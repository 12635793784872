import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { ThemeProvider, } from "@material-ui/core/styles";
import { theme } from "../../theme/theme";

import { store } from "../../App";
import { VisangMembersDB } from "../data/VisangMembersDB";
import { convertToLinearStrokesArray, getDateStr, getTimeDurationStr } from "../../services/stroke_data_loader";
import { chunkPageStrokes } from "../../KerisReplay/StrokeInfo";
import VisangTableHead from "./VisangTableHead";
import VisangTableRow from "./VisangTableRow";
import { data_selected_user } from "../../data/students_data";
import VisangLesson from "../data/VisangLesson";
import { analizeAllStudents, AnalyzedStrokeType } from "../data/StrokeAnalyzer";
import { lessonNames } from "../constants/VisangConstants";
import { HideUIProgressBackdrop, ShowUIProgressBackdrop } from "../../store/reducers/ui";




function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


// 전체 학생들의 문제 풀이 시간과 시퀀스를 얻어낼 수 있다.
// 나중에 참고, 참조할 것, 2020-08-09
/**
 * @typedef AnswerSummary
 * @type {array}
 * @property {number} question_number - number of question
 * @property {number} time - total comsumed time of all applicants
 * @property {number} score - total score of all applicants
 * @property {number} tried - total tried count of all applicants
 */


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  paper: {
    marginLeft: 18,
    marginRight: 18,
    marginBottom: theme.spacing(2),
  },
  container: {
    // height: 800,
    // maxHeight: 800,
  },
  table: {},
  tableHead: {
    fontSize: '1.0rem'
  },

  table_row: {
    "padding-top": "10px",
    "padding-bottom": "10px",
  },

}));


function createData(id: string, analyzed: AnalyzedStrokeType[], course: number, lesson: number) {
  const state = store.getState();
  const db: VisangMembersDB = state.visang.memberDB;
  const info = db.memberInfos[id];

  const re = /(.+)_(.+)_(.+)$/;
  const ss = re.exec(id);   // "txt"
  const idSerial = ss[3];
  const a = analyzed.filter(item => item.id === id)[0];

  const ret: AnalyzedStrokeType = {
    ...a,
    number: sprintf("%3d번", idSerial),
    name: info ? info.name : "",
    absent: false,
    lesson: lessonNames[lesson],
    course: course,
  };

  return ret;
}

type Props = {
  students: string[],
  course: number,
  lesson: number,
}


function EmptyTable(props: Props) {


}


export default function VisangStudentTable(props: Props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("st_num");
  // const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [students, setStudents] = React.useState(props.students);
  const [strokeData, setStrokeData] = React.useState(undefined);
  const [analyzed, setAnalyzed] = React.useState(undefined);

  const state = store.getState();
  let processor = undefined as VisangLesson;
  if (state.visang.lessonData && state.visang.lessonData[props.course] && state.visang.lessonData[props.course][props.lesson]) {
    processor = state.visang.lessonData[props.course][props.lesson];
  }

  useEffect(() => {
    if (processor) {
      ShowUIProgressBackdrop();
      processor.setStrokeData().then(() => {
        setStrokeData(processor.strokes);
        const result = analizeAllStudents(processor.strokes);
        setAnalyzed(result);
        HideUIProgressBackdrop();
      }).catch(e => {
        HideUIProgressBackdrop();
      });
    }
  }, [processor]);

  useEffect(() => {
    setStudents(props.students);
  }, [props.students]);

  let ids = [];
  if (analyzed) {
    ids = analyzed.map(item => item.id);
  }
  const r_rows = ids.map(id => createData(id, analyzed, props.course, props.lesson));


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, r_rows.length - page * rowsPerPage);

  const { course, lesson } = props;

  return (
    <div className={classes.root}>
      {/* <Paper elevation={0} className={classes.paper}> */}
      <TableContainer className={classes.container}>
        <Table size="medium"
          className={classes.table}
          aria-label="enhanced table"
          aria-labelledby="tableTitle"
        >
          <VisangTableHead
            classes={classes}
            // numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={r_rows.length}
          />
          <TableBody>
            {stableSort(r_rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <VisangTableRow key={row.id + index} course={course} lesson={lesson} row={row} processor={processor} />
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 40 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15, 25]}
        component="div"
        count={r_rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {/* </Paper> */}
    </div>
  );
}
