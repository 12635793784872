import { chunkPageStrokes } from "../../KerisReplay/StrokeInfo";
import { getDateStr, getTimeDurationStr, NU_TO_CM, toFloat } from "../../services/stroke_data_loader";

export type AnalyzedStrokeType = {
  absent?: boolean,
  name?: string,
  number?: string,

  id: string,
  startTime: number,
  endTime: number,
  delta_time: number,
  idle_time: number,
  writing_time: number,
  writing_ratio: number,

  min_speed: number,
  max_speed: number,
  avr_speed: number,

  min_force: number,
  max_force: number,
  avr_force: number,

  linear_strokes: TimebasedRenderStrokes,
  stroke_chunks: NeoStroke[][],

  course?: number,
  lesson?: string,
}

function getStrokeCharacteristic_visang(stroke, margin) {
  const dots = stroke.dots;
  const dotCount = stroke.dotCount;
  const startTime = stroke.startTime;

  /** @type {Uint8Array} */
  let dotBlob = new Uint8Array(0);

  if (typeof dots === "string") {
    const binary_string = window.atob(dots);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    dotBlob = bytes;
  } else {
    dotBlob = dots.toUint8Array();
  }

  const dotSize = dotBlob.length / dotCount; // 16 or 17
  let shiftDot = 0;
  if (dotSize !== 16 && dotSize !== 17) {
    console.log("invalid dot", dotBlob);
  }

  if (dotSize === 17) {
    shiftDot = 1;
  }

  // 최초의 1점
  const d = dotBlob.slice(0, dotSize);
  let x0 = toFloat(d, 5 + shiftDot);
  let y0 = toFloat(d, 9 + shiftDot);
  const force = toFloat(d, 1 + shiftDot);

  let min_force = force;
  let max_force = force;

  let sum_force = force;

  let min_speed = 65535;
  let max_speed = 0;
  let sum_speed = 0;

  let sum_deltaTime = 0;

  let speed_cnt = 0;
  for (let i = 1; i < dotCount; i++) {
    const st = i * dotSize;
    const end = st + dotSize;
    const d = dotBlob.slice(st, end);

    // 필압 관련
    const force = toFloat(d, 1 + shiftDot);
    // console.log(`force = ${force}`);
    min_force = Math.min(force, min_force);
    max_force = Math.max(force, max_force);
    sum_force += force;

    // 시간
    const deltaTime = d[0];

    // 속도 관련
    sum_deltaTime += deltaTime;

    // console.log( `deltaTime = ${deltaTime}`);
    const x1 = toFloat(d, 5 + shiftDot);
    const y1 = toFloat(d, 9 + shiftDot);

    const dx = x1 - x0;
    const dy = y1 - y0;
    const dist = Math.sqrt(dx * dx + dy * dy);
    if (deltaTime !== 0) {
      const speed = dist / (deltaTime / 1000);

      max_speed = Math.max(speed, max_speed);
      min_speed = Math.min(speed, min_speed);
      sum_speed += speed;
      speed_cnt++;
    }

    // 상태 갱신
    x0 = x1;
    y0 = y1;
  }

  const avr_speed = sum_speed / speed_cnt;
  const avr_force = sum_force / dotCount;
  const duration = sum_deltaTime;

  /** @type {StrokeCharacteristic} */
  const ret = {
    max_force,
    min_force,
    avr_force,

    duration,
    max_speed: max_speed * NU_TO_CM,
    min_speed: min_speed * NU_TO_CM,
    avr_speed: avr_speed * NU_TO_CM,
  };

  return ret;
}



/**
 *
 * @param {StrokeData} json
 * @return {TimebasedRenderStrokes} - linear stroke
 */
export function makeStrokeLinear(json, margin) {
  const section = json.section;
  const owner = json.owner;
  const book = json.bookCode;

  const pages = json.pages;
  const s = pages.map((page) => {
    const pageNum = page.pageNumber;
    const old_strokes = page.strokes;

    // console.log(`Page: ${page.pageNumber}`);
    const strokes_in_page = old_strokes.map((stroke) => {
      const stroke_characteristic = getStrokeCharacteristic_visang(stroke, margin);

      const endTime = stroke.startTime + stroke_characteristic.duration;
      // console.log(getDateStr(stroke.startTime), getDateStr(endTime));

      /** @type {NeoStroke} */
      const new_stroke = {
        ...stroke,
        section,
        owner,
        book,
        pageNum,
        ...stroke_characteristic,
        endTime,
      };

      return new_stroke;
    });
    // console.log(``);

    return strokes_in_page;
  });

  /** @type {Array.<NeoStroke>} */
  const lineared_strokes = [].concat.apply([], s);
  lineared_strokes.sort((a, b) => {
    return a.startTime - b.startTime;
  });

  console.log(lineared_strokes);

  if (lineared_strokes.length > 0) {
    const related_pages = pages.map((page) => {
      return {
        section,
        owner,
        book,
        page: page.pageNumber,
      };
    });

    const ret = {
      related_pages,
      numStrokes: lineared_strokes.length,
      startTime: lineared_strokes[0].startTime,
      strokes: lineared_strokes,
    };

    return ret;
  }

  return {
    numStrokes: 0,
    startTime: 0,
    strokes: new Array(0),
  };
}

function analizeStroke(strokesMap, id: string) {
  const strokes = strokesMap[id];
  const linear_strokes = makeStrokeLinear(strokes, 0);
  const stroke_chunks = chunkPageStrokes(linear_strokes.strokes);

  let writing_time = 0;
  let min_force = 10000000000;
  let max_force = 0;
  let force_sum = 0;

  let min_speed = 10000000000;
  let max_speed = 0;
  let speed_sum = 0;

  const len = linear_strokes.strokes.length;
  for (let i = 0; i < linear_strokes.strokes.length; i++) {
    const stroke = linear_strokes.strokes[i];
    const duration = stroke.endTime - stroke.startTime;
    writing_time += duration;

    min_force = Math.min(min_force, stroke.min_force);
    max_force = Math.max(max_force, stroke.max_force);
    force_sum += stroke.avr_force;

    min_speed = Math.min(min_speed, stroke.min_speed);
    max_speed = Math.max(max_speed, stroke.max_speed);

    speed_sum += stroke.avr_speed;
  }
  const avr_force = force_sum / len;
  const avr_speed = speed_sum / (len - 1);

  let startTime = linear_strokes.strokes[0].startTime;
  let endTime = linear_strokes.strokes[len - 1].endTime;
  let delta_time = endTime - startTime;

  const idle_time = delta_time - writing_time;
  const writing_ratio = writing_time / delta_time;
  console.log(`id=${id} startTime=${startTime} endTime=${endTime}`)

  console.log(`${getTimeDurationStr(delta_time)} = ${getDateStr(startTime)} ~ ${getDateStr(endTime)}`);

  const ret: AnalyzedStrokeType = {
    id, startTime, endTime,
    delta_time, writing_time, idle_time,
    writing_ratio,
    min_force, max_force, avr_force,
    min_speed, max_speed, avr_speed,
    linear_strokes, stroke_chunks
  };

  return ret;
}

export function analizeAllStudents(strokesMap) {
  const ids = Object.keys(strokesMap);
  const ret = [];
  ids.forEach(id => {
    const result = analizeStroke(strokesMap, id);
    ret.push(result);
  });

  return ret;
}
