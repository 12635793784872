import thunk from 'redux-thunk';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import authorizationReducer from './reducers/authorization';
import uiReducer from './reducers/ui';
import tabReducer from './reducers/TabReducer';
import visangReducer from "../visang/store/VisangRedux";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const rootReducer = combineReducers({
  auth: authorizationReducer,
  ui: uiReducer,
  TabReducer: tabReducer,
  visang: visangReducer,
});


const rootStore = () => {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk))
  );
  return store;
};


export default rootStore;

export type RootStore = ReturnType<typeof rootStore>;

export type RootState = ReturnType<typeof rootReducer>;
