import React from "react";
import { Box, Button, IconButton, makeStyles, TableCell, TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Link } from "react-router-dom";
import TableAnswerDetail from "./PersonalResultTable";

import {
  data_selected_user,
} from "../../data/students_data";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  paper: {
    marginLeft: 18,
    marginRight: 18,
    marginBottom: theme.spacing(2),
  },
  container: {
    // height: 800,
    // maxHeight: 800,
  },
  table: {},
  tableHead: {
    fontSize: '1.0rem'
  },

  table_row: {
    "padding-top": "10px",
    "padding-bottom": "10px",
  },

}));


const handleGotoResult = (event, userId) => {
  console.log("User Id : ", userId);
  // 더 좋은 방법 확인
  data_selected_user.pop();
  data_selected_user.push(userId);
  console.log("data_selected_user[userId]: ", data_selected_user[userId]);
};

export default function EnhancedTableRow(props) {
  const classes = useStyles();

  const { row } = props;
  const [open, setOpen] = React.useState(false);
  // const classes = useStyles();

  // const isItemSelected = isSelected(row.num);
  // const labelId = `enhanced-table-checkbox-${index}`;
  const absent = row.absent;

  // const inner_classes = innerTableStyles();

  let openFunc = absent ? () => { } : setOpen;

  return (
    <React.Fragment>
      <TableRow className={classes.root} hover tabIndex={-1} key={row.num} onClick={() => openFunc(!open)}>
        {/* 열림버튼 */}
        <TableCell component="th" scope="row" width="5%" padding="none" className={classes.table_row} key={row.id}>

          <IconButton aria-label="expand row" size="small" key={row.id}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>

          {/* <ReactableIconButton key={row.name} row={row} open={open} /> */}
        </TableCell>

        {/* 학년 */}
        <TableCell align="center" padding="none" className={classes.table_row}>
          <Box fontSize={15} fontWeight="fontWeightRegular">
            {`${row.grade}학년`}
          </Box>
        </TableCell>

        {/* 반 */}
        <TableCell align="center" padding="none" className={classes.table_row}>
          <Box fontSize={15} fontWeight="fontWeightRegular">
            {`${row.group}반`}
          </Box>
        </TableCell>

        {/* 번호 */}
        <TableCell align="center" padding="none" className={classes.table_row}>
          <Box fontSize={15} fontWeight="fontWeightRegular">
            {`${row.st_num}번`}
          </Box>
        </TableCell>

        {/* 이름 */}
        <TableCell align="center" style={{
          whiteSpace: "nowrap",
          // wordWrap: "break-word"
        }} padding="none" className={classes.table_row}>
          <Box fontSize={17} fontWeight="fontWeightBold">
            {row.name}
          </Box>
        </TableCell>

        {/* 이메일 */}
        <TableCell align="left" padding="none" className={classes.table_row}>
          <Box fontSize={12} fontWeight="fontWeightLight">
            {row.id}
          </Box>
        </TableCell>

        {/* 풀이시간 */}
        <TableCell align="right" padding="none" className={classes.table_row}>
          {row.time}
        </TableCell>

        {/* 성취율 */}
        <TableCell align="right" padding="none" className={classes.table_row}>
          <Box fontSize={16} fontWeight="fontWeightBold">
            {`${row.score} %`}
          </Box>
        </TableCell>

        {/* 상세결과 확인 버튼 */}
        {/* <TableCell align="center" padding="none" className={classes.table_row}>
        {row.absent ? null : (
          <Link to="/main/result">
            <Button variant="outlined" color="primary" onClick={(event) => handleGotoResult(event, row.id)}>
              <Typography variant="h7" gutterBottom component="div">확인</Typography>
            </Button>
          </Link>
        )}
      </TableCell> */}

        {/* 필기재생 버튼 */}
        <TableCell align="center" padding="none" className={classes.table_row}>
          {row.absent ? null : (
            <Link to="/main/replay">
              <Button variant="outlined" color="primary" onClick={(event) => handleGotoResult(event, row.id)}>
                <Box fontSize={16} fontWeight="fontWeightBold" >재생</Box>
              </Button>
            </Link>
          )}
        </TableCell>

        {/* 문제분석 버튼 */}
        {/* <TableCell align="center" padding="none" className={classes.table_row}>
        {row.absent ? null : (
          <Link to="/main/problem">
            <Button variant="outlined" color="primary" onClick={(event) => handleGotoResult(event, row.id)}>
              <Typography variant="h7" gutterBottom component="div">분석</Typography>
            </Button>
          </Link>
        )}
      </TableCell> */}
      </TableRow>

      <TableAnswerDetail open={open} row={row} personal_result={row.detail} id={row.id} />
    </React.Fragment>
  );
}
