import React from "react";
import { Paper } from "@material-ui/core";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import SchoolInfoTable from "./SchoolInfoTable";
import ExamApplicantTable from "./ExamApplicantTable";

import ExamSummaryTable from "./ExamSummaryTable";
import SectionSummary from "./SectionSummary";
import QuestionsSummaryTable from "./QuestionsSummaryTable";
import EvaluationSectionChart from "./EvaluationSectionChart";
import SectionDetailChart from "./SectionDetailChart";



import { connect } from "react-redux";



/**
 *  Dashboard Page
 */
export default class DashboardComponent extends React.Component {
  // export default function DashboardPage() {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: props.isLoaing,


    }
  }

  async componentDidMount() {
    // console.log(">> componentDidMount");
  }


  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render() {
    const { classes, tabNum } = this.props;

    // console.log(`DashboardPage TAB VALUE = ${tabNum}`);

    return (
      <div className={classes.root} style={{ minWidth: "800px" }}>
        <Grid container spacing={3}>
          {/* 학급정보 */}
          <Grid item xs={12}>
            <SchoolInfoTable isLoading={this.state.isLoading} classes={classes} tabNum={tabNum}/>
          </Grid>

          {/* 응시현황 */}
          <Grid item xs={12}>
            <ExamApplicantTable isLoading={this.state.isLoading} classes={classes} tabNum={tabNum}/>
          </Grid>

          {/* 평가결과 */}
          <Grid item xs={12}>
            <ExamSummaryTable isLoading={this.state.isLoading} classes={classes} tabNum={tabNum}/>
          </Grid>

          {/* 성취기준별 현황 */}
          <Grid item xs={7}>
            <SectionSummary isLoading={this.state.isLoading} classes={classes} tabNum={tabNum}/>
          </Grid>

          {/* 성취기준 그래프 */}
          <Grid item xs={5}>
            <EvaluationSectionChart isLoading={this.state.isLoading} classes={classes} tabNum={tabNum}/>
          </Grid>

          {/* 문항별 현황 */}
          <Grid item xs={12}>
            <QuestionsSummaryTable isLoading={this.state.isLoading} classes={classes} tabNum={tabNum}/>
          </Grid>

          {/* 내용별 성취율 그래프 */}
          <Grid item xs={12}>
              <SectionDetailChart isLoading={this.state.isLoading} classes={classes} tabNum={tabNum}/>
          </Grid>

          {/* <Grid item xs={12}>
                <Box display="flex" flexDirection="row"  fontSize={18} p={1} fontWeight="fontWeightBold">
                  성취기준 그래프
                </Box>
                <Paper className={classes.paper} style={{ height: "450px" }}>
                  <ParticipationPieChart />
                </Paper>
              </Grid> */}

          {/* <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <OurClassSideBarChar />
                </Paper>
              </Grid>

              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <TotalClassSideBarChar />
                </Paper>
              </Grid> */}

          {/* <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <MultiPointChart />
                </Paper>
              </Grid>

              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <ScoreByZonePieChart />
                </Paper>
              </Grid> */}
        </Grid>
      </div>
    );
  }
}

