import React from "react";
import PropTypes from "prop-types";
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";

const headCells = [
  { id: "grade", disablePadding: true, label: "학년", align: "center" },
  { id: "group", disablePadding: true, label: "반", align: "center" },
  { id: "st_num", disablePadding: true, label: "번호", align: "center" },
  { id: "name", disablePadding: true, label: "이름", align: "center" },
  { id: "id", disablePadding: true, label: "아이디", align: "left" },
  { id: "time", disablePadding: true, label: "풀이시간(초)", align: "right" },
  { id: "score", disablePadding: true, label: "성취율(%)", align: "right" },
  // { id: "result", disablePadding: true, label: "상세결과", align: "center" },
  { id: "replay", disablePadding: true, label: "필기재생", align: "center" },
  // { id: "analysis", disablePadding: true, label: "문제분석", align: "center" },
];

export default function EnhancedTableHead(props) {
  const {
    classes,
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };



  return (
    <TableHead>
      <TableRow className={classes.table_row}>
        <TableCell component="th" scope="row" width="5%">
        </TableCell>
        {headCells.map((headCell) => {
          return (
            <TableCell key={headCell.id} align={headCell.align as 'inherit' | 'left' | 'center' | 'right' | 'justify'} className={classes.table_row}
              sortDirection={orderBy === headCell.id ? order : false}
              padding="none">

              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >

                <Box fontSize={17} fontWeight="fontWeightBold">

                  {headCell.label}</Box>
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
