import React from "react";
import { logout } from "../store/reducers/authorization";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import EvaluationTable from "../PageComponents/Student/EvaluationTable";
import { data_members } from "../data/students_data";
import { MembersDB } from "../services/members";
import { EvaluationResultDB } from "../services/eveluation_result";
import { ExamSummaryProcessor } from "../services/exam_summary_processor";
import {
  ShowUIProgressBackdrop,
  HideUIProgressBackdrop,
} from "../store/reducers/ui";
import { fetchInfo } from "../services/data_loader";
import { connect } from "react-redux";
import { TopTabs } from "../components/Panels/TopTabs";

import { TabPanel } from "../components/Panels/TabPanel";
import { EXAM_FILE_RESOURCES, getTabName, getTabNames } from "../data/Constant";


const styles = (theme) => ({
  root: {
    // width: '95%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flexGrow: 1,
    // height: '100%',

    // backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

class StudentsPage extends React.Component {
  constructor(props) {
    super(props);
    console.log("StudentsPage isLoading : ", props.isLoading);

    const memberDB = new MembersDB();
    const examResult = new EvaluationResultDB();
    const examSummaryProcessor = new ExamSummaryProcessor();

    this.state = {
      value: this.props.tabNum,
      isLoading: this.props.isLoading,
      members: [],

      // kitty, 2020-08-16
      data_load_fail: false,
      fetching: false, // tells whether the request is waiting for response or not

      memberDB: memberDB,
      examResult: examResult,
      examSummaryProcessor: examSummaryProcessor,
    };
  }

  async componentDidMount() {
    console.log(">> componentDidMount");

    console.log("data_members  : ", data_members.length);

    if (this.props.isLoading) {
      this.setState({
        isLoading: true,
        members: data_members,
      });
    }

    ShowUIProgressBackdrop();
    // await fetchInfo(this, this.props.tabNum);
    HideUIProgressBackdrop();
  }

  // onSetTabValue = (newValue) => {
  //   this.setState({ value: newValue });
  // };

  async shouldComponentUpdate(nextProps, nextState) {
    // if (nextProps.tabNum !== this.props.tabNum) {
    //   console.log("\n\nUPDATE\n\n\n");
    //   const memberDB = new MembersDB();
    //   const examResult = new EvaluationResultDB();
    //   const examSummaryProcessor = new ExamSummaryProcessor();

    //   this.setState({
    //     ...this.state,

    //     fetching: true,
    //     memberDB: memberDB,
    //     examResult: examResult,
    //     examSummaryProcessor: examSummaryProcessor,
    //   });

    //   ShowUIProgressBackdrop();
    //   await fetchInfo(this, nextProps.tabNum);
    //   this.setState({ fetching: false, });
    //   HideUIProgressBackdrop();
    // }

    return true;
  }

  render() {
    const { classes } = this.props;

    if (this.state.data_load_fail) {
      logout();
      this.props.history.push("/");
    }

    const topTabs = getTabNames();

    return (
      <div className={classes.root}>
        <TopTabs onChange={this.onSetTabValue} value={this.state.value} classes={this.props.classes} />

        { topTabs.map((name, index) => {
          const key = `student-${index}`;

          return (
            <TabPanel value={this.state.value} index={index} key={key}>
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <EvaluationTable
                        isLoading={this.state.isLoading}
                        students={this.state.memberDB}
                        exam_result={this.state.examResult}
                        fetching={this.state.fetching}
                      />
                      {/* <ClassScoreChart /> */}
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
          );
        })}

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const ret = {
    tabNum: state.TabReducer.tabNum,
  };
  return ret;
};

export default withStyles(styles)(connect(mapStateToProps)(StudentsPage));
