import React, { Component } from "react";
import KerisMain from "../KerisReplay/KerisMain";
import { data_selected_user } from "../data/students_data";
import { ShowUIProgressBackdrop, HideUIProgressBackdrop, } from "../store/reducers/ui";
import { getTabName, EXAM_FILE_RESOURCES } from "../data/Constant";
import { withStyles } from "@material-ui/core/styles";
import { convertToLinearStrokesArray } from "../services/stroke_data_loader";
import { connect } from "react-redux";
import { chunkPageStrokes } from "../KerisReplay/StrokeInfo";

import "../typedef";

const styles = (theme) => ({
  root: {
    // width: '95%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // height: '100%',

    // backgroundColor: theme.palette.background.paper,
    // textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

interface Props {
  tabNum?: number,
}

interface State {
  storke_data,
  isReceivedData: boolean,

  /** @type {TimebasedRenderStrokes} */
  kitty_stroke_data: TimebasedRenderStrokes,

  /** @type  {Array.<Array.<NeoStroke>>} */
  stroke_chunks: NeoStroke[][],

  isVisang: boolean,
  visangCourse: number,
  visangLesson: number,

}

class ReplayPage extends Component<Props, State> {
  // constructor(props) {
  //   super(props);
  // };

  state = {
    storke_data: null,
    isReceivedData: false,

    /** @type {TimebasedRenderStrokes} */
    kitty_stroke_data: null,

    /** @type  {Array.<Array.<NeoStroke>>} */
    stroke_chunks: null,

    isVisang: false,
    visangCourse: 0,
    visangLesson: 0,
  };

  didReadStroke = (requestUri, margin) => {
    console.log(">> didReadStroke");

    fetch(requestUri)
      .then((response) => {
        HideUIProgressBackdrop();

        console.log("requestUri : ", requestUri);
        const json_promise = response.json();
        return json_promise;
      })
      .then((json) => {
        console.log("json : ", json);

        for (let i = 0; i < json.pages.length; i++) {
          let page = json.pages[i];
          page.section = json.section;
          page.owner = json.owner;
          page.book = json.bookCode;
          console.log(page);
        }

        let kitty_stroke_data = convertToLinearStrokesArray(json, margin);
        let stroke_chunks = chunkPageStrokes(kitty_stroke_data.strokes);

        /** DB에서 나오는 json 파일이, section, owner, book 등을 가지고 있지 않은 페이지 정보 */
        /** 따라서, convertToLinearStrokesArray에서 나온 값을 가지고 변수 json에 별도의 property를 추가 */
        for (let i = 0; i < json.pages.length; i++) {
          let page = json.pages[i];
          page.section = json.section;
          page.owner = json.owner;
          page.book = json.bookCode;
          page.margin = { ...margin };
          console.log(page);
        }
        /** 여기까지 */

        this.setState({
          kitty_stroke_data,
          storke_data: json,
          isReceivedData: true,
          stroke_chunks,
        });
      });
  };

  fetchStorkeData = async (userId, tabNum) => {
    console.log(">> fetchServerData");
    ShowUIProgressBackdrop();

    const token = localStorage.getItem("idToken");
    const headers = {
      authorization: `Bearer ${token}`,
    };

    const tab_info = getTabName(tabNum);
    const stroke_file_base = EXAM_FILE_RESOURCES[tab_info].strokeFile;

    const url = `https://apis.neolab.net/v1/admin/storage/${userId}/file/${stroke_file_base}`;

    let response = await fetch(
      // `https://apis.neolab.net/v1/admin/storage/${userId}/file/${STROKE_FILE_NAME_FIRST}`,
      url,
      { headers }
    );
    console.log("test 1 response.status : ", response.status);
    if (response.ok) {
      let jsonData = await response.json();
      console.log("jsonData : ", jsonData);
      console.log(jsonData.requestUri);

      // kitty 임시코드, 2020/09/15
      const tab_info = getTabName(tabNum);
      const margin = EXAM_FILE_RESOURCES[tab_info].margin;
      this.didReadStroke(jsonData.requestUri, margin);
    } else {
      HideUIProgressBackdrop();
      console.log(`Error : ${response.status}`);
    }
  };


  componentDidMount() {
    console.log(">> componentWillMount");
    console.log("data_selected_user", data_selected_user[0]);

    const arg = data_selected_user[0];
    if (arg && Object.prototype.hasOwnProperty.call(arg, "url")) {
      const strokeFileUrl = arg["url"];
      const VISANG_MARGIN = { bottom: 0, left: 0, right: 0, top: 0 };
      this.didReadStroke(strokeFileUrl, VISANG_MARGIN);

      this.setState({
        isVisang: true,
        visangCourse: arg["course"],
        visangLesson: arg["lesson"],
      });
    }
    else {
      const { tabNum } = this.props;
      const user = data_selected_user[0];
      this.fetchStorkeData(user, tabNum);

      this.setState({
        isVisang: false,
        visangCourse: 0,
        visangLesson: 0,

      });
    }
  }

  render() {
    const { tabNum } = this.props;
    if (this.state.isReceivedData === false) {
      return <div></div>;
    } else {
      // const { classes } = this.props
      const { storke_data, kitty_stroke_data, stroke_chunks, isVisang, visangCourse, visangLesson } = this.state;
      return (
        // <Paper elevation={3}>

        <KerisMain
          defaultData={storke_data}
          strokeStream={kitty_stroke_data}
          strokeChunks={stroke_chunks}
          tabNum={tabNum}
          isVisang={isVisang}
          visangCourse={visangCourse}
          visangLesson={visangLesson}
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  let ret = {
    tabNum: state.TabReducer.tabNum,
  };
  return ret;
};

export default withStyles(styles)(connect(mapStateToProps)(ReplayPage));
