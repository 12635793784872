import { store } from '../../App';
import cookie from 'react-cookies';
import VisangMobx from './VisangMobx';
import { VisangMembersDB } from '../data/VisangMembersDB';
import { courses, numCourses, numLesseons } from '../constants/VisangConstants';
import VisangLesson from '../data/VisangLesson';
import { HideUIProgressBackdrop, ShowUIProgressBackdrop } from '../../store/reducers/ui';

// Define Action Types
const ActionGroup = 'Visang';
const visangStorage = new VisangMobx();

export const VisangActionTypes = {
  GET_MEMBER: `${ActionGroup}.GET_MEMBER`,
  GET_COURSE: `${ActionGroup}.GET_COURSE`,
  GET_LESSON: `${ActionGroup}.GET_LESSON`,
  REFRESH_DRAWER: `${ActionGroup}.REFRESH_DRAWER`,
  REFRESHED: `${ActionGroup}.REFRESHED`,
};


// Action Methods
export const refreshDrawer = () => {
  store.dispatch({
    type: VisangActionTypes.REFRESH_DRAWER,
  });
};

export const refreshVisangData = async () => {
  const state = store.getState();
  const ids = [...state.visang.members];

  if (ids?.length > 0) {
    for (let c = 0; c < numCourses; c++) {
      const promises = [];
      for (let l = 0; l < numLesseons; l++) {
        const lesson = new VisangLesson();
        const pr = lesson.getData(c, l, ids);
        promises.push(pr);
        pr.then(has => {
          state.visang.lessonAvailable[c][l] = has;
          state.visang.lessonData[c][l] = lesson;
        });

      }
      await Promise.all(promises);
      console.log(`retreive lesson data: course=${c} completed`);
    }
  }

  console.log(`retreive lesson data: COMPLETED`);

  store.dispatch({
    type: VisangActionTypes.REFRESHED,
  });
}

// Action Methods
export const getVisangMembers = () => {
  console.log("getVisangMembers ");
  const memberDB = VisangMembersDB.getInstance();
  memberDB.load().then((students) => {
    store.dispatch({
      type: VisangActionTypes.GET_MEMBER,
      members: [...memberDB.ids],
      memberDB,
    })
  });
};

export const setVisangCourse = async (course: number) => {
  const state = store.getState();
  let ids = state.visang.members;

  if (!ids?.length) {
    const memberDB = VisangMembersDB.getInstance();
    await memberDB.load();
    ids = [...memberDB.ids];

    store.dispatch({
      type: VisangActionTypes.GET_MEMBER,
      members: ids,
      memberDB,
    })
  }

  const lessonAvailable: boolean[] = new Array(numLesseons);
  const lessonData: VisangLesson[] = new Array(numLesseons);

  ShowUIProgressBackdrop();
  if (state.visang.lessonDataInited[course]) {
    for (let l = 0; l < numLesseons; l++) {
      lessonAvailable[l] = state.visang.lessonAvailable[course][l];
      lessonData[l] = state.visang.lessonData[course][l];
    }
  }
  else {
    const promises = [];
    for (let l = 0; l < numLesseons; l++) {
      const lesson = new VisangLesson();

      const pr = lesson.getData(course, l, ids);
      promises.push(pr);

      pr.then(has => {
        lessonAvailable[l] = has;
        lessonData[l] = lesson;

      });
    }
    await Promise.all(promises);
  }
  HideUIProgressBackdrop();

  store.dispatch({
    type: VisangActionTypes.GET_COURSE,
    course,
    lessonAvailable,
    lessonData
  })
};


interface State {
  members: string[],
  strokeJson: string,
  lessonDataInited: boolean[],
  lessonAvailable: boolean[][],

  lessonData: VisangLesson[][],

  course: number,
  lesson: number,
  refreshDrawerCount: number,
  dataRefreshed: number,

  selectedLesson: number[],
  memberDB: VisangMembersDB,
};



const initialState: State = {
  members: [] as string[],
  strokeJson: "",
  lessonDataInited: Array.apply(null, { length: numCourses }).map(() => false) as boolean[],
  lessonAvailable: Array.apply(null, { length: numCourses }).map(() =>
    Array.apply(null, { length: numLesseons }).map(() => false)) as boolean[][],

  lessonData: Array.apply(null, { length: numCourses }).map(() =>
    Array.apply(null, { length: numLesseons }).map(() => undefined)) as VisangLesson[][],

  course: 0,
  lesson: 0,
  refreshDrawerCount: 0,
  dataRefreshed: 0,

  selectedLesson: Array.apply(null, { length: numCourses }).map(() => 0) as number[],

  memberDB: undefined as VisangMembersDB,
};


// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case VisangActionTypes.GET_MEMBER: {
      return {
        ...state,
        members: action.members,
        memberDB: action.memberDB,
      }
    }
    case VisangActionTypes.GET_COURSE: {
      const la = state.lessonAvailable;
      la[action.course] = [...action.lessonAvailable];

      const ld = state.lessonData;
      ld[action.course] = [...action.lessonData];

      const li = state.lessonDataInited;
      li[action.course] = true;

      return {
        ...state,
        lessonAvailable: la,
        lessonData: ld,
        lessonDataInited: li,
        course: action.course,
        dataRefreshed: state.dataRefreshed + 1
      };
    }

    case VisangActionTypes.REFRESH_DRAWER: {
      return { ...state, refreshDrawerCount: state.refreshDrawerCount + 1 };
    }

    case VisangActionTypes.REFRESHED: {
      return { ...state, dataRefreshed: state.dataRefreshed + 1 };
    }

    default: {
      return state;
    }
  }
};
