import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import * as TabReducer from "../../store/reducers/TabReducer";
import { EXAM_FILE_RESOURCES, getTabName, getTabNames } from "../../data/Constant";
import { AppBar } from "@material-ui/core";

type Props = {
  isLoading: boolean;
  value: number,
  classes
}

type State = {
  tabNum: number,
  isLoading: boolean,
  members: [],
}
export class TopTabs extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    console.log(">> TopTabs isLoading : ", props.isLoading);

    this.state = {
      tabNum: props.value,
      isLoading: this.props.isLoading,
      members: [],
    };
  }

  componentDidMount() {
    // console.log(">> TopTabs >> componentWillMount");
  }

  handleChange = async (event, tabNum) => {
    this.setState({ tabNum: tabNum });
    // this.props.onChange(tabNum);
    await TabReducer.setTabNumber(tabNum);
  };

  render() {
    // {(event, newValue) => {
    //   this.setState({ tabNum: newValue });
    //   this.props.onChange(newValue);
    //   setTabNumber(newValue);
    // }}
    const { classes, value } = this.props;

    const topTabs = getTabNames();


    return (
      <div className={classes.root}>
          <Tabs value={this.state.tabNum} onChange={this.handleChange} aria-label="Toptab" >
             {topTabs.map((name, i) => (
              <Tab key={`tab-${i}`} label={`${name}`}  id={`simple-tab-${i}`} aria-controls={`simple-tabpanel-${i}`} />
            ))}
          </Tabs>
      </div>
    );
  }
}
