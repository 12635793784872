import "../typedef";

import { getTabName, EXAM_FILE_RESOURCES } from "../data/Constant";

async function didReadStroke(requestUri) {
  console.log(">> didReadStroke");

  const response = await fetch(requestUri);
  if (response.ok) {
    /** @type {StrokeData} */
    const stroke_json = await response.json();
    return stroke_json;
  }

  return null;
}

export const NU_TO_CM = 56 / 600 * 25.4;

/**
 *
 * @param {Stroke} stroke
 *
 * @return {StrokeCharacteristic}
 */
function getStrokeCharacteristic(stroke, margin) {
  const dots = stroke.dots;
  const dotCount = stroke.dotCount;
  // let penColor = stroke.color;
  // let penType = stroke.penTipType;
  const startTime = stroke.startTime;

  /** @type {Uint8Array} */
  let dotBlob = new Uint8Array(0);

  if (typeof dots === "string") {
    const binary_string = window.atob(dots);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    dotBlob = bytes;
  } else {
    dotBlob = dots.toUint8Array();
  }

  const dotSize = dotBlob.length / dotCount; // 16 or 17
  let shiftDot = 0;
  if (dotSize !== 16 && dotSize !== 17) {
    console.log("invalid dot", dotBlob);
  }

  if (dotSize === 17) {
    shiftDot = 1;
  }

  // 최초의 1점
  const d = dotBlob.slice(0, dotSize);
  let x0 = toFloat(d, 5 + shiftDot);
  let y0 = toFloat(d, 9 + shiftDot);
  const force = toFloat(d, 1 + shiftDot);

  let min_force = force;
  let max_force = force;

  let sum_force = force;

  let min_speed = 65535;
  let max_speed = 0;
  let sum_speed = 0;

  let sum_deltaTime = 0;
  const dotArray = [];

  const dot = {
    dotType: 1,
    deltaTime: 0,
    time: startTime,
    f: force,
    x: x0,
    y: y0,
  };
  dotArray.push(dot);

  let speed_cnt = 0;
  for (let i = 1; i < dotCount; i++) {
    const st = i * dotSize;
    const end = st + dotSize;
    const d = dotBlob.slice(st, end);

    // 필압 관련
    const force = toFloat(d, 1 + shiftDot);
    console.log(`force = ${force}`);
    min_force = Math.min(force, min_force);
    max_force = Math.max(force, max_force);
    sum_force += force;

    // 시간
    const deltaTime = d[0];

    // 속도 관련
    sum_deltaTime += deltaTime;

    // console.log( `deltaTime = ${deltaTime}`);
    const x1 = toFloat(d, 5 + shiftDot);
    const y1 = toFloat(d, 9 + shiftDot);

    const dx = x1 - x0;
    const dy = y1 - y0;
    const dist = Math.sqrt(dx * dx + dy * dy);

    if (deltaTime === 0) {
      console.log(" deltaTime === 0");
    }
    else {
      const speed = dist / (deltaTime / 1000);

      max_speed = Math.max(speed, max_speed);
      min_speed = Math.min(speed, min_speed);
      sum_speed += speed;
      speed_cnt++;
    }

    // 상태 갱신
    x0 = x1;
    y0 = y1;

    const dot = {
      dotType: i === dotCount - 1 ? 3 : 2,
      deltaTime: deltaTime,
      time: startTime + sum_deltaTime,

      f: force,
      x: x1,
      y: y1,
    };
    dotArray.push(dot);
  }


  // 임시 코드, 2020/09/15
  // 1089: 1240 x 1752
  // 1099: 1239 x 1623
  const margin_adjusted = dotArray.map(dot => {
    const x_new = dot.x * 1 - margin.left;
    const y_new = dot.y * 1 - margin.top;

    return { ...dot, x: x_new, y: y_new, };
  });

  const avr_speed = sum_speed / speed_cnt;
  const avr_force = sum_force / dotCount;
  const duration = sum_deltaTime;

  /** @type {StrokeCharacteristic} */
  const ret = {
    max_force,
    min_force,
    avr_force,

    duration,
    max_speed: max_speed * NU_TO_CM,
    min_speed: min_speed * NU_TO_CM,
    avr_speed: avr_speed * NU_TO_CM,

    // dotArray,
    dotArray: margin_adjusted,
  };

  return ret;
}


export function toFloat(d, index) {
  const byte = d.slice(index, index + 4);
  const view = new DataView(byte.buffer);
  return view.getFloat32(0, true);
}

export function getTimeDurationStr(ms) {
  let day = "0" + Math.floor(ms / 1000 / 60 / 60 / 24);
  let hours = "0" + Math.floor(ms / 1000 / 60 / 60);
  let minutes = "0" + Math.floor(ms / 1000 / 60);
  let seconds = "0" + Math.floor(ms / 1000);

  minutes = minutes.substr(-2);
  seconds = seconds.substr(-2);
  day = day.substr(-2);

  let miliseconds = "00" + (ms % 1000);
  miliseconds = miliseconds.substr(-3);

  return (
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    "." +
    miliseconds
  );
}



export function getDateStr(unix_tick) {
  const date = new Date(unix_tick);
  const hours = date.getHours();
  let minutes = "0" + date.getMinutes();
  let seconds = "0" + date.getSeconds();
  let day = "0" + date.getDate();
  let month = "0" + (date.getMonth() + 1);
  const year = date.getFullYear();
  minutes = minutes.substr(-2);
  seconds = seconds.substr(-2);
  day = day.substr(-2);
  month = month.substr(-2);

  let miliseconds = "00" + (unix_tick % 1000);
  miliseconds = miliseconds.substr(-3);

  return (
    year +
    "/" +
    month +
    "/" +
    day +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    "." +
    miliseconds
  );
}
/**
 *
 * @param {StrokeData} json
 * @return {TimebasedRenderStrokes} - linear stroke
 */
export function convertToLinearStrokesArray(json, margin) {
  const section = json.section;
  const owner = json.owner;
  const book = json.bookCode;

  const pages = json.pages;
  const s = pages.map((page) => {
    const pageNum = page.pageNumber;
    const old_strokes = page.strokes;

    // console.log(`Page: ${page.pageNumber}`);
    const strokes_in_page = old_strokes.map((stroke) => {
      const stroke_characteristic = getStrokeCharacteristic(stroke, margin);

      const endTime = stroke.startTime + stroke_characteristic.duration;
      // console.log(getDateStr(stroke.startTime), getDateStr(endTime));

      /** @type {NeoStroke} */
      const new_stroke = {
        ...stroke,
        section,
        owner,
        book,
        pageNum,
        ...stroke_characteristic,
        endTime,
      };

      return new_stroke;
    });
    // console.log(``);

    return strokes_in_page;
  });

  /** @type {Array.<NeoStroke>} */
  const lineared_strokes = [].concat.apply([], s);
  lineared_strokes.sort((a, b) => {
    return a.startTime - b.startTime;
  });

  console.log(lineared_strokes);

  if (lineared_strokes.length > 0) {
    const related_pages = pages.map((page) => {
      return {
        section,
        owner,
        book,
        page: page.pageNumber,
      };
    });

    const ret = {
      related_pages,
      numStrokes: lineared_strokes.length,
      startTime: lineared_strokes[0].startTime,
      strokes: lineared_strokes,
    };

    return ret;
  }

  return {
    numStrokes: 0,
    startTime: 0,
    strokes: new Array(0),
  };
}

/**
 *
 * @param {Array.<NeoStroke>} lineared_strokes
 * @return {Array.<number>} - page order
 */
export function getPageOrderOfStrokes(lineared_strokes) {
  // 페이지 순서를 구분
  let prev_page = -1;
  const page_changed = lineared_strokes.filter((stroke) => {
    if (prev_page !== stroke.pageNum) {
      prev_page = stroke.pageNum;
      return true;
    }
    return false;
  });

  const stroke_page_order = page_changed.map((stroke) => stroke.pageNum);
  console.log(stroke_page_order);

  return stroke_page_order;
}

export async function loadStrokeData(userId, tabNum) {
  console.error("")
  const token = localStorage.getItem("idToken");
  const headers = { authorization: `Bearer ${token}` };


  const tab_info = getTabName(tabNum);
  const stroke_file_base = EXAM_FILE_RESOURCES[tab_info].strokeFile;
  const margin = EXAM_FILE_RESOURCES[tab_info].margin;

  const url = `https://apis.neolab.net/v1/admin/storage/${userId}/file/${stroke_file_base}`;
  const response = await fetch(
    url,
    // `https://apis.neolab.net/v1/admin/storage/${userId}/file/${STROKE_FILE_NAME_FIRST}`,
    { headers }
  );

  if (response.ok) {
    const jsonData = await response.json();
    console.log("jsonData : ", jsonData);
    console.log(jsonData.requestUri);
    const strokes_json = await didReadStroke(jsonData.requestUri);

    convertToLinearStrokesArray(strokes_json, margin);

    return strokes_json;
  }

  return null;
}
