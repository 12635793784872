
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Box } from "@material-ui/core";
import { EXAM_FILE_RESOURCES } from "../../data/Constant";

import {
  data_all_members,
  data_exam,
} from "../../data/students_data";

import "../../typedef";


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  paper: {
    marginLeft: 18,
    marginRight: 18,
    marginBottom: theme.spacing(2),
  },
  container: {
    // height: 800,
    // maxHeight: 800,
  },
  table: {},
  tableHead: {
    fontSize: '1.0rem'
  },

  table_row: {
    "padding-left": "0px",
    "padding-right": "0px",
    "padding-top": "10px",
    "padding-bottom": "10px",
  },

}));


/**
 *
 * @param {Array.<Object>} rows
 *
 * @return {AnswerSummary}
 */
function summarizeTestResult(rows) {

  const time_sumup = {};

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];

    const id = row.id;
    const name = row.name;
    const time = row.time;
    const score = row.score;

    const examData = data_exam[id];
    const userInfo = examData.userInfo;
    const answer_arr = examData.questionInfoArray;

    for (let j = 0; j < answer_arr.length; j++) {
      const ans_row = answer_arr[j];

      /** @type {string} */
      const q_num = ans_row.number;           // 문제 번호
      const q_numStr = ans_row.numberStr;

      /** @type {string} */
      const q_eval_elem = ans_row.evaluationElement;  // 성취 기준
      const q_eval_elem_key = q_eval_elem.replace(/\s/g, "");

      /** @type {string} */
      const q_eval_detail = ans_row.detailContent;      // 내용 요소
      const q_eval_detail_key = q_eval_detail.replace(/\s/g, "");

      /** @type {number} */
      const a_time = ans_row.solveTimeSec;    // 문제 풀이에 걸린 시간

      /** @type {number} */
      const a_seq_num = ans_row.sequenceNumber; // 전체 문제 풀이 순서 중에서 몇번째로 풀었는지 (시도를 안했으면 0)

      /** @type {boolean} */
      const a_correct = ans_row.isCorrect;    // 문제를 맞혔는지 안맞혔는지

      /** @type {boolean} */
      const a_tried = ans_row.isPlay;     // 문제를 풀려고 했는지 안했는지,

      // console.log(`${q_eval_elem} --> ${name} ${id}, Q-num:${q_num},  A-timesec:${a_time} == SEQ(${a_seq_num}), ans/tried=${a_correct}/${a_tried} `);

      // 성취 기준(q_eval_elem) 배열에 신규 생성
      const q_key = q_eval_elem_key;
      if (!Object.prototype.hasOwnProperty.call(time_sumup, q_key)) {
        // console.log(`    create now era`);
        time_sumup[q_key] = {
          q_eval_elem,
          q_num: [],
          q_eval_detail: [],
          time: 0,
          score: 0,
          tried: 0,
          count: 0,
        };
      }


      // 성취 기준(q_eval_elem) 배열에 문항을 추가
      if (!time_sumup[q_key].q_num.find(e => e.key === q_num)) {
        // console.log(`    q_key ${q_num}`);
        time_sumup[q_key].q_num.push({ key: q_num, value: q_numStr });
      }

      // 성취 기준(q_eval_elem) 배열에 내용을 추가
      if (!time_sumup[q_key].q_eval_detail.find(elem => elem.key === q_eval_detail_key)) {
        // console.log(`    push ${q_eval_detail}`);
        time_sumup[q_key].q_eval_detail.push({
          key: q_eval_detail_key,
          value: q_eval_detail,
        });
      }

      time_sumup[q_key].count++;
      time_sumup[q_key].time += a_time;
      time_sumup[q_key].score += a_correct ? 1 : 0;
      time_sumup[q_key].tried += a_tried ? 1 : 0;
    }
  }
  // console.log(time_sumup);

  // 이제 정렬을 하자
  const sortable = [];
  for (const q_key in time_sumup) {
    sortable.push([
      time_sumup[q_key].q_num,            // 0: 문항번호
      "도형 두 걸음",                           // 1: 단계
      time_sumup[q_key].q_eval_elem,                              // 2: 성취기준
      time_sumup[q_key].q_eval_detail,    // 3: 내용
      time_sumup[q_key].score,            // 4: 점수
      time_sumup[q_key].time,             // 5: 시간
      time_sumup[q_key].tried,            // 6: 시도 회수
      time_sumup[q_key].count,            // 7: 해당 문항수

    ]);
  }

  sortable.sort(function (a, b) {
    return a[0].value - b[0].value;   // key로 정렬
  });

  let total_time = 0;
  for (let k = 0; k < sortable.length; k++) {
    const ie = sortable[k];

    ie[0].sort((a, b) => a.key - b.key);     // q_num
    ie[3].sort((detail_a, detail_b) => detail_a.key - detail_b.key);     // q_eval_detail


    total_time += sortable[k][1];
  }

  return sortable;
}








function percentStr(num, under_digit = 1) {
  const div = Math.pow(10, under_digit);
  const ret = Math.round(num * div * 100) / div;
  return ret;
}

function unifiedStr(num, under_digit = 1) {
  const div = Math.pow(10, under_digit);
  const ret = Math.round(num * div) / div;
  return ret;
}

type IAlignType = "center" | "left" | "right" | "inherit" | "justify";

const headCells = [
  { id: "q_num", disablePadding: true, label: "문항", align: "center" },
  { id: "level", disablePadding: true, label: "단계", align: "center" },
  { id: "eval_elem", disablePadding: true, label: "성취기준", align: "left" },
  { id: "eval_detail", disablePadding: true, label: "내용", align: "center" },
  { id: "score", disablePadding: true, label: "성취율", align: "center" },
  { id: "time", disablePadding: true, label: "풀이시간", align: "center" },
];

export default function SectionSummary(props) {
  const { tabNum } = props;
  const classes = useStyles();
  // const [raw_data, setRows] = React.useState(data_all_members);
  const [raw_data] = React.useState(data_all_members);
  const exam_result = raw_data.filter(row => (!row.absent));

  const applicants_cnt = exam_result.length;

  /** @type {AnswerSummary} */
  const s_arr = summarizeTestResult(exam_result);
  console.log(s_arr);

  return (
    // <ThemeProvider theme={theme}>
    //   <TableContainer component={Paper}>
    <div>
      <Box display="flex" flexDirection="row"  fontSize={18} p={1} fontWeight="fontWeightBold">
        성취기준별 현황
      </Box>

      <Paper className={classes.paper} style={{ minHeight: "450px" }}>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                return (
                  <TableCell key={`tab-${tabNum}-section-cell-${headCell.id}`} align={headCell.align} padding="none" className={classes.table_row}>
                    <Box fontSize={17} fontWeight="fontWeightBold">
                      {headCell.label}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {
              s_arr.map((item, index) => {
                return (
                  <TableRow className={classes.table_row} key={`section-${index}`}>
                    {/* 문항번호 */}
                    <TableCell align="center" className={classes.table_row} padding="none">

                      {item[0].map((e) => {
                        return (
                          <Box fontWeight="fontWeightLight" key={e.key}>
                            <span>{e.value}{" "}</span>
                          </Box>

                        )
                      })}

                    </TableCell>


                    {/* 단계 */}
                    <TableCell align="center" className={classes.table_row} padding="none"
                      style={{ wordWrap: "break-word" }}>
                      <Box fontSize={14} fontWeight="fontWeightLight">
                        {item[1]}
                      </Box>
                    </TableCell>

                    {/* 성취기준 */}
                    <TableCell align="left" className={classes.table_row} padding="none"
                      style={{ width: "200px", wordWrap: "break-word" }}>
                      <Box fontSize={14} fontWeight="fontWeightLight">
                        {item[2]}
                      </Box>
                    </TableCell>

                    {/* 내용 */}
                    <TableCell align="center" className={classes.table_row} padding="none"
                      style={{ wordWrap: "break-word" }}>
                      {item[3].map((q_detail) => {
                        return (
                          <Box fontWeight="fontWeightLight" key={q_detail.key}>
                            {q_detail.value}
                          </Box>
                        );
                      })}
                      {/* {`${unifiedStr(item[1] / applicants_cnt)}초`} */}
                    </TableCell>

                    {/* 성취율 */}
                    <TableCell align="center" className={classes.table_row} padding="none"
                      style={{ whiteSpace: "nowrap" }}>
                      <Box fontSize={17} fontWeight="fontWeightBold">
                        {`${percentStr(item[4] / item[0].length / applicants_cnt, 0)}%`}
                      </Box>
                      {/* {`${unifiedStr(item[1] / applicants_cnt)}초`} */}
                    </TableCell>

                    {/* 풀이시간 */}
                    <TableCell align="center" className={classes.table_row} padding="none"
                      style={{ whiteSpace: "nowrap" }}>
                      <Box fontSize={16} fontWeight="fontWeightLight">
                        {`${unifiedStr(item[5] / item[0].length / applicants_cnt, 1)}초`}
                      </Box>
                      {/* {`${unifiedStr(item[1] / applicants_cnt)}초`} */}
                    </TableCell>

                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
