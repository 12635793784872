import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Box } from "@material-ui/core";
import SchoolIcon from '@material-ui/icons/School';

import {
  data_all_members,
} from "../../data/students_data";

import "../../typedef";

/**
 * 
 * @param {string} school - 학교 
 * @param {number} grade - 학년
 * @param {number} group - 반
 * 
 * @return {SchoolInfo}
 */

/**
 * 
 * @param {string} [school_name] - 학교 
 * @param {number} [grade] - 학년
 * @param {number} [group] - 반
 * 
 * @return {number} student count of the class group
 */
function getStudentCnt(school_name, grade, group) {
  // 이 각 학교별 데이터를 주도록부분 수정해야 한다.
  // const [rows, setRows] = React.useState(data_all_members_list);

  // return rows.length;


  return 0;
}




function createData(school, grade, group) {
  /** @type {number} */
  const st_num = getStudentCnt(school, grade, group);
  return { school, grade, group, st_num };
}

const info_rows = [
  createData("네오초등학교", 3, 1),
];


const headCells = [
  { id: "school", disablePadding: true, label: "학교", align: "center" },
  { id: "grade", disablePadding: true, label: "학년", align: "center" },
  { id: "group", disablePadding: true, label: "반", align: "center" },
  { id: "st_cnt", disablePadding: true, label: "학급인원", align: "center" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  paper: {
    marginLeft: 18,
    marginRight: 18,
    marginBottom: theme.spacing(2),
  },
  container: {
    // height: 800,
    // maxHeight: 800,
  },
  table: {},
  tableHead: {
    fontSize: '1.0rem'
  },

  table_row: {
    "padding-left": "0px",
    "padding-right": "0px",
    "padding-top": "10px",
    "padding-bottom": "10px",
  },

}));


export default function SchoolInfoTable(props) {
  const classes = useStyles();
  const [rows] = React.useState(data_all_members);
  const st_num = rows.length;

  return (
    <div>
      <Box display="flex" flexDirection="row" fontSize={18} p={1} fontWeight="fontWeightBold" >
        학급정보
        </Box>
      <Paper className={classes.paper}>
        <Table className={classes.table_row}>
          <TableBody>
            <TableRow className={classes.table_row}>
              <TableCell align="center" rowSpan={2} width="150px">
                <SchoolIcon style={{ fontSize: 70 }} />
              </TableCell>

              {headCells.map((headCell) => (
                <TableCell key={headCell.id} align={headCell.align} className={classes.table_row} padding="none">
                  <Box fontSize={17} fontWeight="fontWeightBold">
                    {headCell.label}
                  </Box>
                </TableCell>
              ))}

            </TableRow>

            {info_rows.map((row) => (
              <TableRow key={row.school}>
                <TableCell align="center">{row.school}</TableCell>
                <TableCell align="center">{row.grade}</TableCell>
                <TableCell align="center">{row.group}</TableCell>
                <TableCell align="center">{st_num}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
