import React, { Component } from "react";
import { Provider } from "react-redux";
import {
  Backdrop,
  // Button,
  CircularProgress,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  Fade,
  IconButton,
  MuiThemeProvider,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

import "./styles/App.scss";
import { theme } from "./theme/theme";

import configureStore from "./store/configureStore";
// import {
//   HideDialog,
//   HideToastMessage,
//   ResetDialogConfig,
// } from "./store/reducers/ui";

// import SplashView from "./components/SplashView";
import AppRouter from "./routers/AppRouter";
import VisangMobx from "./visang/store/VisangMobx";
import { CSSProperties } from "@material-ui/styles";

// import cookie from "react-cookies";
// import {LOGIN_TOKEN_AUTH_PAYLOAD} from '../Constants';

export const store = configureStore();


const styles: CSSProperties = {
  routerWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: theme.palette.primary.main,
  },
};

class App extends Component {
  state = {
    loaded: false,
  };

  constructor(props) {
    super(props);

    setTimeout(() => {
      this.setState({
        loaded: true,
      });
    }, 500);
    store.subscribe(() => {
      this.forceUpdate();
    });
  }

  componentDidMount = () => {
    console.log(">> componentDidMount");
  };

  componentWillUnmount = () => { };

  handleToastClose = () => { };

  renderToastMessage() {
    let isAlertToast = false;
    if (
      store.getState().ui.toast.toastType === "error" ||
      store.getState().ui.toast.toastType === "warning" ||
      store.getState().ui.toast.toastType === "info" ||
      store.getState().ui.toast.toastType === "success"
    ) {
      isAlertToast = true;
    }
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        message={store.getState().ui.toast.message}
        open={store.getState().ui.toast.show}
        autoHideDuration={3000}
        onClose={this.handleToastClose}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={this.handleToastClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      >
        {isAlertToast ? (
          <MuiAlert
            style={{
              color: theme.palette.getContrastText(
                theme.palette[store.getState().ui.toast.toastType].main
              ),
            }}
            onClose={this.handleToastClose}
            elevation={6}
            variant="filled"
            severity={store.getState().ui.toast.toastType}
          >
            {store.getState().ui.toast.message}
          </MuiAlert>
        ) : null}
      </Snackbar>
    );
  }

  render = () => {
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Fade in={this.state.loaded} timeout={700}>
            <div style={styles.routerWrapper}>
              <AppRouter/>
            </div>
          </Fade>
          <Backdrop
            style={styles.backdrop}
            open={store.getState().ui.progress.circular}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {this.renderToastMessage()}
        </MuiThemeProvider>
      </Provider>
    );
  };
}

export default App;
