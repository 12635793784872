import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { ThemeProvider, } from "@material-ui/core/styles";
import { theme } from "../../theme/theme";

import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableRow from "./EnhancedTableRow";

import {
  data_all_members,
  data_exam,
  DataSet,
} from "../../data/students_data";


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}




// 전체 학생들의 문제 풀이 시간과 시퀀스를 얻어낼 수 있다.
// 나중에 참고, 참조할 것, 2020-08-09
/**
 * @typedef AnswerSummary
 * @type {array}
 * @property {number} question_number - number of question
 * @property {number} time - total comsumed time of all applicants
 * @property {number} score - total score of all applicants
 * @property {number} tried - total tried count of all applicants
 */

/**
 *
 * @param {string} id
 *
 * @return {AnswerSummary}
 */
function personalResult(id) {

  const time_sumup = {};

  const examData = data_exam[id];
  // let userInfo = examData.userInfo;
  const answer_arr = examData.questionInfoArray;

  for (let j = 0; j < answer_arr.length; j++) {
    const ans_row = answer_arr[j];

    /** @type {string} */
    const q_num = ans_row.number;           // 문제 번호

    /** @type {string} */
    const q_numStr = ans_row.numberStr;           // 문제 번호

    /** @type {string} */
    const q_eval_elem = ans_row.evaluationElement;  // 성취 기준

    /** @type {string} */
    const q_eval_detail = ans_row.detailContent;      // 내용 요소

    /** @type {number} */
    const a_time = ans_row.solveTimeSec;    // 문제 풀이에 걸린 시간

    /** @type {number} */
    const a_seq_num = ans_row.sequenceNumber; // 전체 문제 풀이 순서 중에서 몇번째로 풀었는지 (시도를 안했으면 0)

    /** @type {boolean} */
    const a_correct = ans_row.isCorrect;    // 문제를 맞혔는지 안맞혔는지

    /** @type {boolean} */
    const a_tried = ans_row.isPlay;     // 문제를 풀려고 했는지 안했는지,

    // console.log(`${id}, Q-num:${q_num},  A-timesec:${a_time} == SEQ(${a_seq_num}), ans/tried=${a_correct}/${a_tried} `);

    if (!time_sumup.hasOwnProperty(q_num)) {
      time_sumup[q_num] = {
        q_num: [],
        q_numStr: [],
        q_eval_detail: [],
        q_eval_elem: "",
        time: 0,
        score: 0,
        tried: 0,
        count: 0,
      }
    }

    if (!time_sumup[q_num].q_num.find(q => q === q_num)) {
      time_sumup[q_num].q_num.push(q_num);
      time_sumup[q_num].q_numStr.push(q_numStr);
    }

    if (!time_sumup[q_num].q_eval_detail.find(q => q === q_eval_detail)) {
      time_sumup[q_num].q_eval_detail.push(q_eval_detail);
    }

    time_sumup[q_num].q_eval_elem = q_eval_elem;
    time_sumup[q_num].count++;
    time_sumup[q_num].time += a_time;
    time_sumup[q_num].score += a_correct ? 1 : 0;
    time_sumup[q_num].tried += a_tried ? 1 : 0;
  }

  // console.log(time_sumup);

  // 이제 정렬을 하자
  const sortable = [];
  for (const key in time_sumup) {
    sortable.push([
      time_sumup[key].q_num,            // 0: 문항번호
      "도형 두 걸음",                    // 1: 단계
      time_sumup[key].q_eval_elem,      // 2: 성취기준
      time_sumup[key].q_eval_detail,    // 3: 내용
      time_sumup[key].score,            // 4: 점수
      time_sumup[key].time,             // 5: 시간
      time_sumup[key].tried,            // 6: 시도 회수
      time_sumup[key].count,            // 7: 해당 문항수
      time_sumup[key].q_numStr,         // 8: 해당 문항 번호 문자열

    ]);
  }

  sortable.sort(function (a, b) {
    return a[0] - b[0];   // key로 정렬
  });

  // let total_time = 0;
  for (let k = 0; k < sortable.length; k++) {
    const ie = sortable[k];

    ie[0].sort((a, b) => a - b);     // q_num
    ie[3].sort((a, b) => a - b);     // q_eval_detail


    // total_time += sortable[k][1];
  }

  // console.log(sortable);

  return sortable;
}

function getTestResultDetail(id) {
  const arr = personalResult(id);

  const answer_items = [];

  arr.forEach(a => {
    const answer_data = {
      q_num: a[0],
      eval_level: a[1],
      eval_elem: a[2],
      eval_detail: a[3][0],
      correct: a[4],
      time: a[5],
      tried: a[6],
      q_numStr: a[8],
    };
    answer_items.push(answer_data);
  });

  return answer_items;
}








const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  paper: {
    marginLeft: 18,
    marginRight: 18,
    marginBottom: theme.spacing(2),
  },
  container: {
    // height: 800,
    // maxHeight: 800,
  },
  table: {},
  tableHead: {
    fontSize: '1.0rem'
  },

  table_row: {
    "padding-top": "10px",
    "padding-bottom": "10px",
  },

}));






// function createProblemData(num, area, problem, type, result) {
//   return { num, area, problem, type, result };
// }



function createData(row) {

  const id = row.id;
  const name = row.name;
  const time = row.time;
  const score = row.score;

  if (row.absent) {
    return {
      absent: row.absent,
      grade: "",
      group: "",
      st_num: "",
      name,
      id,
      time,
      score,
      detail: [],
    };
  }

  // let examData = data_exam[id];
  // let userInfo = examData.userInfo;

  const studentInfoStr = DataSet.getMemberProfile(id);
  let studentInfo = {
    grade: "",
    class: "",
    number: "",
  };

  if (studentInfoStr) {
    studentInfo = JSON.parse(studentInfoStr);
  }
  // console.log(`User Profile: ${id} => ${studentInfo}`);

  // let personal_detail = personalResult(id);
  const detail = getTestResultDetail(id);

  return {
    absent: row.absent,
    grade: studentInfo.grade, // userInfo.grade_info.grade,
    group: studentInfo.class, // userInfo.group_info.group,
    st_num: studentInfo.number, // userInfo.number_info.number,
    name,
    id,
    time,
    score,
    detail,
  };
}


const innerTableStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  table: {
    color: "red",
  },
  table_row: {
    padding: "10px",
  },
}));


// function ReactableIconButton(props) {
//   const { open, row } = props;
//   const [setOpen] = React.useState(false);

//   if (row.absent) {
//     return (
//       <React.Fragment>
//         <IconButton aria-label="expand row" size="small">
//           {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//         </IconButton>
//       </React.Fragment>

//     );
//   }

//   return (
//     <React.Fragment>
//       {/* <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}> */}
//       <IconButton aria-label="expand row" size="small">
//         {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//       </IconButton>
//     </React.Fragment>
//   )
// }

// EnhancedTableRow.propTypes = {
//   row: PropTypes.shape({
//     id: PropTypes.string.isRequired,
//     name: PropTypes.string.isRequired,
//     time: PropTypes.number.isRequired,
//     score: PropTypes.number.isRequired,

//     detail: PropTypes.arrayOf(
//       PropTypes.shape({
//         num: PropTypes.number.isRequired,
//         area: PropTypes.string.isRequired,
//         problem: PropTypes.string.isRequired,
//         type: PropTypes.string.isRequired,
//         result: PropTypes.string.isRequired,
//         time: PropTypes.number.isRequired,
//       }),
//     ).isRequired,

//   }).isRequired,
// };




export default function EvaluationTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("st_num");
  // const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [rows] = React.useState(data_all_members);

  // const [members] = React.useState(data_members);
  // console.log(members);

  const d_rows = rows.filter(row => (!row.absent));
  const r_rows = d_rows.map(row => createData(row));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleClick = (event, id) => {
  //   handleGotoResult(event, id);
  //   // this.setState({redirect: true});
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   console.log(`${selected} was clicked`);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0 && selectedIndex < 4) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   console.log();

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, r_rows.length - page * rowsPerPage);



  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        {/* <Paper elevation={0} className={classes.paper}> */}
        <TableContainer className={classes.container}>
          <Table size="medium"
            className={classes.table}
            aria-label="enhanced table"
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              classes={classes}
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={r_rows.length}
            />
            <TableBody>
              {stableSort(r_rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <EnhancedTableRow key={row.id+index} row={row} />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 40 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={r_rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {/* </Paper> */}
      </div>
    </ThemeProvider>
  );
}
