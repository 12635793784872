import React from "react";
// import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
// import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
// import TableSortLabel from "@material-ui/core/TableSortLabel";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

const innerTableStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  table: {
    color: "red",
  },
  table_row: {
    padding: "10px",
  },
}));


export default function TableAnswerDetail(props) {
  const { row, open, personal_result, id } = props;
  // const [setOpen] = React.useState(false);
  // const classes = useStyles();

  // const isItemSelected = isSelected(row.num);
  // const labelId = `enhanced-table-checkbox-${index}`;
  // const absent = row.absent;

  const inner_classes = innerTableStyles();

  return (
    <TableRow key={id}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={1}></TableCell>
      <TableCell key={`${id}_header`} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Table size="small" aria-label="answer detail" className={inner_classes.root}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" component="th" scope="row">번호</TableCell>
                  <TableCell align="center">단계</TableCell>
                  <TableCell align="left">성취기준</TableCell>
                  <TableCell align="right">문항 세부 내용</TableCell>
                  <TableCell align="right">정오</TableCell>
                  <TableCell align="right">풀이시도</TableCell>
                  <TableCell align="right">시간</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {personal_result.map((detailRow) => {
                  return (
                    <TableRow key={detailRow.q_num}>
                      {/* 문항번호 */}
                      <TableCell align="center" component="th" scope="row">
                        {detailRow.q_numStr}
                      </TableCell>

                      {/* 단계 */}
                      <TableCell align="center">
                        {detailRow.eval_level}
                      </TableCell>

                      {/* 성취기준 */}
                      <TableCell align="left">
                        <Box fontWeight="fontWeightLight">
                          {detailRow.eval_elem}
                        </Box>
                      </TableCell>

                      {/* 내용 */}
                      <TableCell align="center">
                        {detailRow.eval_detail}
                      </TableCell>

                      {/* 정오답여부 */}
                      <TableCell align="center">
                        {detailRow.tried > 0
                          ? (<Box fontWeight="fontWeightLight"> {detailRow.correct > 0 ? "O" : "X"} </Box>)
                          : (<Box color="red" fontWeight="fontWeightLight"> {detailRow.correct > 0 ? "O" : "X"} </Box>)
                        }
                      </TableCell>

                      {/* 시도여부 */}
                      <TableCell align="center">
                        {detailRow.tried > 0
                          ? (<Box fontWeight="fontWeightLight"> {detailRow.tried > 0 ? "O" : "X"} </Box>)
                          : (<Box color="blue" fontWeight="fontWeightLight"> {detailRow.tried > 0 ? "O" : "X"} </Box>)
                        }
                      </TableCell>

                      {/* 걸린시간 */}
                      <TableCell align="center">
                        {`${detailRow.time}초`}
                      </TableCell>

                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}