import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Chart, {
  // ArgumentAxis,
  Legend,
  Series,
  ValueAxis,
  Label,
  CommonSeriesSettings,
  Tick,
  Tooltip,
  // MinorTick,
} from "devextreme-react/chart";
import { Paper, Box } from "@material-ui/core";
import { data_total_class_average, data_all_members, data_exam } from "../../data/students_data";


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  paper: {
    marginLeft: 18,
    marginRight: 18,
    marginBottom: theme.spacing(2),
  },
  container: {
    // height: 800,
    // maxHeight: 800,
  },
  table: {},
  tableHead: {
    fontSize: '1.0rem'
  },

  table_row: {
    "padding-left": "0px",
    "padding-right": "0px",
    "padding-top": "10px",
    "padding-bottom": "10px",
  },

}));

// 전체 학생들의 문제 풀이 시간과 시퀀스를 얻어낼 수 있다.
// 나중에 참고, 참조할 것, 2020-08-09
/** 
 * @typedef AnswerSummary
 * @type {array}
 * @property {number} question_number - number of question
 * @property {number} time - total comsumed time of all applicants
 * @property {number} score - total score of all applicants
 * @property {number} tried - total tried count of all applicants
 */

/**
 * 
 * @param {Array.<Object>} rows 
 * 
 * @return {AnswerSummary}
 */
function summarizeTestResult(rows) {

  const time_sumup = {};

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const id = row.id;
    const name = row.name;
    // let time = row.time;
    // let score = row.score;

    const examData = data_exam[id];
    // let userInfo = examData.userInfo;
    const answer_arr = examData.questionInfoArray;

    for (let j = 0; j < answer_arr.length; j++) {
      const ans_row = answer_arr[j];

      /** @type {string} */
      const q_num = ans_row.number;           // 문제 번호 

      /** @type {string} */
      // let q_eval_elem = ans_row.evaluationElement;  // 성취 기준

      /** @type {string} */
      const q_eval_detail = ans_row.detailContent;      // 내용 요소

      /** @type {number} */
      const a_time = ans_row.solveTimeSec;    // 문제 풀이에 걸린 시간

      /** @type {number} */
      const a_seq_num = ans_row.sequenceNumber; // 전체 문제 풀이 순서 중에서 몇번째로 풀었는지 (시도를 안했으면 0)

      /** @type {boolean} */
      const a_correct = ans_row.isCorrect;    // 문제를 맞혔는지 안맞혔는지

      /** @type {boolean} */
      const a_tried = ans_row.isPlay;     // 문제를 풀려고 했는지 안했는지,

      // console.log(`${name} ${id}, Q-num:${q_num},  A-timesec:${a_time} == SEQ(${a_seq_num}), ans/tried=${a_correct}/${a_tried} `);

      // 여기서, 정렬할 key(키)를 설정, 가장 최신 소스, 2020-08-10 00:41
      const key = q_eval_detail;
      if (!time_sumup.hasOwnProperty(key)) {
        time_sumup[key] = {
          q_num: [],
          q_eval_elem: [],
          q_eval_detail: [],
          time: 0,
          score: 0,
          tried: 0,
          count: 0,
        }
      }

      if (!time_sumup[key].q_num.find(q => q === q_num)) {
        time_sumup[key].q_num.push(q_num);
      }

      if (!time_sumup[key].q_eval_detail.find(q => q === q_eval_detail)) {
        time_sumup[key].q_eval_detail.push(q_eval_detail);

      }

      time_sumup[key].count++;
      time_sumup[key].time += a_time;
      time_sumup[key].score += a_correct ? 1 : 0;
      time_sumup[key].tried += a_tried ? 1 : 0;
    }
  }
  // console.log(time_sumup);

  // 이제 정렬을 하자
  const sortable = [];
  for (const key in time_sumup) {
    sortable.push([
      time_sumup[key].q_num,            // 0: 문항번호
      "도형 두 걸음",                           // 1: 단계
      time_sumup[key].q_eval_elem,                              // 2: 성취기준
      time_sumup[key].q_eval_detail,    // 3: 내용
      time_sumup[key].score,            // 4: 점수
      time_sumup[key].time,             // 5: 시간
      time_sumup[key].tried,            // 6: 시도 회수
      time_sumup[key].count,            // 7: 해당 문항수

    ]);
  }

  sortable.sort(function (a, b) {
    return a[0] - b[0];   // key로 정렬
  });

  // let total_time = 0;
  for (let k = 0; k < sortable.length; k++) {
    const ie = sortable[k];

    ie[0].sort((a, b) => a - b);     // q_num
    ie[3].sort((a, b) => a - b);     // q_eval_detail

    // total_time += sortable[k][1];
  }

  // console.log(sortable);

  return sortable;
}

// function percentStr(num, under_digit = 1) {
//   let div = Math.pow(10, under_digit);
//   let ret = Math.round(num * div * 100) / div;
//   return ret;
// }

export default function SectionDetailChart(props) {
  const classes = useStyles();

  // const [data_source] = React.useState(data_total_class_average);
  const [raw_data] = React.useState(data_all_members);
  // const [result_data] = React.useState([]);
  let result_data = [];


  const customizeText = (e, source) => {
    // let index = e.value;
    return "";
    // return this.state.result_data[index][3][0];
  }

  const customizeTooltip = (arg, b) => {
    if (arg.seriesName === '시간(초)') {
      return {
        text: `풀이시간: ${arg.valueText}초`,
      };

    }
    return {
      text: `비율: ${arg.valueText}%`,
    };
  }

  // const [raw_data, setRows] = React.useState(data_all_members);
  const exam_result = raw_data.filter(row => (!row.absent));

  // let applicants_cnt = exam_result.length;

  /** @type {AnswerSummary} */
  const s_arr = summarizeTestResult(exam_result);
  result_data = s_arr;

  const data_source = [];
  for (let i = 0; i < s_arr.length; i++) {
    const item = s_arr[i];
    data_source.push(
      {
        level: i + 1,
        name: item[3][0],
        score: Math.round(item[4] / item[7] * 1000) / 10,
        time: Math.round(item[5] / item[7] * 10) / 10,
        tried: Math.round(item[6] / item[7] * 1000) / 10,
      }
    );
  }

  const contents_source = [];
  for (let j = 0; j < s_arr.length; j++) {
    const item = s_arr[j];
    contents_source.push(
      {
        value: item[0],
        name: item[3][0],
      }
    );
  }

  return (
    <div>
      <Box display="flex" flexDirection="row"  fontSize={18} p={1} fontWeight="fontWeightBold" >
        내용별 성취율 그래프
      </Box>

      <Paper className={classes.paper} style={{ height: "450px" }}>

        <Chart palette="Material" dataSource={data_source} rotated={false} id="chart">
          {/* <Chart palette="Material" dataSource={this.state.data_source} rotated={false} id="chart"> */}
          <CommonSeriesSettings barPadding={0.5} argumentField="name" />

          {/* <ArgumentAxis tickInterval={1} name="name">
            <Label customizeText={this.customizeText} />
          </ArgumentAxis> */}

          <ValueAxis name="percent">
            <Tick visible={true} />
            <Label visible={true} />
          </ValueAxis>

          <ValueAxis name="time" position="right">
            <Tick visible={true} />
            <Label visible={true} />
          </ValueAxis>

          <Series  axis="time" valueField="time" argumentField="name" name="시간(초)" type="bar">
            {/* <Point symbol="" /> */}
          </Series>

          <Series  axis="percent" valueField="score" argumentField="name" name="성취도(%)" >
            <Label visible={true} />
          </Series>

          <Series  axis="percent" valueField="tried" argumentField="name" name="시도율(%)" >
            <Label visible={true} />
          </Series>

          <Tooltip
            enabled={true}
            customizeTooltip={customizeTooltip}
          />

          <Legend visible={true} />
        </Chart>
      </Paper>
    </div>
  );
}
