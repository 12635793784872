import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import {
  AppBar,
  Badge,
  CssBaseline,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  ListItemIcon,
} from "@material-ui/core";

import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircle from "@material-ui/icons/AccountCircle";

import Icon from "@mdi/react";
import { mdiLogout } from "@mdi/js";

import PropTypes from "prop-types";

import { AppContext } from "../AppContext";
import MainRoutePath from "../routers/MainRoutePath";
import DrawerMenu from "../components/DrawerMenu";

import { logout } from "../store/reducers/authorization";
import { ShowUIProgressBackdrop, HideUIProgressBackdrop, } from "../store/reducers/ui";
import { MembersDB } from "../services/members";
import { EvaluationResultDB } from "../services/eveluation_result";
import { ExamSummaryProcessor } from "../services/exam_summary_processor";


import { fetchInfo } from "../services/data_loader";

const styles = (theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuTitle: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
});

/**
 * Main Page
 */
class MainPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  static title = "Main Page";

  constructor(props) {
    super(props);
    this.onDrawerOpenStatus = this.onDrawerOpenStatus.bind(this);

    const memberDB = new MembersDB();
    const examResult = new EvaluationResultDB();
    const examSummaryProcessor = new ExamSummaryProcessor();
    this.state = {
      "user-menu-anchor": null,
      pageTitle: null,
      subPageTitle: null,
      userMenuItems: [
        {
          title: "Logout",
          icon: mdiLogout,
          menuId: "logout",
        },
      ],
      isLoading: false,

      // kitty, 2020-08-16
      fetching: false, // tells whether the request is waiting for response or not
      memberDB: memberDB,
      examResult: examResult,
      examSummaryProcessor: examSummaryProcessor,
      data_load_fail: false,
    };
  }

  async componentDidMount() {
    ShowUIProgressBackdrop();
    await fetchInfo(this, this.props.tabNum);
    HideUIProgressBackdrop();
    this.setState({
      isLoading: true,
    });

    this.renderPageTitle(this.props.location.pathname);
    this.historyListener = this.props.history.listen((location, action) => {
      this.renderPageTitle(location.pathname);
    });


  }

  async shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.tabNum !== this.props.tabNum) {
      // console.log("\n\nUPDATE\n\n\n");
      const memberDB = new MembersDB();
      const examResult = new EvaluationResultDB();
      const examSummaryProcessor = new ExamSummaryProcessor();

      this.state = {
        ...this.state,

        fetching: true,
        memberDB: memberDB,
        examResult: examResult,
        examSummaryProcessor: examSummaryProcessor,
      };

      ShowUIProgressBackdrop();
      await fetchInfo(this, nextProps.tabNum);
      HideUIProgressBackdrop();
      this.setState({ fetching: false, });

      // this.state.renderer.replayStart();
      // return false;
    }

    return true;
  }

  componentWillUnmount() {
    if (this.historyListener) {
      this.historyListener();

    }
  }

  renderPageTitle(currentLocation) {
    const selected = MainRoutePath.currentMenuItems(
      this.props.match.path,
      currentLocation
    );

    if (selected.selectedSubMenuItem !== null) {
      this.setState({
        pageTitle: selected.selectedMenuItem.title,
        subPageTitle: selected.selectedSubMenuItem.title,
      });
    } else if (selected.selectedMenuItem !== null) {
      this.setState({
        pageTitle: selected.selectedMenuItem.title,
        subPageTitle: null,
      });
    }
  }

  onDrawerOpenStatus = (isOpen) => { };

  onClickUserMenuButton = (event) => {
    this.setState({
      "user-menu-anchor": event.currentTarget,
    });
  };

  onUserMenuClose = () => {
    this.setState({
      "user-menu-anchor": null,
    });
  };

  onClickUserMenu = (event, menuItem) => {
    switch (menuItem.menuId) {
      case "logout": {
        logout();
        this.props.history.push("/");
        break;
      }
      default: {
        break;
      }
    }

    this.setState({
      "user-menu-anchor": null,
    });
  };

  render = () => {
    const { classes, tabNum } = this.props;
    // console.log(`TAB VALUE = ${tabNum}`);

    if (this.state.data_load_fail) {
      logout();
      this.props.history.push("/");
    }

    return (
      <div style={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar variant="regular">
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" ></IconButton>
            <AppContext.Consumer>
              {(ctxValue) => (
                <Typography variant="h6" className={classes.menuTitle}>
                  {ctxValue.projectName} - {this.state.pageTitle}{" "}
                  {this.state.subPageTitle
                    ? `> ${this.state.subPageTitle}`
                    : ""}
                </Typography>
              )}
            </AppContext.Consumer>

            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge anchorOrigin={{ vertical: "top", horizontal: "left", }} badgeContent={0} color="secondary" >
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton edge="end" aria-label="account of current user" aria-controls="user-menu" aria-haspopup="true" onClick={this.onClickUserMenuButton} color="inherit" >
              <AccountCircle />
            </IconButton>

            <Menu id="user-menu" anchorEl={this.state["user-menu-anchor"]} getContentAnchorEl={null} anchorOrigin={{ vertical: "bottom", horizontal: "right", }} keepMounted open={Boolean(this.state["user-menu-anchor"])} onClose={this.onUserMenuClose} >
              {this.state.userMenuItems.map((item) => (
                <MenuItem
                  key={item.menuId}
                  onClick={(e) => {
                    this.onClickUserMenu(e, item);
                  }}
                >
                  <ListItemIcon>
                    <Icon path={item.icon} size={1} />
                  </ListItemIcon>
                  <Typography variant="inherit">{item.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Toolbar>
        </AppBar>

        <DrawerMenu onDrawerOpenStatus={this.onDrawerOpenStatus} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route path={`${this.props.match.path}`} exact={true}>
              <Redirect
                to={`${this.props.match.path}/${MainRoutePath.defaultPath}`}
              />
            </Route>
            {MainRoutePath.children.map((child, index) => {
              if (this.state.isLoading == false) {
                return <div></div>;
              } else {
                {/* console.log( `MainRoutePath: ${this.props.match.path}/${child.path}` ); */}
                return (
                  <Route
                    key={child.path}
                    path={`${this.props.match.path}/${child.path}`}
                    render={(props) => (
                      <child.component
                        {...props}
                        isLoading={this.state.isLoading}
                      />
                    )}
                  />
                );
              }
            })}
          </Switch>
        </main>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  const ret = {
    tabNum: state.TabReducer.tabNum,
  };
  return ret;
};

export default withRouter(withStyles(styles)(connect(mapStateToProps)(MainPage)));
