// import React from "react";

export const data_all_members_list = [];
export const data_all_members = [];
export const data_members = [];
export const data_exam = {};

export const architectureSources = [
  { value: 'cnt', key: 'score', name: '학생수' }
];

export const data_class_score_chart = [];

export const data_our_class_average = [];

export const data_total_class_average = [];

export const data_participation = [];

export const data_score_by_zone = [
  // { subject: '수학', zone: '기타영역', total: 0 },
];

export const data_selected_user = [];

export const data_result_elements = {};

export const data_class_statics = {};

export const data_my_average = [];

export const data_my_levels = [];

export const data_my_spider = [];

export const data_my_spider_chart_resource = [
  { value: 'score', name: '점수' }
];

export const data_my_ranking = {};


export const data_write_time_sources = [
  { value: 'question', name: '문제영역 평균속도 (ms)', color: '#ffd700' },
  { value: 'answer', name: '답안영역 평균속도 (ms)', color: '#cd7f32' }
];

export const data_write_time = [];

export const data_problem_list = [];

export const DataSet = {
  getMemberProfile(id) {
    let member = data_all_members_list.find(elem => elem.id === id);
    if (member) {
      return member.extra;
    }

    return null;
  },

  /**
   * 
   * @param {string} [school_name] - 학교 
   * @param {number} [grade] - 학년
   * @param {number} [group] - 반
   * 
   * @return {number} student count of the class group
   */
  getStudentCnt(school_name, grade, group) {
    // custom hook을 쓰든지, react function으로 만들어야 한다.

    // 이 각 학교별 데이터를 주도록부분 수정해야 한다.
    // const [rows, setRows] = React.useState(data_all_members_list);

    // return data_all_members_list.length;
    return 0;
  },

  /**
   * 시험별 응시자수를 집계한다. 시험이름과 학교 등의 filter는 아직 구현되지 않았다. 2020-08-09
   * @param {string} exam_type 
   * @param {string} exam_name 
   * @param {string} exam_subject 
   * @param {string} [school_name]
   * @param {number} [grade]
   * @param {number} [group]
   * 
   * @return {number} student count of the class group
   */
  getApplicantCnt(exam_type, exam_name, exam_subject, school_name, grade, group) {
    // custom hook을 쓰든지, react function으로 만들어야 한다.

    // var a_rows = data_all_members.filter(row => (!row.absent));
    // return a_rows.length;
    return 0;
  },
};



/**
 * 
 * @param {string} [school_name] - 학교 
 * @param {number} [grade] - 학년
 * @param {number} [group] - 반
 * 
 * @return {number} student count of the class group
 */
export function getStudentCnt(school_name, grade, group) {
  // custom hook을 쓰든지, react function으로 만들어야 한다.

  // 이 각 학교별 데이터를 주도록부분 수정해야 한다.
  // return data_all_members_list.length;

  return 0;
}


