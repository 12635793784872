import { configure, observable, action, runInAction, flow } from 'mobx';
import { asyncAction } from "mobx-async-action";
import { courses, lessons } from '../constants/VisangConstants';
import { getJsonData, getVisangLessonData, makeVisangStrokeFileUrl } from './UrlExistTest';
import { VisangMembersDB } from './VisangMembersDB';

type HasLessonType = {
  [course: number]: {
    [lesson: number]: boolean,
  }
}

type StrokeJsonData = {
  [course: number]: {
    [lesson: number]: {
      [id: string]: string,
    }
  }
}

interface LessonType {
  course: string,
  lesson: string,

  /** key:studentId, value: strokeUrl */
  strokeUrls: { [key: string]: string },

  strokes: { [key: string]: object },
}

export default class VisangLesson {
  data: LessonType = {
    course: "N/A",
    lesson: "N/A",
    strokeUrls: {},
    strokes: {},
  };

  exist = false;


  getData = (course: number, lesson: number, ids: string[]) => {
    this.data.course = courses[course];
    this.data.lesson = lessons[lesson];

    const promises: Promise<string>[] = [];
    let exist = false;

    ids.forEach((id) => {
      const url = makeVisangStrokeFileUrl(id, course, lesson);
      const pr = getJsonData(url);
      promises.push(pr);

      pr.then(json => {
        if (json) {
          this.data.strokeUrls[id] = json.requestUri;
          exist = true;
        }
      });
    });


    return new Promise(resolve => {
      Promise.all(promises).then(data => {
        this.exist = exist;
        resolve(exist);
      });
    }) as Promise<boolean>;
  }

  setStrokeData = async () => {
    const ids = Object.keys(this.data.strokeUrls);
    const { course, lesson } = this.data;

    const promises: Promise<string>[] = [];
    ids.forEach(id => {
      const url = this.data.strokeUrls[id];
      const pr = getJsonData(url);
      promises.push(pr);

      pr.then(json => {
        if (json) {
          this.data.strokes[id] = json;
        }
      });
    });

    await Promise.all(promises);
  }

  get strokes() {
    return this.data.strokes;
  }

}
