import React from "react";

import DashboardIcon from "@material-ui/icons/Dashboard";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
// import NoteIcon from "@material-ui/icons/Note";

import VisangDetail from "../pages/VisangDetail";
import { store } from "../../App";
import { lessonNames, lessons, numLesseons } from "../constants/VisangConstants";
import VisangLesson from "../data/VisangLesson";
import { refreshDrawer } from "../store/VisangRedux";
import { HideUIProgressBackdrop, ShowUIProgressBackdrop } from "../../store/reducers/ui";
import ReplayPage from "../../pages/ReplayPage";

interface DrawerItemType {
  path: string,
  component: any,
  title?: string,
  icon?: JSX.Element,

  isMenuItem: boolean,
  isDummy?: boolean,

  subMenuItems?: DrawerItemType,
}



let _drawer_instance: VisangDrawerRoutePath = undefined;

const itemBase = {
  path: "student",
  component: VisangDetail,
  title: "데이터 로딩 중",
  icon: undefined,

  isMenuItem: true,
  isDummy: false,

  subMenuItems: undefined,
};

const defaultMenu: DrawerItemType[] = [
  { ...itemBase }
];

const subPages: DrawerItemType[] = [
  { ...itemBase },
  {
    path: "replay",
    component: ReplayPage,
    isMenuItem: false,
  },
];



export class VisangDrawerRoutePath {
  defaultPath = "student";
  children = defaultMenu.filter((item) => item.isDummy !== true);
  menuItems = defaultMenu.filter((item) => item.isMenuItem === true);

  course = -1;

  dataRefreshed = -1;

  constructor() {
    if (_drawer_instance) return _drawer_instance;
    store.subscribe(this.onStateChanged);
  }

  static getInstance() {
    if (!_drawer_instance) {
      _drawer_instance = new VisangDrawerRoutePath();
    }
    return _drawer_instance;
  }

  refresh = async (course: number) => {
    const state = store.getState();
    this.course = course;

    const la = state.visang.lessonAvailable[course];

    this.children = [];
    this.menuItems = [];

    for (let l = 0; l < numLesseons; l++) {
      const has = la[l];
      if (has) {
        const path = `student?course=${course}&lesson=${l}`;
        const item: DrawerItemType = { ...itemBase, path, title: lessonNames[l] };
        this.children.push(item);
        this.menuItems.push(item);
      }
    }

    this.children.push(...subPages);
    console.log(`refresh: course=${course}                COMPLETED`);
  }

  onStateChanged = async () => {
    const nextState = store.getState();

    if (nextState.visang.dataRefreshed !== this.dataRefreshed) {
      this.dataRefreshed = nextState.visang.dataRefreshed;
      await this.refresh(nextState.visang.course);
      refreshDrawer();
    }
  }


  currentMenuItems = (rootPath, currentPath) => {
    // console.log(`rootPath : ${rootPath}, currentPath : ${currentPath}`);
    let selectedMenu = null;
    let selectedSubMenu = null;
    const menus = this.children.filter((item) => item.isMenuItem === true);
    const matchedMenus = menus.filter(
      (item) => {
        const r = (currentPath === `${rootPath}/${item.path}`);
        return r;
      }
    );

    if (matchedMenus.length > 0) {
      if (matchedMenus.length > 1) {
        console.warn("Matched menu item is more than 1.");
      }
      selectedMenu = matchedMenus[0];
    }

    if (selectedMenu !== null) {
      if (currentPath !== `${rootPath}/${selectedMenu.path}`) {
        if (
          selectedMenu.subMenuItems !== null ||
          selectedMenu.subMenuItems !== undefined
        ) {
          const matchedSubMenus = selectedMenu.subMenuItems.filter(
            (item) =>
              currentPath.indexOf(
                `${rootPath}/${selectedMenu.path}/${item.path}`
              ) === 0
          );
          if (matchedSubMenus.length > 0) {
            if (matchedSubMenus.length > 1) {
              console.warn("Matched Sub Menu item is more than 1.");
            }
            selectedSubMenu = matchedSubMenus[0];
          }
        }
      }
    }

    return {
      selectedMenuItem: selectedMenu,
      selectedSubMenuItem: selectedSubMenu,
    };

  }
}
