import React from "react";

import DashboardIcon from "@material-ui/icons/Dashboard";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
// import NoteIcon from "@material-ui/icons/Note";

import DashboardPage from "../pages/DashboardPage";
import StudentsPage from "../pages/StudentsPage";
import ReplayPage from "../pages/ReplayPage";
const children = [
  {
    path: "dashboard",
    component: DashboardPage,
    isMenuItem: true,
    title: "전체 보기",
    icon: <DashboardIcon />,
  },
  {
    path: "students",
    component: StudentsPage,
    isMenuItem: true,
    title: "학생별 보기",
    icon: <PermIdentityIcon />,
  },
  // {
  //   path: "result",
  //   component: ResultPage,
  //   isMenuItem: false,
  // },
  {
    path: "replay",
    component: ReplayPage,
    isMenuItem: false,
  },
  // {
  //   path: "problem",
  //   component: ProblemPage,
  //   isMenuItem: false,
  // },
];

export default {
  defaultPath: "dashboard",
  children: children.filter((item) => item.isDummy !== true),
  menuItems: children.filter((item) => item.isMenuItem === true),

  currentMenuItems: (rootPath, currentPath) => {
    // console.log(`rootPath : ${rootPath}, currentPath : ${currentPath}`);
    let selectedMenu = null;
    let selectedSubMenu = null;
    const menus = children.filter((item) => item.isMenuItem === true);
    const matchedMenus = menus.filter(
      (item) => currentPath === `${rootPath}/${item.path}`
    );
    
    if (matchedMenus.length > 0) {
      if (matchedMenus.length > 1) {
        console.warn("Matched menu item is more than 1.");
      }
      selectedMenu = matchedMenus[0];
    }

    if (selectedMenu !== null) {
      if (currentPath !== `${rootPath}/${selectedMenu.path}`) {
        if (
          selectedMenu.subMenuItems !== null ||
          selectedMenu.subMenuItems !== undefined
        ) {
          const matchedSubMenus = selectedMenu.subMenuItems.filter(
            (item) =>
              currentPath.indexOf(
                `${rootPath}/${selectedMenu.path}/${item.path}`
              ) === 0
          );
          if (matchedSubMenus.length > 0) {
            if (matchedSubMenus.length > 1) {
              console.warn("Matched Sub Menu item is more than 1.");
            }
            selectedSubMenu = matchedSubMenus[0];
          }
        }
      }
    }

    return {
      selectedMenuItem: selectedMenu,
      selectedSubMenuItem: selectedSubMenu,
    };
  },
};
