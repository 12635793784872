import React from "react";
// import React, { Component } from 'react';
import PropTypes from "prop-types";

import PageRenderer from "./PageRenderer";
import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { getTabName, EXAM_FILE_RESOURCES, VISANG_RESOURCES } from "../data/Constant";


export const PLAYSTATE = {
  play: "PLAY",
  stop: "STOP",
  pause: "PAUSE",
  rewind: "REWIND",
  trackRewind: "REWIND_TRACK",
  setAutoStop: "SET_AUTO_STOP",
  unsetAutoStop: "UNSET_AUTO_STOP",
};



const styles = (theme) => ({
  root: {
    overflow: "hidden",
    display: "flex",
    // position: "relative",
    paddingTop: 0,
    height: "100%",
    width: "100%",
  },
  paper: {
    margin: "auto",
  },
});

interface Props {
  tabNum: number,
  playTimeHandler: Function,
  playStateHandler: Function,


  scaleType: number, // 0: fit scale, 1: fit height, 2: fit width
  scale: number,

  pageNo: number,
  pages,

  strokeStream,

  isPlay: boolean,

  replaySpeed: number,
  playTime: number,
  pageClickHandle: Function,
  caption: string,
  stopTrigger: boolean,
  rewindTrigger: boolean,

  autoStop: boolean,

  isVisang?: boolean,
  visangCourse?: number,
  visangLesson?: number,



  rect?,
  bgurl?: string,
  page?,
  note?,
  saveBind?: Function,
  seekHandler?: Function,

  classes?

}

interface State {
  renderer: PageRenderer,
  rect: {
    x: number,
    y: number,
    width: number,
    height: number,
  },
  sizeUpdate: number,
}

export function getReplayBgUrl(args: { pageNo: number, tabNum: number, isVisang: boolean, visangLesson: number, visangCourse: number }) {
  const { pageNo, tabNum, isVisang, visangLesson, visangCourse } = args;

  if (isVisang) {
    const courseName = `course_${visangCourse}`;
    const bg_header = VISANG_RESOURCES[courseName].pageImagePrefix;
    const filePath = window.location.origin + bg_header + pageNo + ".jpg";
    return filePath;
  }
  else {
    const tab_info = getTabName(tabNum);
    const bg_header = EXAM_FILE_RESOURCES[tab_info].pageImagePrefix;
    const filePath = window.location.origin + bg_header + pageNo + ".jpg";
    return filePath;
  }
}




class KerisReplay extends React.Component<Props, State> {
  state = {
    /** @type {PageRenderer} */
    renderer: null,
    rect: {
      x: 0,
      y: 0,
      width: 88.58,
      height: 125.28,
    },
    sizeUpdate: 0,
  };
  canvasRef;
  size;
  constructor(props) {
    super(props);
    // kitty
    this.canvasRef = React.createRef();
  }


  shouldComponentUpdate(nextProps: Props, nextState: State) {
    let ret_val = true;

    if (nextProps.isPlay !== this.props.isPlay) {
      if (nextProps.stopTrigger) {
        this.state.renderer.replayStop();
      }
      if (nextProps.rewindTrigger) {
        this.state.renderer.replayRewind();
      }

      if (nextProps.isPlay) {
        this.state.renderer.replayStart();
      } else {
        this.state.renderer.replayPause();
      }
      ret_val = false;
    }

    // if (nextProps.isSaveTrigger !== this.props.isSaveTrigger) {
    //   this.saveCanvas();
    //   return false;
    // }

    if (nextProps.replaySpeed !== this.props.replaySpeed) {
      this.state.renderer.setReplaySpeed(nextProps.replaySpeed);
      ret_val = false;
    }

    if (nextProps.pageNo !== this.props.pageNo) {
      const { pageNo, tabNum, scale, isVisang, visangLesson, visangCourse } = nextProps;

      const filePath = getReplayBgUrl({ pageNo, tabNum, isVisang, visangLesson, visangCourse });
      
      this.state.renderer.setCanvas(this.size, filePath);
      const page = this.props.pages.filter((p) => p.pageNumber === pageNo)[0];
      if (page) {
        this.state.renderer.setPage(page);
        this.state.renderer.preparePage(
          page,
          this.state.rect,
          this.size,
          scale
        );
      }

      ret_val = false;
    }

    if (nextProps.playTime !== this.props.playTime) {
      this.state.renderer.setTimePoint(nextProps.playTime);
      ret_val = false;
    }

    if (nextProps.autoStop !== this.props.autoStop) {
      this.state.renderer.setAutoStop(nextProps.autoStop);
      ret_val = false;
    }

    if (nextProps.caption !== this.props.caption) {
      console.log(" caption changed ");
      ret_val = false;
    }

    if (nextProps.stopTrigger) {
      this.state.renderer.replayStop();
      ret_val = false;
    }

    if (nextProps.rewindTrigger) {
      this.state.renderer.replayRewind();
      ret_val = false;
    }

    return ret_val;
  }

  initRenderer(size) {
    const {
      pages, pageNo,
      scale,
      playTimeHandler,
      playStateHandler,
      strokeStream,
      tabNum,
      autoStop,
      isVisang, visangLesson, visangCourse
    } = this.props;
    const { rect } = this.state;
    const page = pages.filter((p) => p.pageNumber === pageNo)[0];

    const renderer = new PageRenderer(
      "c",
      playTimeHandler,
      playStateHandler,
      strokeStream,
      autoStop,
    );

    const filePath = getReplayBgUrl({ pageNo, tabNum, isVisang, visangLesson, visangCourse });
      

    // const tab_info = getTabName(tabNum);
    // const bg_header = EXAM_FILE_RESOURCES[tab_info].pageImagePrefix;
    // const bgurl = window.location.origin + bg_header + pageNo + ".jpg";
    // // let bgurl = window.location.origin + "/img/3_27_1089_" + pageid + ".jpg";

    renderer.setCanvas(size, filePath);
    renderer.setPage(page);
    renderer.preparePage(page, rect, size, scale);
    renderer.setSeekHandeler(this.props.seekHandler);
    this.setState({ renderer: renderer });
  }

  componentDidMount() {
    const size = this.size;
    const { pages, pageNo } = this.props;
    const { rect } = this.state;
    const page = pages.filter((p) => p.pageNumber === pageNo)[0];
    console.log("Draw Stroke size", page.strokes.length, "canvas size", size, "rect", rect);

    this.initRenderer(this.size);
    window.addEventListener("resize", this.resizeListener);
  }

  resizeListener = () => {
    this.setState({ sizeUpdate: this.state.sizeUpdate + 1 });
  };

  getSize = (scale, rect, scaleType) => {
    const vertical_margin = 240;
    const horizontal_margin = 200 + 200; // left 200, right 200
    const pageHeight = window.innerHeight - vertical_margin;
    let h = pageHeight - 20; // for divider
    let w = window.innerWidth - horizontal_margin;

    if (scaleType === 1) {
      h = h * scale * 0.01;
      w = (h * rect.width) / rect.height;
      // scale to width
    } else if (scaleType === 2) {
      w = w * scale * 0.01;
      h = (w * rect.height) / rect.width;
    }

    console.log("!!!!!!!!!", w, h, rect.height, rect.width);

    const size = {
      w: w,
      h: h,
    };

    return size;
  };

  componentWillUnmount() {
    this.state.renderer.stopInterval();
    window.removeEventListener("resize", this.resizeListener);
  }

  render() {
    const { classes, scaleType, scale } = this.props;
    const { rect } = this.state;
    this.size = this.getSize(scale, rect, scaleType);

    if (this.state.renderer) {
      // console.log("render resize", this.size)
      this.state.renderer.resize(this.size);
    }
    return (
      <div id="replayContainer" className={classes.root}>
        <Paper
          className={classes.paper}
          style={{ height: this.size.h + 10, width: this.size.w }}
        >
          <canvas id="c" ref={this.canvasRef} />
        </Paper>
      </div>
    );
  }
}

// KerisReplay.propTypes = propTypes;
// KerisReplay.defaultProps = defaultProps;

export default withStyles(styles)(KerisReplay);
