import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Box } from "@material-ui/core";
import {
  data_all_members,
  data_exam,
} from "../../data/students_data";

import "../../typedef";


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  paper: {
    marginLeft: 18,
    marginRight: 18,
    marginBottom: theme.spacing(2),
  },
  container: {
    // height: 800,
    // maxHeight: 800,
  },
  table: {},
  tableHead: {
    fontSize: '1.0rem'
  },

  table_row: {
    "padding-left": "0px",
    "padding-right": "0px",
    "padding-top": "10px",
    "padding-bottom": "10px",
  },

}));


// function getTestResultDetail(id) {
//   let answer_items = [];
//   let examData = data_exam[id];
//   let questionList = examData.questionInfoArray;

//   console.log('Detail answer information is processing', id);

//   questionList.forEach(question => {
//     console.log(question);

//     // answer_items.push(problemData);
//   });


//   return answer_items;
// }





/**
 *
 * @param {Array.<Object>} rows
 *
 * @return {AnswerSummary}
 */
function summarizeTestResult(rows) {

  const time_sumup = {};

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const id = row.id;
    // let name = row.name;
    // let time = row.time;
    // let score = row.score;

    const examData = data_exam[id];
    // let userInfo = examData.userInfo;
    const answer_arr = examData.questionInfoArray;

    for (let j = 0; j < answer_arr.length; j++) {
      const ans_row = answer_arr[j];

      /** @type {string} */
      const q_num = ans_row.number;           // 문제 번호
      const q_numStr = ans_row.numberStr;           // 문제 번호

      /** @type {number} */
      const a_time = ans_row.solveTimeSec;    // 문제 풀이에 걸린 시간

      /** @type {number} */
      // let a_seq_num = ans_row.sequenceNumber; // 전체 문제 풀이 순서 중에서 몇번째로 풀었는지 (시도를 안했으면 0)

      /** @type {boolean} */
      const a_correct = ans_row.isCorrect;    // 문제를 맞혔는지 안맞혔는지

      /** @type {boolean} */
      const a_tried = ans_row.isPlay;     // 문제를 풀려고 했는지 안했는지,

      // console.log(`${name} ${id}, Q-num:${q_num},  A-timesec:${a_time} == SEQ(${a_seq_num}), ans/tried=${a_correct}/${a_tried} `);

      if (!time_sumup.hasOwnProperty(q_num)) {
        time_sumup[q_num] = {
          q_numStr, 
          time: 0,
          score: 0,
          tried: 0,
        }
      }


      time_sumup[q_num].time += a_time;
      time_sumup[q_num].score += a_correct ? 1 : 0;
      time_sumup[q_num].tried += a_tried ? 1 : 0;
    }
  }
  // console.log(time_sumup);

  // 이제 정렬을 하자
  const sortable = [];
  for (const q_num in time_sumup) {
    sortable.push([
      time_sumup[q_num].q_numStr,                      // 0: 문제번호
      time_sumup[q_num].time,     // 1: 풀이시간
      time_sumup[q_num].score,    // 2: 정답수
      time_sumup[q_num].tried,    // 3: 시도회수
      q_num
    ]);
  }

  sortable.sort(function (a, b) {
    return a[4] - b[4];   // key로 정렬
  });

  // let total_time = 0;
  // for (let k = 0; k < sortable.length; k++) {
  //   total_time += sortable[k][1];
  // }

  // console.log(sortable);

  return sortable;
}

function percentStr(num, under_digit = 1) {
  const div = Math.pow(10, under_digit);
  const ret = Math.round(num * div * 100) / div;
  return ret;
}

function unifiedStr(num, under_digit = 1) {
  const div = Math.pow(10, under_digit);
  const ret = Math.round(num * div) / div;
  return ret;
}



export default function ExamSummaryTable(props) {
  // const { classes } = props;
  const classes = useStyles();
  const [raw_data] = React.useState(data_all_members);
  const exam_result = raw_data.filter(row => (!row.absent));

  const applicants_cnt = exam_result.length;

  /** @type {AnswerSummary} */
  const s_arr = summarizeTestResult(exam_result);

  const sum = [0, 0, 0, 0];
  const q_cnt = s_arr.length;

  for (let i = 0; i < s_arr.length; i++) {
    const item = s_arr[i];

    sum[1] += item[1];    // 시간
    sum[2] += item[2];    // 정답수
    sum[3] += item[3];    // 시도 회수
  }

  return (
    // <ThemeProvider theme={theme}>
    //   <TableContainer component={Paper}>
    <div>
      <Box display="flex" flexDirection="row"  fontSize={18} p={1} fontWeight="fontWeightBold">
        평가결과
      </Box>

      <Paper className={classes.paper}>
        <Table>
          <TableHead>
            <TableRow className={classes.table_row}>
              <TableCell align="center" className={classes.table_row} padding="none">
                <Box fontSize={17} fontWeight="fontWeightBold">
                  문항번호
            </Box>
              </TableCell>
              <TableCell align="center" className={classes.table_row} padding="none">
                <Box fontSize={16} fontWeight="fontWeightBold">
                  전체
            </Box>
              </TableCell>

              {
                s_arr.map((item) => (
                  <TableCell key={item[0]} align="center" className={classes.table_row} padding="none">
                    <Box fontSize={17} fontWeight="fontWeightBold">
                      {item[0]}
                    </Box>
                  </TableCell>
                ))}

            </TableRow>
          </TableHead>

          <TableBody>

            {/* 풀이시간 */}
            <TableRow className={classes.table_row}>
              <TableCell align="center" className={classes.table_row} padding="none">
                <Box fontSize={16} fontWeight="fontWeightMedium">
                  평균 풀이시간
            </Box>
              </TableCell>

              <TableCell align="center" className={classes.table_row} padding="none">
                <Box fontSize={16} fontWeight="fontWeightMedium">
                  {`${unifiedStr(sum[1] / (applicants_cnt * q_cnt))}초`}
                </Box>
              </TableCell>

              {
                s_arr.map((item) => (
                  <TableCell align="center" className={classes.table_row} padding="none" key={item[0]}>
                    <Box fontWeight="fontWeightMedium">
                      {`${unifiedStr(item[1] / applicants_cnt)}초`}
                    </Box>
                  </TableCell>
                ))}
            </TableRow>

            {/* 풀이시간 */}
            <TableRow className={classes.table_row}>
              <TableCell align="center" className={classes.table_row} padding="none">
                <Box fontSize={16} fontWeight="fontWeightMedium">
                  평균 정답율
            </Box>
              </TableCell>

              <TableCell align="center" className={classes.table_row} padding="none">
                <Box fontSize={16} fontWeight="fontWeightMedium">
                  {`${percentStr(sum[2] / (applicants_cnt * q_cnt), 0)}%`}
                </Box>
              </TableCell>


              {
                s_arr.map((item) => (
                  <TableCell align="center" className={classes.table_row} padding="none" key={item[0]}>

                    {
                      item[2] / item[3] < 0.7
                        ? (<Box color="red" fontWeight="fontWeightBold">{`${percentStr(item[2] / applicants_cnt, 0)}%`}</Box>)
                        : (<Box fontWeight="fontWeightMedium">{`${percentStr(item[2] / applicants_cnt, 0)}%`}</Box>)}


                  </TableCell>
                ))}
            </TableRow>

            {/* 풀이시간 */}
            <TableRow className={classes.table_row}>
              <TableCell align="center" className={classes.table_row} padding="none">
                <Box fontSize={16} fontWeight="fontWeightMedium">
                  평균 시도율
            </Box>
              </TableCell>

              <TableCell align="center" className={classes.table_row} padding="none">
                <Box fontSize={16} fontWeight="fontWeightMedium">
                  {`${percentStr(sum[3] / (applicants_cnt * q_cnt), 0)}%`}
                </Box>
              </TableCell>
              {
                s_arr.map((item) => (
                  <TableCell align="center" className={classes.table_row} padding="none" key={item[0]}>
                    {`${percentStr(item[3] / applicants_cnt, 0)}%`}
                  </TableCell>
                ))}
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
