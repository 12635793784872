import { BreakStyle } from "devextreme-react/chart";
import { login } from "../util/login";

let _vm_instance: VisangMembersDB = null;

enum UserType {
  Student,
  Visang,
  Teacher,
  Admin
}

export class VisangMembersDB {
  allMembers: string[] = [];
  visang: string[] = [];
  students: string[] = [];
  teachers: string[] = [];
  admins: string[] = [];

  memberInfos: { [key: string]: MemberInfo } = {};

  ready: Promise<string[]>;


  constructor() {
    if (_vm_instance) return _vm_instance;
  }

  static getInstance() {
    if (!_vm_instance) {
      _vm_instance = new VisangMembersDB();
      // _vm_instance.ready = _vm_instance.load();
    }

    return _vm_instance;
  }

  get ids() {
    return this.visang;
  }

  reset = () => {
    this.visang = [];
    this.students = [];
    this.teachers = [];
    this.admins = [];
    this.allMembers = [];

    this.memberInfos = {};
  }

  load = async () => {

    const token = localStorage.getItem("idToken");
    const headers = { authorization: `Bearer ${token}` };

    // 학생 맴버
    const response = await fetch("https://apis.neolab.net/v1/admin/application/member", { headers });
    // console.log("test 1 response.status : ", response.status);

    if (response.ok) {
      this.reset();
      const jsonData = await response.json();
      const elements = jsonData.resultElements;
      for (let i = 0; i < elements.length; i++) {
        const member = elements[i];

        const id = member.id as string;

        let userType = UserType.Teacher;

        if (id.indexOf("visang_student") > -1) {
          userType = UserType.Visang;
        }

        let school = "", grade = "", group = "", number = "";
        if (member.extra?.length > 0) {
          try {
            const organization_info = JSON.parse(member.extra);
            school = organization_info.school;
            grade = organization_info.grade;
            group = organization_info.class;
            number = organization_info.number;
            userType = UserType.Student;
          }
          catch (e) {
            console.log(e);
          }
        }

        const memberInfo = {
          ...member,
          school, grade, group, number, userType,
          extra: ""
        };

        // 멤버 정보를 매핑하고
        this.memberInfos[id] = memberInfo;

        // 전체 멤버를 등록한다.
        this.allMembers.push(member.id);

        switch (userType) {
          case UserType.Visang:
            this.visang.push(member.id);
            break;

          case UserType.Student:
            this.students.push(member.id);
            break;

          case UserType.Teacher:
            this.teachers.push(member.id);
            break;

          default:
            this.admins.push(member.id);
            break;
        }
      }
    } else {
      if (response.status === 401) {
        // unauthorized
        login("/visang");
      }
      console.log(`Error : ${response.status}`);
    }
    console.log(`Visang:${this.visang.length} Students:${this.students.length} Teachers:${this.teachers.length} Admins:${this.admins.length}`);

    return this.visang;
  }

  getName(id) {
    /** @type {MemberInfo} */
    const info = this.memberInfos[id];
    if (info)
      return info.name;

    return "";
  }
}

