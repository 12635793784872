import "../typedef";

//import { loadStrokeData } from "../services/stroke_data_loader";
import { MembersDB } from "../services/members";
import { EvaluationResultDB } from "../services/eveluation_result";
import { ExamSummaryProcessor } from "../services/exam_summary_processor";

export async function fetchInfo(component, tabNum) {
  component.setState({
    fetching: true
  });

  try {
    /** @type {MembersDB} */
    const memberDB = component.state.memberDB;

    /** @type {EvaluationResultDB} */
    const examResult = component.state.examResult;

    /** @type {ExamSummaryProcessor} */
    const examSummaryProcessor = component.state.examSummaryProcessor;

    const students_ids = await memberDB.load();

    if (students_ids.length > 0) {
      await examResult.load(students_ids, tabNum);
      examSummaryProcessor.init(memberDB, examResult);
      // await loadStrokeData(students_ids[0], tabNum);   // kitty
    }
    else {
      component.setState({
        data_load_fail: true // done!
      });
    }

    component.setState({
      fetching: false // done!
    });


  }
  catch (e) {
    console.log("error occurred", e);
    component.setState({
      fetching: false // done!
    });

  }
}