const point3Curve = (p1, p2, p3) => {
  // bezier.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, p3.x, p3.y)
  return (
    "C" +
    p1.x +
    ", " +
    p1.y +
    "," +
    p2.x +
    ", " +
    p2.y +
    "," +
    p3.x +
    "," +
    p3.y
  );
};


export const drawLinePath = (points) => {
  const len = points.length;
  if (len < 1) {
    return;
  }

  let path = "";
  path += "M" + points[0].x + "," + points[0].y;

  for (let i = 1; i < len - 1; i++) {
    const p = points[i];

    path += "L" + p.x + ", " + p.y;
  }
  return path;
};


const drawCurvePath = (points) => {
  if (points.length < 1) {
    return;
  }
  let bezier = "";
  bezier += "M" + points[0].x + "," + points[0].y;

  const n = points.length - 1;
  let controlPoints = [];

  for (let i = 0; i < n; i++) {
    const p = points[i];

    if (controlPoints.length < 5) {
      controlPoints.push(p);
      continue;
    }

    const endPoint = {
      x: (controlPoints[2].x + p.x) / 2,
      y: (controlPoints[2].y + p.y) / 2,
    };

    bezier += point3Curve(controlPoints[1], controlPoints[2], endPoint);
    controlPoints = [endPoint, p];
  }
  const p = points[n];

  while (controlPoints.length < 5) {
    controlPoints.push(p);
  }
  bezier += point3Curve(controlPoints[1], controlPoints[2], p);

  return bezier;
};


const clone = (obj) => {
  if (obj === null || typeof obj !== "object") return obj;

  const copy = obj.constructor();

  for (const attr in obj) {
    if (obj.hasOwnProperty(attr)) {
      copy[attr] = clone(obj[attr]);
    }
  }
  return copy;
};

// Drawing Function
export const drawPath = (point, lineWidth) => {
  if (point.length < 3) {
    return;
  }
  let bezier = "";
  const scaled_pen_thickness = lineWidth;

  // first 1.0f --> lineScale
  let x0, x1, x2, x3, y0, y1, y2, y3, p0, p1, p2, p3;
  let vx01, vy01, vx21, vy21;

  // unit tangent vectors 0->1 and 1<-2
  let norm, n_x0, n_y0, n_x2, n_y2;

  // the normals
  const temp = { x: 0, y: 0 };
  const endPoint = { x: 0, y: 0 };
  const controlPoint1 = { x: 0, y: 0 };
  const controlPoint2 = { x: 0, y: 0 };
  // the first actual point is treated as a midpoint
  x0 = point[0].x + 0.1;
  y0 = point[0].y;
  p0 = point[0].p;
  x1 = point[1].x + 0.1;
  y1 = point[1].y;
  p1 = point[1].p;
  vx01 = x1 - x0;
  vy01 = y1 - y0;
  // instead of dividing tangent/norm by two, we multiply norm by 2
  norm = Math.sqrt(vx01 * vx01 + vy01 * vy01 + 0.0001) * 2.0;
  vx01 = (vx01 / norm) * scaled_pen_thickness * p0;
  vy01 = (vy01 / norm) * scaled_pen_thickness * p0;
  n_x0 = vy01;
  n_y0 = -vx01;
  // Trip back path will be saved.
  const pathPointStore = [];
  temp.x = x0 + n_x0;
  temp.y = y0 + n_y0;

  endPoint.x = x0 + n_x0;
  endPoint.y = y0 + n_y0;
  controlPoint1.x = x0 - n_x0 - vx01;
  controlPoint1.y = y0 - n_y0 - vy01;
  controlPoint2.x = x0 + n_x0 - vx01;
  controlPoint2.y = y0 + n_y0 - vy01;
  //Save last path. I'll be back here....
  const ep = clone(endPoint);
  const cp1 = clone(controlPoint1);
  const cp2 = clone(controlPoint2);
  pathPointStore.push({
    endPoint: ep,
    controlPoint1: cp1,
    controlPoint2: cp2,
  });

  // drawing setting
  bezier += "M" + temp.x + "," + temp.y;
  for (let i = 2; i < point.length - 1; i++) {
    x3 = point[i].x;
    // + 0.1f;
    y3 = point[i].y;
    p3 = point[i].p;
    x2 = (x1 + x3) / 2.0;
    y2 = (y1 + y3) / 2.0;
    p2 = (p1 + p3) / 2.0;
    vx21 = x1 - x2;
    vy21 = y1 - y2;
    norm = Math.sqrt(vx21 * vx21 + vy21 * vy21 + 0.0001) * 2.0;
    vx21 = (vx21 / norm) * scaled_pen_thickness * p2;
    vy21 = (vy21 / norm) * scaled_pen_thickness * p2;
    n_x2 = -vy21;
    n_y2 = vx21;
    if (norm < 0.6) {
      continue;
    }
    // The + boundary of the stroke
    endPoint.x = x2 + n_x2;
    endPoint.y = y2 + n_y2;
    controlPoint1.x = x1 + n_x0;
    controlPoint1.y = y1 + n_y0;
    controlPoint2.x = x1 + n_x2;
    controlPoint2.y = y1 + n_y2;
    bezier += point3Curve(controlPoint1, controlPoint2, endPoint);

    // THe - boundary of the stroke
    endPoint.x = x0 - n_x0;
    endPoint.y = y0 - n_y0;
    controlPoint1.x = x1 - n_x2;
    controlPoint1.y = y1 - n_y2;
    controlPoint2.x = x1 - n_x0;
    controlPoint2.y = y1 - n_y0;
    const ep = clone(endPoint);
    const cp1 = clone(controlPoint1);
    const cp2 = clone(controlPoint2);
    pathPointStore.push({
      endPoint: ep,
      controlPoint1: cp1,
      controlPoint2: cp2,
    });
    x0 = x2;
    y0 = y2;
    p0 = p2;
    x1 = x3;
    y1 = y3;
    p1 = p3;
    vx01 = -vx21;
    vy01 = -vy21;
    n_x0 = n_x2;
    n_y0 = n_y2;
    //
  }
  // the last actual point is treated as a midpoint
  x2 = point[point.length - 1].x;
  // + 0.1f;
  y2 = point[point.length - 1].y;
  p2 = point[point.length - 1].p;
  vx21 = x1 - x2;
  vy21 = y1 - y2;
  norm = Math.sqrt(vx21 * vx21 + vy21 * vy21 + 0.0001) * 2.0;
  vx21 = (vx21 / norm) * scaled_pen_thickness * p2;
  vy21 = (vy21 / norm) * scaled_pen_thickness * p2;
  n_x2 = -vy21;
  n_y2 = vx21;
  endPoint.x = x2 + n_x2;
  endPoint.y = y2 + n_y2;
  controlPoint1.x = x1 + n_x0;
  controlPoint1.y = y1 + n_y0;
  controlPoint2.x = x1 + n_x2;
  controlPoint2.y = y1 + n_y2;
  bezier += point3Curve(controlPoint1, controlPoint2, endPoint);
  endPoint.x = x2 - n_x2;
  endPoint.y = y2 - n_y2;
  controlPoint1.x = x2 + n_x2 - vx21;
  controlPoint1.y = y2 + n_y2 - vy21;
  controlPoint2.x = x2 - n_x2 - vx21;
  controlPoint2.y = y2 - n_y2 - vy21;
  bezier += point3Curve(controlPoint1, controlPoint2, endPoint);

  endPoint.x = x0 - n_x0;
  endPoint.y = y0 - n_y0;
  controlPoint1.x = x1 - n_x2;
  controlPoint1.y = y1 - n_y2;
  controlPoint2.x = x1 - n_x0;
  controlPoint2.y = y1 - n_y0;
  bezier += point3Curve(controlPoint1, controlPoint2, endPoint);

  // Trace back to the starting point
  // console.log("reverse start", pathPointStore)
  while (pathPointStore.length) {
    const repath = pathPointStore.pop();
    bezier += point3Curve(
      repath.controlPoint1,
      repath.controlPoint2,
      repath.endPoint
    );
  }
  return bezier;
};



