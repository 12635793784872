import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Box } from "@material-ui/core";
import {
  data_all_members,
  data_exam,
} from "../../data/students_data";

import "../../typedef"


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  paper: {
    marginLeft: 18,
    marginRight: 18,
    marginBottom: theme.spacing(2),
  },
  container: {
    // height: 800,
    // maxHeight: 800,
  },
  table: {},
  tableHead: {
    fontSize: '1.0rem'
  },

  table_row: {
    "padding-left": "0px",
    "padding-right": "0px",
    "padding-top": "10px",
    "padding-bottom": "10px",
  },

}));


/**
 *
 * @param {Array.<Object>} rows
 *
 * @return {AnswerSummary}
 */
function summarizeTestResult(rows) {

  const time_sumup = {};

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const id = row.id;
    // let name = row.name;
    // let time = row.time;
    // let score = row.score;

    const examData = data_exam[id];
    // let userInfo = examData.userInfo;
    const answer_arr = examData.questionInfoArray;

    for (let j = 0; j < answer_arr.length; j++) {
      const ans_row = answer_arr[j];

      /** @type {string} */
      const q_num = ans_row.number;           // 문제 번호
      const q_numStr = ans_row.numberStr;

      /** @type {string} */
      const q_eval_elem = ans_row.evaluationElement;  // 성취 기준

      /** @type {string} */
      const q_eval_detail = ans_row.detailContent;      // 내용 요소

      /** @type {number} */
      const a_time = ans_row.solveTimeSec;    // 문제 풀이에 걸린 시간

      /** @type {number} */
      // let a_seq_num = ans_row.sequenceNumber; // 전체 문제 풀이 순서 중에서 몇번째로 풀었는지 (시도를 안했으면 0)

      /** @type {boolean} */
      const a_correct = ans_row.isCorrect;    // 문제를 맞혔는지 안맞혔는지

      /** @type {boolean} */
      const a_tried = ans_row.isPlay;     // 문제를 풀려고 했는지 안했는지,

      // console.log(`${name} ${id}, Q-num:${q_num},  A-timesec:${a_time} == SEQ(${a_seq_num}), ans/tried=${a_correct}/${a_tried} `);

      if (!time_sumup.hasOwnProperty(q_num)) {
        time_sumup[q_num] = {
          q_num: [],
          q_eval_detail: [],
          q_eval_elem: "",
          time: 0,
          score: 0,
          tried: 0,
          count: 0,
        }
      }

      if (!time_sumup[q_num].q_num.find(e => e.key === q_num)) {
        time_sumup[q_num].q_num.push({ key:q_num, value:q_numStr });
      }

      if (!time_sumup[q_num].q_eval_detail.find(q => q === q_eval_detail)) {
        time_sumup[q_num].q_eval_detail.push(q_eval_detail);
      }

      time_sumup[q_num].q_eval_elem = q_eval_elem;
      time_sumup[q_num].count++;
      time_sumup[q_num].time += a_time;
      time_sumup[q_num].score += a_correct ? 1 : 0;
      time_sumup[q_num].tried += a_tried ? 1 : 0;
    }
  }
  // console.log(time_sumup);

  // 이제 정렬을 하자
  const sortable = [];
  for (const key in time_sumup) {
    sortable.push([
      time_sumup[key].q_num,            // 0: 문항번호
      "도형 두 걸음",                           // 1: 단계
      time_sumup[key].q_eval_elem,                              // 2: 성취기준
      time_sumup[key].q_eval_detail,    // 3: 내용
      time_sumup[key].score,            // 4: 점수
      time_sumup[key].time,             // 5: 시간
      time_sumup[key].tried,            // 6: 시도 회수
      time_sumup[key].count,            // 7: 해당 문항수
      // time_sumup[key].q_numStr,            // 8: 문항번호 스트링

    ]);
  }

  sortable.sort(function (a, b) {
    return a[0].key - b[0].key;   // key로 정렬
  });

  // let total_time = 0;
  for (let k = 0; k < sortable.length; k++) {
    const ie = sortable[k];

    // ie[0].sort((a, b) => a - b);     // q_num
    // ie[3].sort((a, b) => a - b);     // q_eval_detail


    // total_time += sortable[k][1];
  }

  // console.log(sortable);

  const retObj = {};
  sortable.forEach((item, index) => {
    retObj[index] = {
      q_num: item[0],
      level: item[1],
      q_elem: item[2],
      q_detail: item[3],
      score: item[4],
      time: item[5],
      tried: item[6],
      count: item[7],
      q_numStr: item[8],
    }
  });

  return sortable;
}

function percentStr(num, under_digit = 1) {
  const div = Math.pow(10, under_digit);
  const ret = Math.round(num * div * 100) / div;
  return ret;
}

function unifiedStr(num, under_digit = 1) {
  const div = Math.pow(10, under_digit);
  const ret = Math.round(num * div) / div;
  return ret;
}


const headCells = [
  { id: "q_num", disablePadding: true, label: "문항", align: "center" },
  { id: "level", disablePadding: true, label: "단계", align: "center" },
  { id: "eval_elem", disablePadding: true, label: "성취기준", align: "left" },
  { id: "eval_detail", disablePadding: true, label: "내용", align: "center" },
  { id: "score", disablePadding: true, label: "성취율", align: "center" },
  { id: "time", disablePadding: true, label: "풀이시간", align: "center" },
];

export default function QuestionsSummaryTable(props) {
    const { tabNum } = props;
  const classes = useStyles();
  // const [raw_data, setRows] = React.useState(data_all_members);
  const [raw_data] = React.useState(data_all_members);
  const exam_result = raw_data.filter(row => (!row.absent));

  const applicants_cnt = exam_result.length;

  /** @type {AnswerSummary} */
  const s_arr = summarizeTestResult(exam_result);

  return (
    <div>
      <Box display="flex" flexDirection="row"  fontSize={18} p={1} fontWeight="fontWeightBold" >
        문항별 현황
      </Box>

      <Paper className={classes.paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell, index) => (
                <TableCell key={`tab-${tabNum}-question-${index}`} align={headCell.align} padding="none" className={classes.table_row}>
                  <Box fontSize={17} fontWeight="fontWeightBold">
                    {headCell.label}
                  </Box>
                </TableCell>
              ))}
            </TableRow>

            {/* <TableRow className={classes.table_row}>
          <TableCell align="center" className={classes.table_row} padding="none" className={classes.table_row}>
            <Box fontSize={17} fontWeight="fontWeightBold">
              문항번호
            </Box>
          </TableCell>

          {
            s_arr.map((item) => (
              <TableCell key={item[0]} align="center" className={classes.table_row} padding="none" className={classes.table_row}>
                <Box fontSize={17} fontWeight="fontWeightBold">
                  {item[0]}
                </Box>
              </TableCell>
            ))}

        </TableRow> */}
          </TableHead>

          <TableBody>

            {
              s_arr.map((item, index) => (
                <TableRow className={classes.table_row} key={`qsum-cell-${index}`}>
                  {/* 문항번호 */}
                  <TableCell align="center" className={classes.table_row} padding="none" >
                    {item[0].map((e, index) => {
                      return (
                        <Box fontWeight="fontWeightLight" key={`question-number-${index}`}>
                          {e.value}{" "}
                        </Box>
                      );
                    })}
                  </TableCell>


                  {/* 단계 */}
                  <TableCell align="center" className={classes.table_row} padding="none" style={{ whiteSpace: "nowrap" }}>
                    {item[1]}
                  </TableCell>

                  {/* 성취기준 */}
                  <TableCell align="left" className={classes.table_row} padding="none" style={{ width: "300px" }}>

                    <Box fontSize={12} fontWeight="fontWeightLight">
                      {item[2]}
                    </Box>
                  </TableCell>

                  {/* 내용 */}
                  <TableCell align="center" className={classes.table_row} padding="none" style={{ wordWrap: "break-word" }}>
                    {item[3].map((q_detail, index) => (
                      <Box fontWeight="fontWeightLight" key={`qsum-note-${index}`}>
                        {q_detail}
                      </Box>
                    ))}
                    {/* {`${unifiedStr(item[1] / applicants_cnt)}초`} */}
                  </TableCell>

                  {/* 성취율 */}
                  <TableCell align="center" className={classes.table_row} padding="none" style={{ whiteSpace: "nowrap" }}>
                    <Box fontSize={17} fontWeight="fontWeightBold">
                      {`${percentStr(item[4] / item[0].length / applicants_cnt, 0)}%`}
                    </Box>
                    {/* {`${unifiedStr(item[1] / applicants_cnt)}초`} */}
                  </TableCell>

                  {/* 풀이시간 */}
                  <TableCell align="center" className={classes.table_row} padding="none" style={{ whiteSpace: "nowrap" }}>
                    <Box fontSize={16} fontWeight="fontWeightLight">
                      {`${unifiedStr(item[5] / item[0].length / applicants_cnt, 1)}초`}
                    </Box>
                    {/* {`${unifiedStr(item[1] / applicants_cnt)}초`} */}
                  </TableCell>

                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </div >
  );
}
