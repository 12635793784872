import React, { Component } from 'react';
import Colors from '../styles/colors.scss';
import Box from '@material-ui/core/Box';
import { ShowUIProgressBackdrop, HideUIProgressBackdrop } from '../store/reducers/ui';
import { didLogin } from '../store/reducers/authorization';
import { TextField, Button } from '@material-ui/core';
import cookie from 'react-cookies';
// import logo from "../assets/logo_sample.png";

const styles = {
  loginPage: {
    backgroundColor: Colors.gray10,
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  loginInputWrapper: {
    width: '300px',
    padding: '20px',
    border: '1px solid var(--gray3)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px'
  },
  logoImage: {
    width: '150px',
    objectFit: 'contain',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  }
};

/**
 * 로그인 페이지
 */
class LoginPage extends Component {
  // constructor(props) {
  //   super(props);
  // };

  componentDidMount() {
    console.log(">> componentWillMount");

    // 로컬 스토리지에 토큰이 있으면
    const token = localStorage.getItem('idToken')
    console.log("token : ", token);
    if (token) {
      // 구글계정 연동 시도
      didLogin(token);
    } else {
      // 쿠키에 토콘이 있으면
      const cookieAuth = cookie.load("token");
      console.log(cookieAuth)

      if (cookieAuth && cookieAuth !== "") {
        didLogin(cookieAuth);
        // 로그인 후 쿠키에서 제거
        cookie.remove("token");
      }
    }
  }


  componentWillUnmount() {
    console.log(">> componentWillUnmount");
  }

  render = () => {
    // 로컬
    // const redirectUri = "http://127.0.0.1:3000/callback.html";
    // 배포
    // const redirectUri = "https://stat-keris.web.app/callback.html";
    const baseUrl = "https://apis.neolab.net";
    const redirectUri = window.location.origin + "/callback.html";
    const url = baseUrl + "/login_social/google_kerispjt_web?redirect_uri=" + redirectUri
    console.log( `Redirect URL: ${redirectUri} ==> ${url}`);

    return (
      <div style={styles.loginPage}>
        <div style={styles.loginInputWrapper}>

          <Box fontSize={17} fontWeight="fontWeightBold">버튼을 눌러 다음으로 진행해 주세요</Box>
          {/* <TextField required fullWidth id="email-input" label="ID" size="small" variant="outlined" />
          <TextField style={{ marginTop: '10px' }} required fullWidth id="password-input" label="Password" type="password" size="small" variant="outlined" /> */}

          <Button style={{ marginTop: '15px' }} variant="contained" color="primary" fullWidth
            onClick={() => {
              ShowUIProgressBackdrop();

              // 리다이렉트 페이지로 이동한다.
              window.location.replace(url);
              setTimeout(() => { HideUIProgressBackdrop(); }, 3000);
            }}>
            LOGIN
            </Button>
        </div>
      </div>
    );
  };
}

export default LoginPage;
