import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Paper, Box } from "@material-ui/core";
// import { Paper, Typography, Divider, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// import { CallReceived } from "@material-ui/icons";
import { getTabName, EXAM_FILE_RESOURCES, VISANG_RESOURCES } from "../data/Constant";
import PropTypes from "prop-types";
import { getReplayBgUrl } from "./KerisReplay";


const styles = (theme) => ({
  paper: {
    width: 142,
    height: 217,
    border: "1px ridge",
    "&:hover": {
      border: "1px ridge #1794b6",
    },
  },
  currentPage: {
    color: theme.palette.primary.main,
  },
});


interface Props {
  tabNum: number,

  strokeChunks: NeoStroke[][],

  isVisang?: boolean,
  visangCourse?: number,
  visangLesson?: number,

  classes?,
  pageClickHandle,

  pageNo?: number,
}

function ItemPageView(props: Props) {
  const { pageNo, tabNum, isVisang, visangLesson, visangCourse } = props;

  const filePath = getReplayBgUrl({ pageNo, tabNum, isVisang, visangLesson, visangCourse });
  return (
    <div>
      <div style={{position:"absolute", left:0, top:0}}>
      <img src={filePath} alt="" width="140" height="215" />
      </div>
      <div style={{position:"absolute", left:0, top:0}}>
        <h2 style={{color: "white", textShadow:"2px 2px 2px gray" }}> {pageNo}</h2>
      </div>
    </div>
  );
}

const range1toN = (N) => Array.from({ length: N }, (v, k) => k + 1);

function KerisRight(props: Props) {
  const pageClickHandle = (page) => {
    props.pageClickHandle(page);
  };

  const { classes, tabNum } = props;

  let pageNums = [];
  if (!props.isVisang) {
    const tab_info = getTabName(tabNum);
    const totalPages = EXAM_FILE_RESOURCES[tab_info].numPages;

    pageNums = range1toN(totalPages);
  }
  else {
    const chunks = props.strokeChunks;
    chunks.forEach(chunk => {
      const stroke = chunk[0];
      if (pageNums.indexOf(stroke.pageNum) < 0)
        pageNums.push(stroke.pageNum);
    })
  }

  const { pageNo: dummy, ...rest } = props;

  return (
    <Box
      bgcolor="background.paper"
      style={{ height: "calc(100vh - 64px)", overflow: "auto" }}
    >
      {/* <Divider style={{ marginTop: 20 }} /> */}

      <List>
        {pageNums.map((pageNo) => (
          <ListItem key={pageNo} onClick={() => pageClickHandle(pageNo)}>
            <div style={{ margin: "auto" }}>
              <Paper elevation={0} square={true} className={classes.paper}>
                <ItemPageView {...rest} pageNo={pageNo} ></ItemPageView>
              </Paper>
            </div>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

// KerisRight.propTypes = propTypes;
// KerisRight.defaultProps = defaultProps;

export default withStyles(styles)(KerisRight);
