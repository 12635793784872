import React from "react";
import qs from "qs";
import { didLogin } from "../../store/reducers/authorization";


export default function LoginCallback(props) {
  const { params } = props.match;

  const pathName: string = props.location.pathname;

  // const slashDriven = pathName.indexOf("callback/") > -1;
  // if (slashDriven) {
  //   const re = /(.+)callback\/(.+)&(.+)$/;
  //   const ss = re.exec(pathName);   // "txt"
  //   const callbackUri = "/" + ss[2];

  //   const query = qs.parse(pathName, {
  //     ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
  //   });

  // }


  const query = qs.parse(props.location.search, {
    ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
  });

  const redirect = query.redirect;
  const redirectUrl = window.location.origin + redirect;

  let code = "";
  if (Object.prototype.hasOwnProperty.call(query, "?code")) {
    code = query["?code"];
  }
  else {
    code = query["code"];
  }

  const baseUrl = "https://apis.neolab.net";

  // redirect uri (소셜 인증을 마친후 받은 authorize code로 access code를 얻어오는 코드가 구현된 페이지)
  // 잉크 서버에 미리 등록 해야 함
  // const redirectUri = "http://127.0.0.1:8093/upload/inkserver/callback.html";

  // 미리 지정한 oauth credencial client id
  const clientId = "google_kerispjt_web";

  // 미리 지정한 oauth credencial client secret
  const clientSecret = "chy-secret";

  requestAccessToken(decodeURIComponent(code), redirectUrl);


  // // 주소 창에 있는 parameter를 취득한다.
  // function getParameter(name) {
  //   var list = location.search.substring(1).split('&');
  //   for (var i = 0; i < list.length; i++) {
  //     var data = list[i].split('=');
  //     if (data.length === 2) {
  //       if (data[0] === name) {
  //         return data[1];
  //       }
  //     }
  //   }
  //   return null;
  // }

  // 잉크 서버에 authorization_code 방식으로 인증을 요청한다. (access token, refresh token을 받음)
  function requestAccessToken(code: string, redirectUrl: string) {
    const redirectUri = window.location.origin + `/visang/callback?redirect=/visang`;

    var url = baseUrl + "/oauth/token";
    var formData = "grant_type=authorization_code&client_id=" + clientId + "&client_secret=" + clientSecret + "&code=" + code;
    formData = formData + "&redirect_uri=" + redirectUri;
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onload = function () {
      var token = JSON.parse(xhr.responseText);
      if (xhr.readyState === 4 && xhr.status === 200) {
        // 부모창에 전달
        console.log("access_token : ", token.access_token);
        document.cookie = 'token=' + token.access_token;
        didLogin(token.access_token);
      } else {
        console.error(token);
        document.cookie = 'token=' + '';
      }
      window.close();

      // 로컬
      // window.location.replace("http://127.0.0.1:3000/login");
      // 배포
      // window.location.replace("https://stat-keris.web.app");


      window.location.replace(redirectUrl);
    }
    xhr.send(formData);
  }

  return (<div></div>);
}


