import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import RestrictedRoute from "./RestrictedRoute";
import LoginPage from "../pages/LoginPage";
import MainPage from "../pages/MainPage";
import DashboardPage from "../pages/DashboardPage";
import StudentsPage from "../pages/StudentsPage";
import LoginCallback from "../visang/component/LoginCallback";
import { withRouter } from "react-router";
import VisangMain from "../visang/pages/VisangMain";

type Props = {
  authorized: boolean,
}

class AppRouter extends Component<Props> {
  render() {
    // const baseUrl = "https://apis.neolab.net";
    // const redirectUri = window.location.origin + "/callback.html";
    // const url =
    //   baseUrl + "/login_social/google_kerispjt_web?redirect_uri=" + redirectUri;
    // console.log(`Redirect URL: ${redirectUri}`);

    console.log("AppRouter call");
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/visang/callback" component={LoginCallback} />
          <Route path="/visang" component={VisangMain} />

          <Route path="/" exact={true}>
            {this.props.authorized ? (
              <Redirect to="/main" />
            ) : (
                <Redirect to="/login" />
              )}
          </Route>
          <PublicRoute path="/login" component={LoginPage} />
          <RestrictedRoute path="/main" component={MainPage} />
          <Redirect from="*" to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  const ret = {
    authorized: !!state.auth.authToken
  };
  return ret;
};

export default connect(mapStateToProps)(AppRouter);
