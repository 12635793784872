import { render } from "react-dom";
import React from "react";
import App from "./App";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme/theme";
import VisangMobx from "./visang/store/VisangMobx";
import { Provider as MobxProvider } from "mobx-react";

export const visangStore = new VisangMobx();

render(
  <MobxProvider visangStore={visangStore}>
    <MuiThemeProvider theme={theme} >
      <App />
    </MuiThemeProvider>
  </MobxProvider>,
  document.getElementById("app")
);
