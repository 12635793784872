import { mdiBellSleep } from "@mdi/js";
import { store } from "../../App";
import { EvaluationResultDB } from "../../services/eveluation_result";
import { ExamSummaryProcessor } from "../../services/exam_summary_processor";
import { MembersDB } from "../../services/members";

//[Define Action Types
const ActionGroup = "TOP_TAB";

export const TopTabActionType = Object.freeze({
  SET: `${ActionGroup}.SET`,
  GET: `${ActionGroup}.GET`,
});
//]

const initialState = {
  tabNum: 0,
};

const sleep = (ms) => {
  new Promise(resolve => setTimeout(resolve, ms));
}

const fetchData = async (tabNum) => {
  // const memberDB = new MembersDB ();
  // const examResult = new EvaluationResultDB ();
  // const examSummaryProcessor = new ExamSummaryProcessor ();

  // try {
  //   const students_ids = await memberDB.load();

  //   if (students_ids.length > 0) {
  //     await examResult.load(students_ids, tabNum);
  //     examSummaryProcessor.init(memberDB, examResult);
  //     // await loadStrokeData(students_ids[0], tabNum);   // kitty
  //   }
  //   else {
  //     self.setState({
  //       data_load_fail: true // done!
  //     });
  //   }

  //   self.setState({
  //     fetching: false // done!
  //   });

  // }
  // catch (e) {
  //   console.log("error occurred", e);
  // }
}


export const setTabNumber = async (num) => {
  await fetchData();
  store.dispatch({
    type: TopTabActionType.SET,
    value: num,
  });
};

//[Reducer
export default (state = initialState, action) => {
  // console.log(action);

  switch (action.type) {
    case TopTabActionType.SET: {
      return {
        ...state,
        tabNum: action.value,
      };
    }

    default: {
      return state;
    }
  }
};
//]
