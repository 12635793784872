import { numCourses, numLesseons, courses, lessons } from "../constants/VisangConstants";

export function makeVisangStrokeFileUrl(id: string, course: number, lesson: number) {
  if (course >= numCourses) {
    throw new Error("course number is over than 3");
  }

  if (lesson >= numLesseons) {
    throw new Error("lesson number is over than 3");
  }

  // const stroke_file_base = `visang_${courses[course]}_${lessons[lesson]}_1_stroke.json`;
  const stroke_file_base = `visang_${courses[course]}_${lessons[lesson]}_1_ stroke.json`;
  // const stroke_file_base = "visang_Starter_Lesson1_1_ stroke.json";
  const url = `https://apis.neolab.net/v1/admin/storage/${id}/file/${stroke_file_base}`;

  return encodeURI(url);
}


export function getJsonData(url: string) {
  const token = localStorage.getItem("idToken");
  const headers = {
    authorization: `Bearer ${token}`,
  };

  return new Promise((resolve) => {
    fetch(url, { headers }).then(response => {
      if (response.ok) {
        response.json().then(jsonData => {
          resolve(jsonData);
        }).catch(e => {
          resolve(undefined);
        })
      } else {
        resolve(undefined);
      }
    }).catch(e => {
      resolve(undefined);
    })
  }) as Promise<any>;
}



export function getVisangLessonData(url: string) {

  const token = localStorage.getItem("idToken");
  const headers = {
    authorization: `Bearer ${token}`,
  };

  return new Promise(async (resolve) => {
    // const url = `https://apis.neolab.net/v1/admin/storage/visang_student_13/file/visang_Starter_Lesson1_1_ stroke.json`;
    let response = await fetch(
      // `https://apis.neolab.net/v1/admin/storage/${userId}/file/${STROKE_FILE_NAME_FIRST}`,
      url,
      { headers }
    );


    if (response.ok) {
      let jsonData = await response.json();
      console.log(jsonData);

      console.log("jsonData : ", jsonData);
      console.log(jsonData.requestUri);

      // stroke json
      const stroke_response = await fetch(jsonData.requestUri);
      if (stroke_response.ok) {
        /** @type {StrokeData} */
        const stroke_json = await stroke_response.json();
        resolve(stroke_json);
      }

      resolve(undefined);
    } else {
      resolve(undefined);
    }
  }) as Promise<string>;
}

