import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Box } from "@material-ui/core";
import {
  data_all_members,
} from "../../data/students_data";

import "../../typedef";
import { getTabName, EXAM_FILE_RESOURCES } from "../../data/Constant";

/**
 * 
 * @param {string} exam_date - date string
 * @param {string} exam_type - 평가 종류 (ex, "기초학력평가")
 * @param {string} exam_name - 시험 이름 (ex, "2020년 1차")
 * @param {string} exam_subject - 과목 (ex, "수학")
 * 
 * @return {ExamSummaryInfo}
 */
function createData(exam_date, exam_type, exam_name, exam_subject) {
  /** @type {number} */
  const applicants_cnt = 0;

  /** @type {number} */
  // let students_cnt = 0;

  /** @type {number} */
  const absent_cnt = 0;

  /** @type {number} */
  const applicant_ratio = 0;

  // applicants_cnt = DataSet.getApplicantCnt(exam_type, exam_name, exam_subject);
  // students_cnt = DataSet.getStudentCnt();
  // absent_cnt = students_cnt - applicants_cnt;
  // applicant_ratio = applicants_cnt / students_cnt;

  return { exam_date, exam_type, exam_name, exam_subject, applicants_cnt, absent_cnt, applicant_ratio };
}


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  paper: {
    marginLeft: 18,
    marginRight: 18,
    marginBottom: theme.spacing(2),
  },
  container: {
    // height: 800,
    // maxHeight: 800,
  },
  table: {},
  tableHead: {
    fontSize: '1.0rem'
  },

  table_row: {
    "padding-left": "0px",
    "padding-right": "0px",
    "padding-top": "10px",
    "padding-bottom": "10px",
  },

}));


export default function ExamApplicantTable(props) {
  const classes = useStyles();
  const [all_st_rows] = React.useState(data_all_members);
  const a_rows = all_st_rows.filter(row => (!row.absent));

  const st_num = all_st_rows.length;
  const applicants_cnt = a_rows.length;
  const absent_cnt = st_num - applicants_cnt;
  const applicant_ratio = applicants_cnt / st_num;



  // const info_rows = [
  //   createData("2020-08-06", "기초학력평가", "2020년 1차", "수학"),
  // ];
  const tab_info = getTabName(props.tabNum);
  const exam_info = EXAM_FILE_RESOURCES[tab_info].info;
  const info_row = createData(exam_info.date, exam_info.type, exam_info.name, exam_info.subject);
  const info_rows = [info_row];


  const headCells = [
    { id: "exam_date", disablePadding: true, label: "날짜", align: "center" },
    { id: "exam_type", disablePadding: true, label: "평가 종류", align: "center" },
    { id: "exam_name", disablePadding: true, label: "시험 이름", align: "center" },
    { id: "exam_subject", disablePadding: true, label: "과목", align: "center" },
    { id: "applicants_cnt", disablePadding: true, label: "응시자수", align: "center" },
    // { id: "absent_cnt", disablePadding: true, label: "결시자수", align: "center" },
    { id: "student_cnt", disablePadding: true, label: "학급인원", align: "center" },
    { id: "applicant_ratio", disablePadding: true, label: "응시율", align: "center" },
  ];


  return (
    <div>
      <Box display="flex" flexDirection="row"  fontSize={18} p={1} fontWeight="fontWeightBold" >
        응시현황
      </Box>
      <Paper className={classes.paper}>

        <Table>
          <TableHead>
            <TableRow className={classes.table_row}>
              {headCells.map((headCell, index) => (
                <TableCell key={`index-${index}`} align={headCell.align} className={classes.table_row} padding="none">
                  <Box fontSize={17} fontWeight="fontWeightBold">
                    {headCell.label}
                  </Box>
                </TableCell>
              ))}

            </TableRow>
          </TableHead>

          <TableBody>
            {info_rows.map((row) => (
              <TableRow key={row.exam_date}>
                <TableCell align="center">{row.exam_date}</TableCell>
                <TableCell align="center">{row.exam_type}</TableCell>
                <TableCell align="center">{row.exam_name}</TableCell>
                <TableCell align="center">{row.exam_subject}</TableCell>
                <TableCell align="center">{applicants_cnt}</TableCell>
                {/* <TableCell align="center">{absent_cnt}</TableCell> */}
                <TableCell align="center">{applicants_cnt + absent_cnt}</TableCell>
                <TableCell align="center">{`${Math.round(applicant_ratio * 1000) / 10}%`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
