export function login(callbackUrl: string) {
  // unauthorized
  // const baseUrl = "https://apis.neolab.net";
  const redirectUri = window.location.origin + `/visang/callback?redirect=${callbackUrl}&`;
  // const url = baseUrl + "/login_social/google_kerispjt_web?redirect_uri=" + redirectUri;
  // window.location.href = url;

  const apiServerUrl = "https://apis.neolab.net";
  const authUri = "/oauth/authorize?response_type=code&client_id=google_kerispjt_web";
  const redirectParameter = `&redirect_uri=${redirectUri}`;
  const rightString = "&scope=application.member.admin.read%20storage.read%20profile.read%20profile.write%20userdata.admin.read%20storage.write%20storage.admin.read%20storage.admin.write";
  const oauthUrl = apiServerUrl + authUri + rightString + redirectParameter;
  window.location.href = oauthUrl;

  // let loginUrl = "https://apis.neolab.net/oauth/authorize?response_type=code&client_id=google_kerispjt_web&redirect_uri=" + redirectUri;
  // loginUrl = loginUrl + "scope=application.member.admin.read%20storage.read%20profile.read%20profile.write%20userdata.admin.read%20storage.write%20storage.admin.read%20storage.admin.write";

  // window.location.href = loginUrl;
}