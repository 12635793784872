import React from "react";
import { Box, Button, IconButton, makeStyles, TableCell, TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Link } from "react-router-dom";
import { sprintf } from "sprintf-js";
import { data_selected_user } from "../../data/students_data";
import VisangLesson from "../data/VisangLesson";

function getDurationStr(ms: number) {
  let t = Math.floor(ms / 1000);
  const milli = ms - t * 1000;

  const days = Math.floor(t / (60 * 60 * 24));
  t -= days * (60 * 60 * 24);

  const hours = Math.floor(t / (60 * 60));
  t -= hours * (60 * 60);

  const minutes = Math.floor(t / 60);
  t -= minutes * (60);

  const seconds = t;

  let ret = sprintf("%2d초", seconds);
  if (minutes > 0 || hours > 0 || days > 0)
    ret = sprintf("%2d분 ", minutes) + ret;

  if (hours > 0 || days > 0)
    ret = sprintf("%2d시간 ", hours) + ret;
  if (days > 0)
    ret = sprintf("%2d일  ", days) + ret;

  return ret;
}

function percentStr(f: number, fraction: number) {
  const multiple = Math.pow(10, fraction);
  const format = `%.${fraction}f%%`;
  return sprintf(format, Math.round(f * 100 * multiple) / multiple);
}

function speedStr(f: number, fraction: number) {
  const format = `%.${fraction}f%%`;
  const value = Math.round(f * 10) / 10;
  const ret = sprintf("%.1fcm/s", value);

  return ret;
}



const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  paper: {
    marginLeft: 18,
    marginRight: 18,
    marginBottom: theme.spacing(2),
  },
  container: {
    // height: 800,
    // maxHeight: 800,
  },
  table: {},
  tableHead: {
    fontSize: '1.0rem'
  },

  table_row: {
    "padding-top": "10px",
    "padding-bottom": "10px",
  },

}));


interface Props {
  row,
  processor: VisangLesson,

  course: number,
  lesson: number,

  match?
  history?
}

export default function VisangTableRow(props: Props) {
  const classes = useStyles();

  const { row } = props;
  const [open, setOpen] = React.useState(false);
  // const classes = useStyles();

  // const isItemSelected = isSelected(row.num);
  // const labelId = `enhanced-table-checkbox-${index}`;
  const absent = row.absent;

  // const inner_classes = innerTableStyles();

  const onReplayClicked = (event, id) => {
    console.log("User Id : ", id);
    // 더 좋은 방법 확인

    if (props.processor) {
      const url = props.processor.data.strokeUrls[id];
      data_selected_user.pop();
      data_selected_user.push({ id, url, course: props.course, lesson: props.lesson });
    }

    // const url = `${window.location.origin}/visang/replay?course=${course}&lesson=${lesson}`;
    // props.history.push(url);

    console.log("data_selected_user[userId]: ", data_selected_user[id]);
  }

  const openFunc = absent ? () => { } : setOpen;
  const { course, lesson } = props;

  return (
    <React.Fragment>
      <TableRow className={classes.root} hover tabIndex={-1} key={row.num}
        onClick={() => openFunc(!open)}>
        {/* 열림버튼 */}
        {/* <TableCell component="th" scope="row" width="5%" padding="none" className={classes.table_row} key={row.id}>
          <IconButton aria-label="expand row" size="small" key={row.id}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> */}

        {/* 이름 */}
        <TableCell colSpan={2} align="center" style={{
          whiteSpace: "nowrap", // wordWrap: "break-word"
        }} padding="none" className={classes.table_row}>
          <Box fontSize={17} fontWeight="fontWeightBold">
            {row.number}
          </Box>
        </TableCell>

        {/* name */}
        <TableCell align="center" padding="none" className={classes.table_row}>
          <Box fontSize={12} fontWeight="fontWeightLight">
            {row.name}
          </Box>
        </TableCell>


        {/* ID */}
        {/* <TableCell align="right" padding="none" className={classes.table_row}>
          <Box fontSize={12} fontWeight="fontWeightLight">
            {row.id}
          </Box>
        </TableCell> */}

        {/* 풀이시간 */}
        <TableCell align="right" padding="none" className={classes.table_row}>
          {getDurationStr(row.delta_time)}
        </TableCell>

        {/* 필기 시간 */}
        <TableCell align="right" padding="none" className={classes.table_row}>
          {getDurationStr(row.writing_time)}
        </TableCell>

        {/* 필기 효율 */}
        <TableCell align="right" padding="none" className={classes.table_row}>
          {Math.round(row.writing_time / row.delta_time * 1000) / 10}%
        </TableCell>


        {/* 필압 */}
        {/* <TableCell align="right" padding="none" className={classes.table_row}>
          <Box fontSize={12} fontWeight="fontWeightLight">
            {percentStr(row.min_force, 0)}
            /<b>{percentStr(row.avr_force, 0)}</b>
          /{percentStr(row.max_force, 0)}
          </Box>
        </TableCell> */}

        {/* 속도 */}
        <TableCell align="right" padding="none" className={classes.table_row}>
          <Box fontSize={12} fontWeight="fontWeightLight">
            {speedStr(row.avr_speed, 0)}
          </Box>
        </TableCell>

        {/* 레슨 */}
        <TableCell align="right" padding="none" className={classes.table_row}>
          <Box fontSize={12} fontWeight="fontWeightLight">
            {row.lesson}
          </Box>
        </TableCell>


        {/* 필기재생 버튼 */}
        <TableCell align="center" padding="none" className={classes.table_row}>
          {row.absent ? null : (
            <Link to={`/visang/replay?course=${course}&lesson=${lesson}&id=${row.id}`}>
              <Button variant="outlined" color="primary" onClick={(event) => onReplayClicked(event, row.id)}>
                <Box fontSize={16} fontWeight="fontWeightBold" >재생</Box>
              </Button>
            </Link>
          )}
        </TableCell>

      </TableRow>
    </React.Fragment>
  );
}
