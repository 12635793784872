import "../typedef";

import { EvaluationResultDB } from "./eveluation_result";
import { MembersDB } from "./members";

import {
  data_all_members,
  data_members,
  data_exam,
  data_all_members_list
} from "../data/students_data";


export class ExamSummaryProcessor {
  constructor() {
  }

  /**
   * 
   * @param {MembersDB} members_db 
   * @param {EvaluationResultDB} eval_result_db 
   */
  init(members_db, eval_result_db) {
    this.makeExamResultData(members_db, eval_result_db);

    // clear
    data_all_members_list.splice(0, data_all_members_list.length)
    const all_member = members_db.dataAllMembersList;
    all_member.forEach((item) => {
      data_all_members_list.push(item);
    });
    // console.log(data_all_members_list.length);
  }

  /**
   * 
   * @param {Array.<QuestionInfo>} questionInfoArray 
   * @return {number}
   */
  getTotalSolveTime(questionInfoArray) {
    let total_time = 0;
    for (let i = 0; i < questionInfoArray.length; i++) {
      total_time += questionInfoArray[i].solveTimeSec;
    }
    return total_time;
  }

  /**
   * 
   * @param {MembersDB} members_db 
   * @param {EvaluationResultDB} result_db 
   */
  makeExamResultData(members_db, result_db) {
    const students = members_db.studentIDs;
    // clear
    data_all_members.splice(0, data_all_members.length)
    data_members.splice(0, data_members.length)

    students.forEach((id) => {
      /** */
      const examData = result_db.getExamData(id);

      let time = 0;
      const name = members_db.getName(id);
      let score = 0;
      let absent = true;

      if (examData) {
        time = examData.solveTime;
        score = examData.scoreInfo.totalScore;
        absent = false;
      }

      data_all_members.push({ id, name, time, score, absent });
      data_exam[id] = examData;

      // 아래 데이터는 아무데도 안쓰는 것 같다.
      data_members.push({ id, name, time, score, absent });
    });


    // console.log(data_all_members.length);
    // console.log(data_members.length);
  }
}
