import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AppBar, Badge, Box, CssBaseline, IconButton, ListItemIcon, Menu, MenuItem, Table, TableCell, TableHead, TableRow, Toolbar, Typography } from "@material-ui/core";
import { getVisangMembers, refreshVisangData, setVisangCourse } from "../store/VisangRedux";
import { RootState } from "../../store/configureStore";
import { CreateCSSProperties, CSSProperties, PropsFunc, ThemeProvider, withStyles } from "@material-ui/styles";
import VisangLesson from "../data/VisangLesson";
import { store } from "../../App";
import { VisangAppContext } from "../context/VisangAppContext";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Icon from "@mdi/react";
import { mdiLogout } from "@mdi/js";
import { logout } from "../../store/reducers/authorization";
import VisangDrawerMenu from "../component/VisangDrawerMenu";
import { VisangDrawerRoutePath } from "../routers/VisangDrawerRoutePath";
import { HideUIProgressBackdrop, ShowUIProgressBackdrop } from "../../store/reducers/ui";
import { visangTheme } from "../theme/VisangTheme";

const styles = (theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuTitle: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,

  root: {
    width: '95%',
    height: '100%',
  },
  paper: {
    marginLeft: 18,
    marginRight: 18,
    // marginBottom: theme.spacing(2),
  },
  container: {
    // height: 800,
    // maxHeight: 800,
  },
  table: {},
  tableHead: {
    fontSize: '1.0rem'
  },

  table_row: {
    "padding-left": "0px",
    "padding-right": "0px",
    "padding-top": "10px",
    "padding-bottom": "10px",
  },
});

interface Props {
  isLoading: boolean,
  tabNum: number,
  classes: any,
  authorized: boolean,

  members: string[],

  course: number,
  lesson: number,

  history?,
  match?,
}

interface State {
  "user-menu-anchor",
  pageTitle: string,
  subPageTitle: string,
  userMenuItems: { title: string, icon: string, menuId: string }[],
  isLoading: boolean,
}

class VisangMain extends React.Component<Props, State> {
  static defaultProps: Props = {
    isLoading: false,
    tabNum: 0,
    classes: undefined,
    authorized: false,

    members: [],
    course: 0,
    lesson: 0,

  }

  state = {
    "user-menu-anchor": null,
    pageTitle: null,
    subPageTitle: null,
    userMenuItems: [
      {
        title: "Logout",
        icon: mdiLogout,
        menuId: "logout",
      },
    ],
    isLoading: false,
  };

  constructor(props = VisangMain.defaultProps) {
    super(props);
    console.log("StudentsPage isLoading : ", props.isLoading);
  }

  componentDidMount() {
    console.log(">> componentDidMount");

    const state = store.getState();
    setVisangCourse(state.visang.course);

  }

  shouldComponentUpdate(nextProps: Props, nextState) {
    if (this.props.members !== nextProps.members) {
      console.log("members update");
    }
    return true;
  }

  onClickUserMenu = (event, menuItem) => {
    switch (menuItem.menuId) {
      case "logout": {
        logout();
        this.props.history.push("/visang");
        break;
      }
      default: {
        break;
      }
    }

    this.setState({
      "user-menu-anchor": null,
    });
  };


  onDrawerOpenStatus = (isOpen) => { };


  onClickUserMenuButton = (event) => {
    this.setState({
      "user-menu-anchor": event.currentTarget,
    });
  };

  onUserMenuClose = () => {
    this.setState({
      "user-menu-anchor": null,
    });
  };

  render = () => {
    const { classes, tabNum } = this.props;
    // console.log(`TAB VALUE = ${tabNum}`);
    const visangDrawerItems = VisangDrawerRoutePath.getInstance();
    const drawer = visangDrawerItems;

    return (
      <div style={{ display: "flex" }}>
        <CssBaseline />
        <ThemeProvider theme={visangTheme}>

        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar variant="regular">
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" ></IconButton>
            <VisangAppContext.Consumer>
              {(ctxValue) => (
                <Typography variant="h6" className={classes.menuTitle}>
                  {ctxValue.projectName}
                </Typography>
              )}
            </VisangAppContext.Consumer>

            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge anchorOrigin={{ vertical: "top", horizontal: "left", }} badgeContent={0} color="secondary" >
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton edge="end" aria-label="account of current user" aria-controls="user-menu" aria-haspopup="true"
              onClick={this.onClickUserMenuButton} color="inherit" >
              <AccountCircle />
            </IconButton>

            <Menu id="user-menu" anchorEl={this.state["user-menu-anchor"]} getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right", }} keepMounted open={Boolean(this.state["user-menu-anchor"])}
              onClose={this.onUserMenuClose} >
              {this.state.userMenuItems.map((item) => (
                <MenuItem
                  key={item.menuId}
                  onClick={(e) => {
                    this.onClickUserMenu(e, item);
                  }}
                >
                  <ListItemIcon>
                    <Icon path={item.icon} size={1} />
                  </ListItemIcon>
                  <Typography variant="inherit">{item.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Toolbar>
        </AppBar>

        <VisangDrawerMenu onDrawerOpenStatus={this.onDrawerOpenStatus} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route path={`${this.props.match.path}`} exact={true}>
              <Redirect to={`${this.props.match.path}/${drawer.defaultPath}`} />
            </Route>

            {drawer.children.map((child, index) => {
              const cpath = child.path as string;
              const idx = cpath.indexOf("?");
              const childPagePath = idx > 0 ? cpath.substring(0, idx) : cpath;
              console.log(`MainRoutePath: ${this.props.match.path}/${childPagePath}`);
              return (
                <Route
                  key={cpath}
                  path={`${this.props.match.path}/${childPagePath}`}
                  render={(props) => (
                    <child.component
                      {...props}
                      isLoading={this.state.isLoading}
                    />
                  )}
                />
              );
            })}
            
          </Switch>
        </main>
        </ThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const ret = {
    tabNum: state.TabReducer.tabNum,
    authorized: !!state.auth.authToken,
    members: state.visang.members,
  };
  return ret;
};

export default withRouter(withStyles(styles)(connect(mapStateToProps)(VisangMain)));
