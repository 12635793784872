import { configure, observable, action, runInAction, flow } from 'mobx';
import { asyncAction } from "mobx-async-action";
import { getVisangLessonData, makeVisangStrokeFileUrl } from '../data/UrlExistTest';
import { VisangMembersDB } from '../data/VisangMembersDB';

type HasLessonType = {
  [course: number]: {
    [lesson: number]: boolean,
  }
}

type StrokeJsonData = {
  [course: number]: {
    [lesson: number]: {
      [id: string]: string,
    }
  }
}


// decorate(VisangMobx, {
//   number: observable,
//   members: observable,
//   lessonExists: observable,
//   strokeData: observable,

//   increase: action,
//   decrease: action,
//   setMembers: action,
// })
configure({ enforceActions: 'observed' })

export default class VisangMobx {
  @observable number = 0;
  @observable members: string[] = [];
  @observable lessonExists: HasLessonType = {};
  @observable strokeData: StrokeJsonData = {};

  @action
  increase = () => {
    this.number++;
  }

  @action
  decrease = () => {
    this.number--;
  }

  @action
  setMembers = (members: string[]) => {
    this.members = [...members];
  }

  @action
  setHasLesson = (course: number, lesson: number, has: boolean) => {
    // 
    if (!Object.prototype.hasOwnProperty.call(this.lessonExists, course)) {
      this.lessonExists[course] = {};
    }

    const c = this.lessonExists[course];
    c[lesson] = has;
    this.increase();
  }

  @action
  setStrokeData = (course: number, lesson: number, id: string, json: string) => {
    if (!Object.prototype.hasOwnProperty.call(this.strokeData, course)) {
      this.strokeData[course] = {};
    }
    const c_stroke = this.strokeData[course];

    if (!Object.prototype.hasOwnProperty.call(c_stroke, lesson)) {
      c_stroke[lesson] = {};
    }
    const l_stroke = c_stroke[lesson];

    l_stroke[id] = json;

    this.increase();
  }


  getMembers = flow(function* () {
    const memberDB = VisangMembersDB.getInstance();

    // await 대신에 yield 를 사용합니다.
    const projects = yield memberDB.load();

    // 비동기 작업이 필요한 부분을 수동으로 감싸지 않아도 됩니다.
    this.members = [...memberDB.ids];
  })


  @action
  getMembers2 = () => {
    const memberDB = VisangMembersDB.getInstance();
    memberDB.load().then((students) => {
      runInAction(() => {
        this.members = [...memberDB.ids];
        // this.setMembers(memberDB.ids);
        // this.increase();
      });
    });
  }

  @action
  getAll = async (ids: string[]) => {
    for (let c = 0; c < numCourses; c++) {
      this.getCourseData(c, ids);
    }
  }

  @action
  getCourseData = async (course: number, ids: string[]) => {
    for (let l = 0; l < numLesseons; l++) {
      this.getLessons(course, l, ids).then(exists => {
        this.setHasLesson(course, l, true);
        this.increase();
        console.log(`course_${course} lesson_${l} result=${exists}`);
      });
    }
  }

  @action
  getLessons = (course: number, lesson: number, ids: string[]) => {
    let exists = false;

    const promises: Promise<string>[] = [];
    ids.forEach((id) => {
      if (!exists) {
        const url = makeVisangStrokeFileUrl(id, course, lesson);
        const pr = getVisangLessonData(url);
        promises.push(pr);
        pr.then(json => {
          if (json) {
            exists = true;
            this.setStrokeData(course, lesson, id, json);
            this.increase();
            console.log(`course_${course} lesson_${lesson} exists : ${url}`);
          }
        });
      }
    });

    return new Promise(resolve => {
      Promise.all(promises).then((results) => {
        // const rr = !!results.find(item => !!item );
        resolve(exists);
      })
    }) as Promise<boolean>;
  }
}