// import { STUDENTS_ARR } from "../data/Constant";
// import {
//   data_all_members_list
// } from "../data/students_data";

export class MembersDB {
  /** @type {?MembersDB} */
  static instance = null;


  constructor() {
    /** @type {string[]} */
    this.students = [];

    /** @type {string[]} */
    this.teachers = [];

    /** @type {string[]} */
    this.admins = [];

    /** @type {string[]} */
    this.all_members_id = [];

    /** @type {Map<string, MemberInfo>} */
    this.memberInfos = new Map();

    // 기존의 data_result_elements
    this.memberMap_old = {};

    this.ready = null;
  }

  /**
   * @returns {MembersDB}
   */
  static getInstance() {
    if (MembersDB.instance === null) {
      MembersDB.instance = new MembersDB();
      this.ready = MembersDB.instance.load();
    }

    return this.instance;
  }

  // fetchPostInfo = async (postId) => {
  //   const post = await service.getPost(postId);
  //   console.log(post);
  //   const comments = await service.getComments(postId);
  //   console.log(comments);
  // }
  get ids() {
    return this.all_members_id;
  }

  load = async () => {
    // console.log(">> fetchServerData");
    this.all_members_id = [];

    const token = localStorage.getItem("idToken");
    const headers = { authorization: `Bearer ${token}` };

    // 학생 맴버
    const response = await fetch("https://apis.neolab.net/v1/admin/application/member", { headers });
    // console.log("test 1 response.status : ", response.status);

    if (response.ok) {
      const jsonData = await response.json();

      // console.log("jsonData : ", jsonData);
      for (let i = 0; i < jsonData.resultElements.length; i++) {
        const member_info = jsonData.resultElements[i];
        const userId = `${member_info.id}`;
        //  console.log(`"userId: ${userId}, extra:${member_info.extra}`);

        let school = "";
        let grade = "";
        let group = "";
        let number = "";
        let user_type = 100;
        if (member_info.extra) {
          try {
            const organization_info = JSON.parse(member_info.extra);
            school = organization_info.school;
            grade = organization_info.grade;
            group = organization_info.class;
            number = organization_info.number;

            user_type = 200;
          }
          catch (e) {
            // console.log(`Invalid extra filed: ID=${userId}, EXTRA FIELD="${member_info.extra}",`, e);
          }
        }

        /** @type {MemberInfo} */
        const member = {
          allowedPushMessage: member_info.allowedPushMessage,
          birthday: member_info.birthday,
          canShare: member_info.canShare,
          createdAt: member_info.createdAt,
          email: member_info.email,
          gender: member_info.gender,
          id: member_info.id,
          lastVisitDate: member_info.lastVisitDate,
          modifiedAt: member_info.modifiedAt,
          name: member_info.name,
          nationality: member_info.nationality,
          password: member_info.password,
          pictureUrl: member_info.pictureUrl,
          visitCount: member_info.visitCount,

          school: school,
          grade: grade,
          class: group,
          number: number,

          userType: user_type,    // 0:admin, 100:teacher, 200:student
          extra: "",
        };

        // 멤버 정보를 매핑하고
        this.memberInfos[userId] = member;

        // 전체 멤버를 등록한다.
        this.all_members_id.push(member.id);

        if (user_type === 200) {
          this.students.push(member.id);
        }
        else if (user_type === 100) {
          this.teachers.push(member.id);
        }
        else if (user_type === 0) {
          this.admins.push(member.id);
        }
        else {
          this.admins.push(member.id);
        }


        // 기존의 data_result_elements
        this.memberMap_old[userId] = member_info;
        // console.log("Member loaded: ", member.id);
        // // 맴버 별 requestUrl
        // let exam_result_url = `https://apis.neolab.net/v1/admin/storage/${userId}/file/${EXAME_FILE_NAME_FIRST}`;
        // memberUrls.push({ member_info, exam_result_url });
      }

      // await this.processFileUri(memberUrls);
    } else {
      console.log(`Error : ${response.status}`);
    }

    console.log(`Students:${this.students.length} Teachers:${this.teachers.length} Admins:${this.admins.length}`);

    return this.students;
  }

  /**
   * @return {Array.<string>}
   */
  get studentIDs() {
    return this.students;
  }

  /**
   * @return {Array.<string>}
   */
  get teacherIDs() {
    return this.teachers;
  }

  /**
   * @return {Array.<string>}
   */
  get adminIDs() {
    return this.admins;
  }

  get data_result_elements() {
    return this.memberMap_old;
  }

  get dataAllMembersList() {
    const ret = this.all_members_id.map(id => {
      return this.memberMap_old[id];
    });

    return ret;
  }

  getName(id) {
    /** @type {MemberInfo} */
    const info = this.memberInfos[id];
    if (info)
      return info.name;

    return "";
  }
}

