import { fabric } from "fabric";
import KerisData from "./KerisData";
import { PLAYSTATE } from "./KerisReplay";
import "../typedef";

import { point3Curve, drawPath } from "./DrawCurves";

const timeTickDuration = 20; // ms
const DISABLED_STROKE_COLOR = "rgba(0, 0, 0, 0.1)";
const INVISIBLE_STROKE_COLOR = "rgba(255, 255, 255, 0)";
const INCOMPLETE_STROKE_COLOR = "rgba(255, 0, 255, 0.4)";
const CURRENT_POINT_STROKE_COLOR = "rgba(255, 255, 255, 1)";

export default class PageRenderer {
  /**
   *
   * @param {string} canvasName
   * @param {function} playTimeHandler
   * @param {TimebasedRenderStrokes} strokeStream
   */

  constructor(canvasName, playTimeHandler, playStateHandler, strokeStream, autoStop) {
    this.playingTimeHandler = playTimeHandler;
    this.playStateHandler = playStateHandler;

    this.strokeStream = strokeStream;

    // 재생 시간과 관련된 변수

    /**
     * relative time
     * absolute time(unix ms) = playingTime + startTime_whole
     */
    this.playingTime = -1;

    /**
     * absolute time(unix ms)
     * relative time = absolute time - this.startTime_whole
     */
    this.startTime_page = 0;
    this.endTime_page = 0;

    this.startTime_whole = 0;
    this.endTime_whole = 0;

    /**
     * the page info now being played
     */
    this.rel_auto_play_endtime = 0;
    this.autoStop = autoStop;
    //
    this.canvasName = canvasName;
    this.canvas = null;
    this.frameconfig = 1;
    this.bgcolor = 0;
    this.bgurl = "";
    this.originSize = { w: 800, h: 1000 };
    this.strokWidth = this.originSize.w;
    this.strokHeight = this.originSize.h;
    this.lineScale = [1, 3, 5, 7, 10];
    this.pathArray = []; // Rendering Path

    this.scaleX = 1;
    this.scaleY = 1;
    // for replay
    this.renderTime = 0;
    this.replaySpeed = 1;
    this.dotArray = [];
    this.strokes = null; // neoink format stroke
    this.backgroundImage = null;

    this.timer = null;

    this.timeStr = "";
    this.pageNumber = 0;
  }

  clear = () => {
    this.canvas.clear();
  };

  // resize = size => {
  //   var zoom = size.w / this.originSize.w;
  //   this.canvas.setZoom(zoom);
  //   this.canvas.setHeight(size.h);
  //   this.canvas.setWidth(size.w);
  // };

  setReplaySpeed = (speed) => {
    this.replaySpeed = speed;
    // console.log("set speed", speed)
  };

  resize = (size) => {
    const zoom = size.w / this.originSize.w;
    this.canvas.setZoom(zoom);
    this.canvas.setHeight(size.h);
    this.canvas.setWidth(size.w);
  };

  //step 1: canvas set size and background image
  setCanvas = (size, bgurl) => {
    this.bgurl = bgurl;
    this.originSize = size;
    this.canvas = new fabric.Canvas(this.canvasName, {
      backgroundColor: "rgb(255,255,255)",
      // selectionColor: 'blue',
      selection: false,
      controlsAboveOverlay: true,
      centeredScaling: true,
      allowTouchScrolling: true,
      selectionLineWidth: 4,
      width: size.w,
      height: size.h,
    });
    // this.canvas.isDrawingMode = true
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        bgurl,
        (img) => {
          // console.log("bgurl !!!!!!!!!!!!!!!",bgurl);
          img.scaleToWidth(size.w);
          // img.scaleToHeight(size.h)
          this.backgroundImage = img;
          img.selectable = false;
          this.canvas.setBackgroundImage(
            img,
            this.canvas.renderAll.bind(this.canvas)
          );
          // console.log("image size", size, img.width, img.height);
          resolve();
        },
        { crossOrigin: "Anonymous" }
      );
    });
  };

  stopInterval() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  replayPause = () => {
    this.stopInterval();
  };

  replayStop = () => {
    this.playingTime = 0;
    this.playingTimeHandler(this.playingTime);
    this.drawPageAtTime(this.playingTime);
  };

  replayRewind = () => {
    this.playingTime = this.rewindToPageStart();
    this.playingTimeHandler(this.playingTime);
    this.drawPageAtTime(this.playingTime);
  };

  setTimePoint = (ms) => {
    this.playingTime = ms;
    this.drawPageAtTime(this.playingTime);
    // this.playingTimeHandler(this.playingTime);
  };

  setAutoStop = (sw) => {
    this.autoStop = sw;
  }
  setPage = (page) => {
    this.pageNumber = page.pageNumber;
  };

  rewindToPageStart = () => {
    const newTime = this.startTime_page - this.startTime_whole - 1;
    return newTime;
  };

  setPageStrokes = (page, strokeStream) => {
    const { section, owner, book, pageNumber } = page;
    const strokes_kitty = strokeStream.strokes.filter(
      (s) =>
        s.book == book &&
        s.owner == owner &&
        s.section == section &&
        s.pageNum == pageNumber
    );
    console.log(strokes_kitty);

    let lastStroke, lastDot;

    // 페이지, 시작시간, 끝시각
    const page_start_time = strokes_kitty[0].dotArray[0].time;
    lastStroke = strokes_kitty[strokes_kitty.length - 1];
    lastDot = lastStroke.dotArray[lastStroke.dotArray.length - 1];
    const page_end_time = lastDot.time;

    this.startTime_page = page_start_time;
    this.endTime_page = page_end_time + 1;

    // 전체 필기, 시작시간, 끝시간
    const whole_start_time = strokeStream.strokes[0].dotArray[0].time;
    lastStroke = strokeStream.strokes[strokeStream.strokes.length - 1];
    lastDot = lastStroke.dotArray[lastStroke.dotArray.length - 1];
    const whole_end_time = lastDot.time;

    this.startTime_whole = whole_start_time;
    this.endTime_whole = whole_end_time + 1;

    this.playingTime = -1; // absolute time (unix ms) = playingTime + startTime_whole

    return { strokes_kitty, start_time: page_start_time };
  };

  // Drawing iOS Data Format
  preparePage = (page, rect, size, scale) => {
    const strokeStream = this.strokeStream;
    // console.log("Page data", page, rect, size, scale)
    this.strokWidth = size.w;
    this.strokHeight = size.h;
    this.rect = rect;
    this.scale = scale;
    this.scaleX = (size.w / rect.width / scale) * 100;
    this.scaleY = (size.h / rect.height / scale) * 100;
    const strokes = page.strokes;
    this.strokes = strokes;

    const stroke_data = this.setPageStrokes(page, strokeStream);
    const strokes_kitty = stroke_data.strokes_kitty;
    const page_start_time = stroke_data.start_time;

    this.resetPathArray();
    this.initPathArray(strokes_kitty, scale, page_start_time);

    // this.playingTime = this.rewindToPageStart();
    this.drawPageAtTime(this.playingTime);
  };

  resetPathArray = () => {
    if (this.canvas) {
      this.pathArray.forEach((path) => {
        this.canvas.remove(path);
      });
    }
    this.pathArray = [];
    // this.startTime_page = -1;
    // this.endTime_page = -1;
  };

  initPathArray = (strokes, scale, startTime) => {
    strokes.forEach((stroke) => {
      this.color = stroke.color;
      this.thickness = stroke.thickness;

      const path = this.createPathFromDots(stroke.dotArray, scale, startTime);
      this.pathArray.push(path);
      if (this.canvas) {
        this.canvas.add(path);
        // console.log("Add Path", path);
      }
    });
  };

  // Draw Dot from Pen
  createPathFromDots = (dots, scale, page_start_time, whole_start_time) => {
    // console.log("dot Count", dots.length);
    const rect = this.rect;
    // console.log(rect);

    const pointArray = [];
    dots.forEach((dot) => {
      const pt = this.getXYPfromDot(dot, rect, scale);

      pointArray.push(pt);
    });

    // Draw Stroke
    const color = this.color;
    const thickness = this.thickness;
    // console.log("Color, thickness", color, thickness);
    // console.log(pointArray.length, pointArray[0]);
    const pathOption = {
      objectCaching: false,
    };
    pathOption.stroke = color;
    const tempThickness = this.scaleX * thickness * 0.5;
    pathOption.strokeWidth = tempThickness;
    pathOption.strokeLineCap = "round";
    pathOption.fill = color;
    const pathData = drawPath(pointArray, this.scaleX * thickness);

    const path = new fabric.Path(pathData, pathOption);
    path.color = color;
    path.zoom_scale = scale;
    // TODO: selectable and evented
    path.selectable = false;
    path.evented = true;

    // kitty, 시작점으로부터의 상대 시간
    path.startTime = dots[0].time;
    path.endTime = dots[dots.length - 1].time;

    path.startTime_page = dots[0].time - page_start_time;
    path.endTime_page = dots[dots.length - 1].time - page_start_time;

    path.startTime_whole = dots[0].time - whole_start_time;
    path.endTime_whole = dots[dots.length - 1].time - whole_start_time;
    path.dots = dots;

    return path;
  };

  // Event
  setSeekHandeler = (handler) => {
    this.seekCallback = handler;
  };

  eventHandler = (event) => {
    // console.log(event, event.target.time)
    this.seekCallback(event.target.time + 1);
  };

  /**
   * time tick
   */
  onTick = () => {
    const delta_time = timeTickDuration * this.replaySpeed;
    const playTime = this.playingTime + delta_time;

    // if (Math.floor(playTime / 1000) !== Math.floor(this.playingTime / 1000))
    this.playingTimeHandler(this.playingTime);

    this.playingTime = playTime;

    let rel_endtime = this.endTime_whole - this.startTime_whole;
    if (this.autoStop) {
      // relative time
      rel_endtime = this.rel_auto_play_endtime;
    }

    if (playTime > rel_endtime + delta_time) {
      this.drawPageAtTime(playTime);
      this.playStateHandler(PLAYSTATE.pause);
    }
  };

  replayStart = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    // 자동 멈춤 지점을 설정 
    this.rel_auto_play_endtime = this.endTime_page - this.startTime_whole;

    this.timer = setInterval(this.onTick, timeTickDuration);
    this.playingTimeHandler(this.playingTime);
  };

  /**
   * stroke replay by kitty
   * @param {number} playingTime - millisecond from 0 (relative to whole strokes)
   */
  drawPageAtTime = (playingTime) => {
    const time_base = this.startTime_whole;

    const time_abs = playingTime + time_base;
    this.pathArray.forEach((path) => {

      if (time_abs <= path.startTime) {
        path.fill = DISABLED_STROKE_COLOR;
        path.stroke = DISABLED_STROKE_COLOR;
      } else if (path.startTime < time_abs && time_abs <= path.endTime) {
        path.fill = INVISIBLE_STROKE_COLOR;
        path.stroke = INVISIBLE_STROKE_COLOR;

        this.drawIncompletedPath(path, time_abs);
      } else {
        path.fill = path.color;
        path.stroke = path.color;
      }
    });

    // 모든 획이 다 그려져야 하는 상황이면 temp 획을 안보이게 한다.
    if (this.pathArray[this.pathArray.length - 1].endTime < time_abs) {
      this.canvas.remove(this.tempPath);
      this.tempPath = null;

      this.canvas.remove(this.tempPath_disabled);
      this.tempPath_disabled = null;

      // 확인 사살, 맨 마지막 획만
      const path = this.pathArray[this.pathArray.length - 1];
      path.fill = path.color;
      path.stroke = path.color;
    }

    this.canvas.requestRenderAll();
  };

  /**
   *
   * @param {fabric.Path} path - path to draw
   * @param {number} t - playTime in real-time (unixtime ms)
   */
  drawIncompletedPath = (path, t) => {
    const dots = path.dots;
    const scale = path.zoom_scale;

    // console.log("dot Count", dots.length);
    const rect = this.rect;
    // console.log(rect);

    const pointArray = [];
    const pointArray_disabled = [];

    let last_completed_dot = null;

    dots.forEach((dot) => {
      if (dot.time <= t) {
        const pt = this.getXYPfromDot(dot, rect, scale);
        if (pt) pointArray.push(pt);
        last_completed_dot = dot;
      } else {
        const pt = this.getXYPfromDot(dot, rect, scale);

        if (last_completed_dot) {
          const pt_last = this.getXYPfromDot(last_completed_dot, rect, scale);
          if (pt_last) pointArray_disabled.push(pt_last);
          last_completed_dot = null;
        }

        if (pt) pointArray_disabled.push(pt);
      }
    });

    const path_new = this.createRealTimePathObject(
      pointArray,
      path.color,
      this.thickness
    );
    const path_new_disabled = this.createRealTimePathObject(
      pointArray_disabled,
      INCOMPLETE_STROKE_COLOR,
      this.thickness
    );

    if (this.canvas) {
      this.canvas.add(path_new);
      this.canvas.remove(this.tempPath);
      this.tempPath = path_new;

      this.canvas.add(path_new_disabled);
      this.canvas.remove(this.tempPath_disabled);
      this.tempPath_disabled = path_new_disabled;
      // console.log("Add Path", path);
    }

    // let canvas_ktty = this.canvasRef.current;
    // let ctx = canvas_ktty.getContext("2d");
  };

  createRealTimePathObject = (pointArray, color, thickness) => {
    // Draw Stroke

    const pathOption = {
      objectCaching: false,
    };

    pathOption.stroke = color;
    const tempThickness = this.scaleX * thickness * 0.5;
    pathOption.strokeWidth = tempThickness;
    pathOption.strokeLineCap = "round";
    pathOption.fill = color;
    const pathData = drawPath(pointArray, this.scaleX * thickness);

    const path_new = new fabric.Path(pathData, pathOption);
    path_new.color = color;
    // TODO: selectable and evented
    path_new.selectable = false;
    path_new.evented = true;

    return path_new;
  };

  getXYPfromDot = (dot, rect, scale) => {
    const scaleXY = (dot_x, dot_y, scale) => {
      const offset = 0;

      return {
        x: ((dot_x - offset) * scale) / 100,
        y: ((dot_y - offset) * scale) / 100,
      };
    };

    const scaled = scaleXY(dot.x, dot.y, scale);

    const p = dot.f;
    let x = scaled.x - rect.x;
    let y = scaled.y - rect.y;
    if (
      x > rect.width * this.scaleX ||
      y > rect.height * this.scaleY ||
      x < 0 ||
      y < 0
    ) {
      // console.log("튀었음", dot)
      return;
    }
    x *= this.scaleX;
    y *= this.scaleY;

    return { x, y, p };
  };
}
