import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { setVisangCourse } from "../store/VisangRedux";
import { courses } from "../constants/VisangConstants";

type Props = {
  value: number,
  classes
}

type State = {
  tabNum: number,
  members: [],
}

export const getVisangTabNames = () => {
  return courses;
}



export class VisangTabs extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      tabNum: props.value,
      members: [],
    };
  }

  componentDidMount() {
    // console.log(">> TopTabs >> componentWillMount");
  }

  handleChange = async (event, tabNum) => {
    this.setState({ tabNum: tabNum });
    setVisangCourse(tabNum);
  };

  render() {
    const { classes, value } = this.props;
    const topTabs = getVisangTabNames();

    return (
      <div className={classes.root}>
          <Tabs value={value} onChange={this.handleChange} aria-label="Toptab" >
             {topTabs.map((name, i) => (
              <Tab key={`tab-${i}`} label={`${name}`}  id={`simple-tab-${i}`} aria-controls={`simple-tabpanel-${i}`} />
            ))}
          </Tabs>
      </div>
    );
  }
}
