import React from "react";
import PropTypes from "prop-types";
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";

const headCells = [
  { id: "number", disablePadding: true, label: "번호", align: "center" },
  { id: "name", disablePadding: true, label: "이름", align: "center" },
  // { id: "id", disablePadding: true, label: "아이디", align: "right" },
  { id: "delta_time", disablePadding: true, label: "총 풀이시간", align: "right" },
  { id: "writing_time", disablePadding: true, label: "필기시간", align: "right" },
  { id: "writing_ratio", disablePadding: true, label: "필기율", align: "right" },
  // { id: "avr_force", disablePadding: true, label: "필압(최소/평균/최대)", align: "right" },
  { id: "avr_speed", disablePadding: true, label: "필기속도", align: "right" },
  { id: "lesson", disablePadding: true, label: "Lesson", align: "right" },
  { id: "replay", disablePadding: true, label: "필기재생", align: "center" },
];

export default function VisangTableHead(props) {
  const {
    classes,
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={classes.table_row}>
        <TableCell component="th" scope="row" width="2%">
        </TableCell>
        {headCells.map((headCell) => {
          return (
            <TableCell key={headCell.id} align={headCell.align as 'inherit' | 'left' | 'center' | 'right' | 'justify'} className={classes.table_row}
              sortDirection={orderBy === headCell.id ? order : false}
              padding="none">

              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >

                <Box fontSize={15} fontWeight="fontWeightBold">

                  {headCell.label}</Box>
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  );
}
