export const VISANG_RESOURCES = {
  "course_0" : {
    pageImagePrefix: "/visang/3_27_692_[Y1] STARTER L1/3_27_692_",
  },

  "course_1" : {
    pageImagePrefix: "/visang/3_27_693_[Y2] JUMPER L1/3_27_693_",
  },

  "course_2" : {
    pageImagePrefix: "/visang/3_27_696_[Y5] ADVANCED L1/3_27_696_",
  },

  
}

export const EXAM_FILE_RESOURCES = {
  "기초학력평가 2020년 12월": {
    info: {
      date: "2020-12-11",
      type: "기초학력평가",
      name: "2020년 12월",
      subject: "수학",
    },
    strokeFile: '3_27_689_stroke.json',
    examFile: '3_27_689_examInfo.json',
    pageNprojFile: "note_689.nproj",

    pageImagePrefix: "/img/3_27_689_",

    // 임시코드 2020/09/10
    margin: { left: 0, top: 0, right: 0, bottom: 0 },
    numPages: 6,
  },

  "기초학력평가 2020년 8월": {
    info: {
      date: "2020-08-06",
      type: "기초학력평가",
      name: "2020년 1차",
      subject: "수학",
    },

    strokeFile: '3_27_1089_stroke.json',
    examFile: '3_27_1089_examInfo.json',
    pageNprojFile: "note_1089.nproj",

    pageImagePrefix: "/img/3_27_1089_",

    // 임시코드 2020/09/10
    margin: { left: 0, top: 0, right: 0, bottom: 0 },
    numPages: 6,
  },

  "수학튼튼": {
    info: {
      date: "2020-09-10",
      type: "수학튼튼 평가",
      name: "2020년 9월",
      subject: "수학",
    },

    strokeFile: '3_27_1099_stroke.json',     // on ink server
    examFile: '3_27_1099_examInfo.json',     // on ink server
    pageNprojFile: "note_1099.nproj",

    pageImagePrefix: "/img/3_27_1099_",         // on front-end web server

    // 임시코드 2020/09/10
    margin: { left: 5, top: 5, right: 5, bottom: 5 },
    numPages: 4,
  },
};

export const QUESTION_CATEGORIES = [
  [
    "직선, 선분, 반직선",
    "각도",
    "도형의 변형",
    "다각형과 정다각형",
  ],
  [
    "직선, 선분, 반직선",
    "각도",
    "도형의 변형",
    "다각형과 정다각형",
  ],

  [
    "직선, 선분, 반직선, 각도",
    "예각과 둔각",
    "직각 삼각형",
    "직사각형",
    "정사각형",
  ]
];


// 현재는 로그인만 하면, 멤버로 등록되기 때문에,
export const STUDENTS_ARR = [
  "teseuteu771@gmail.com",
  "neolab010112@gmail.com",
  "neolab010111@gmail.com",
  "neolab010110@gmail.com",
  "neolab010109@gmail.com",
  "neolab010107@gmail.com",
  "neolab010108@gmail.com",
  "choijin@neolab.net",
  "neolab010101@gmail.com",
  "neolab010105@gmail.com",
  "neolab010104@gmail.com",
  "neolab010103@gmail.com",
  "neolab010102@gmail.com",
  "kim_eeejeong@neolab.net",
  "jeenasook1@gmail.com",
  "neosmartpen@neolab.kr",
  "gonttaro@gmail.com",
  "bskim@neolab.net",
  "mrlove1.neo1@gmail.com",
  "pkc8100@neolab.net",
  "neolabdata@gmail.com",
  "howyaaa@gmail.com",
  "hslee@neolab.net",
  "neolabdev.test0@gmail.com",
  "hisdesign@neolab.net",
  "05yoonseo@neolab.net",
  "sbkim@neolab.net",
  "inuks2@gmail.com",
  "kitty@neolab.net",
  "arammoon@neolab.net",
  "mjkim@neolab.net",
  "readymade1010@gmail.com",
  "chyccs@gmail.com",
  "mrlove1@neolab.net",
  "hwiyoung@neolab.net",
  "neolab.soft.test1@gmail.com",
  "inuks2@neolab.net",
  "chyccs@neolab.net",
];


export const getTabName = (index) => {
  return getTabNames()[index];
}

export const getTabNames = () => {
  return Object.keys(EXAM_FILE_RESOURCES);
}
