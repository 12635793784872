import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { Box, Table, TableCell, TableHead, TableRow } from "@material-ui/core";
import { getVisangMembers } from "../store/VisangRedux";
import { RootState } from "../../store/configureStore";
import { CSSProperties, withStyles } from "@material-ui/styles";
import VisangLesson from "../data/VisangLesson";
import { store } from "../../App";
import qs from "qs";
import { courses, lessonNames } from "../constants/VisangConstants";
import { TopTabs } from "../../components/Panels/TopTabs";
import { VisangTabs } from "../component/VisangTabs";
import VisangStudentTable from "../component/VisangStudentTable";
import { data_selected_user } from "../../data/students_data";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flexGrow: 1,

    backgroundColor: theme.palette.background.paper,
    // textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    // height: 800,
    // maxHeight: 800,
  },
  table: {
    // margin: "20px",
  },
  tableHead: {
    // fontSize: '1.0rem'
  },

  table_row: {
    "padding-left": "0px",
    "padding-right": "0px",
    // "padding-top": "10px",
    // "padding-bottom": "10px",
  },
});


interface Props {
  isLoading: boolean,
  tabNum: number,
  classes: any,
  authorized: boolean,

  location?,

  members: string[],

  course: number,
  lesson: number,

  dataRefreshed?: number
}

interface State {
  value: number,
  isLoading: boolean,

  members: string[],

}

class VisangDetail extends React.Component<Props, State> {
  static defaultProps: Props = {
    isLoading: false,
    tabNum: 0,
    classes: undefined,
    authorized: false,

    members: [],
    course: 0,
    lesson: 0,
  }

  constructor(props = VisangDetail.defaultProps) {
    super(props);
    console.log("StudentsPage isLoading : ", props.isLoading);

    this.state = {
      value: props.tabNum,
      isLoading: props.isLoading,
      members: [],
    };

  }

  componentDidMount() {
    console.log(">> componentDidMount");
    getVisangMembers();

    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
    });
    const { course, lesson } = query;
    console.log(`visang detail page: course=${course} lesson=${lesson}`);
  }

  shouldComponentUpdate(nextProps: Props, nextState) {
    if (this.props.members !== nextProps.members) {
      const state = store.getState();
    }

    return true;
  }


  render() {

    // if (this.state.data_load_fail) {
    //   logout();
    //   this.props.history.push("/");
    // }

    // const topTabs = getTabNames();
    const { classes } = this.props;

    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true // /about?details=true 같은 쿼리 주소의 '?'를 생략해주는 옵션입니다.
    });
    
    const { course, lesson } = query;

    const lessonName = lessonNames[lesson];
    const courseName = courses[course];

    const state = store.getState();
    const ld = state.visang?.lessonData[course]?.[lesson];

    // const { members, number } = this.props.visangStore;
    let ids = [];
    if (ld) {
      ids = Object.keys(ld.data.strokeUrls);
    }
    const { members } = this.props;

    console.log( `MM course=${course}`)
    return (
      <div className={classes.root}>
        <VisangTabs value={parseInt(course)} classes={this.props.classes} />
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper classes={classes.paper}>
                <VisangStudentTable
                  students={ids} course={course} lesson={lesson} />
                {/*                       
                <h1>{courseName} ({lessonName})  {members.length}</h1>
                <Table>
                  <TableHead>
                    {ids?.map((key, value) => {
                      return (
                        <TableRow key={key[0]}>
                          <TableCell>
                            {key[0]}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableHead>
                </Table> */}

              </Paper>
            </Grid>
          </Grid>
        </div >

      </div >
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const ret = {
    tabNum: state.TabReducer.tabNum,
    authorized: !!state.auth.authToken,
    members: state.visang.members,
    dataRefreshed: state.visang.dataRefreshed

  };
  return ret;
};


export default withStyles(styles)(connect(mapStateToProps)(VisangDetail));


