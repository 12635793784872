import "../typedef";

import { getTabName, EXAM_FILE_RESOURCES } from "../data/Constant";


export class EvaluationResultDB {
  constructor() {
    /** @type {Array.<string>} */
    this.all_members_id = [];
    this.presentedMemberList = {};

    this.load = this.load.bind(this);
    this.loadJson = this.loadJson.bind(this);
    this.loadExamResults = this.loadExamResults.bind(this);
  }

  /**
   * id로부터 시험정보를 받아올 URL을 반환한다
   *
   * @param {string} id
   * @return {string} url
   */
  getExamResultUrl(id, exam_file_name) {
    if ( !exam_file_name ) {
      throw new Error("exam result file name is not provided");
    }
    const exam_result_url = `https://apis.neolab.net/v1/admin/storage/${id}/file/${exam_file_name}`;
    return exam_result_url;
  }

  /**
   * 시험정보를 download한다
   * @param {Array.<string>} arr_id
   */
  async load(arr_id, tabNum) {

    // let urls = arr_id.map((id) => {
    //   return { id: id, url: this.getExamResultUrl(id) };
    // });

    const tab_info = getTabName(tabNum);
    const json_url_base = EXAM_FILE_RESOURCES[tab_info].examFile;
    await this.loadExamResults(arr_id, json_url_base);
    // await this.loadExamResults(arr_id, EXAME_FILE_NAME_FIRST);
    // await this.loadExamResults(arr_id, EXAME_FILE_NAME_CHOONGNAM);

  }


  /**
   * URL로부터 JSON 파일을 다운로드한다
   * @param {string} url
   * @param {Object} headers
   */
  async loadJson(url, headers = null) {
    if (!headers) {
      headers = {};
    }

    const response = await fetch(url, { headers });
    const json = await response.json();

    return json;
  }

  /**
   *
   * @param {Array.<QuestionInfo>} questionInfoArray
   * @return {number}
   */
  getTotalSolveTime(questionInfoArray) {
    let total_time = 0;
    for (let i = 0; i < questionInfoArray.length; i++) {
      total_time += questionInfoArray[i].solveTimeSec;
    }
    return total_time;
  }

  async loadSingleResult(id, exam_file_name ) {
    const user_data_url = this.getExamResultUrl(id, exam_file_name);

    const token = localStorage.getItem("idToken");
    const headers = { authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json", };
    const response_redir = await fetch(user_data_url, { headers });
    const json_redir = await response_redir.json();

    const file_uri = json_redir.requestUri;
    if (file_uri) {
      // 시험 결과 데이터
      // console.log("   GET", id, file_uri);

      const response_exam_result = await fetch(file_uri);
      // console.log("");
      // console.log("userId : ", id);
      // console.log(response_exam_result);

      const json_data = await response_exam_result.json();
      // console.log(id, json_data);

      /** @type {ExamData} */
      const examData = json_data;
      const t = this.getTotalSolveTime(examData.questionInfoArray);  // 초
      examData.solveTime = t;

      return { id, examData };
    }

    return { id, examData: null };
  }

  /**
   * 전달받은 파일경로를 가지고 데이터를 읽어온다.
   *
   * @param {*} urls 파일 경로들
   */
  async loadExamResults(id_arr, exam_file_name) {
    const token = localStorage.getItem("idToken");
    const headers = { authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json", };

    const promises = id_arr.map((user_id) => {
      return this.loadSingleResult(user_id, exam_file_name);
    });

    const results = await Promise.all(promises);
    // console.log(results);

    results.forEach(element => {
      const id = element.id;
      const examData = element.examData;
      // console.log("SUCCESS", id, examData);
      this.all_members_id.push(id);
      this.presentedMemberList[id] = examData;
    });

    // console.log("LOADING COMPLETED, ANSWER SHEETS ====================");
  }

  /**
   * 전달받은 파일경로를 가지고 데이터를 읽어온다.
   *
   * @param {*} urls 파일 경로들
   */
  async loadExamResults_old(urls) {
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log(">> loadExamResults");

    // 파일경로 요청
    const fileUris = [];
    // let requestCount = urls.length;
    let count = 0;
    const token = localStorage.getItem("idToken");
    // console.log(`access_token : ${token}`);
    const headers = { authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json", };

    Promise.all(urls.map((url_info) => {
      const user_id = url_info.id;
      // console.log("GET", user_id, url_info.url);

      // 시험 결과 데이터의 bulk(json)의 위치를 받아 온다
      fetch(url_info.url, { headers }).then((response_redir) => {
        // console.log(response);

        response_redir.json().then((json_redir) => {
          // console.log(json);

          const file_uri = json_redir.requestUri;

          // 시험 데이터가 있는 사람만, 시험  결과 데이터를 받아 온다
          if (file_uri) {
            // 시험 결과 데이터
            // console.log("   GET", user_id, file_uri);

            fetch(file_uri).then((response_exam_result) => {
              // console.log("");
              // console.log("userId : ", user_id);
              // console.log(response);

              response_exam_result.json().then((json_data) => {
                count++;
                // console.log("count : ", count);
                // console.log(json);

                /** @type {ExamData} */
                const examData = json_data;
                const t = this.getTotalSolveTime(examData.questionInfoArray);  // 초
                examData.solveTime = t;

                console.log("SUCCESS", user_id, examData);
                this.all_members_id.push(user_id);
                this.presentedMemberList[user_id] = examData;
                // console.log("examData : ", examData);
                // console.log("examContentList size : ", examContentList.length);

              }).catch(e => {
                console.log("JSON parse Error", user_id, response_exam_result, e);

              });
            })

          }
        }).catch(e => {
          console.log("JSON parse Error", e);

        })
      })
    }));
  }

  /**
   * @return {ExamData}
   */
  getExamData(id) {
    return this.presentedMemberList[id];
  }
}

