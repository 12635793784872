import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { data_members } from "../data/students_data";

import { MembersDB } from "../services/members";
import { EvaluationResultDB } from "../services/eveluation_result";
import { ExamSummaryProcessor } from "../services/exam_summary_processor";
import {
  ShowUIProgressBackdrop,
  HideUIProgressBackdrop,
} from "../store/reducers/ui";

import { fetchInfo } from "../services/data_loader";
import { logout } from "../store/reducers/authorization";

import { TopTabs } from "../components/Panels/TopTabs";
import { connect } from "react-redux";
import { TabPanel } from "../components/Panels/TabPanel";

import DashboardComponent from "../PageComponents/DashBaord/DashboardComponent";
import { EXAM_FILE_RESOURCES, getTabName, getTabNames } from "../data/Constant";


const styles = (theme) => ({
  root: {
    // width: '95%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flexGrow: 1,
    // height: '100%',

    // backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

/**
 *  Dashboard Page
 */
class DashboardPage extends React.Component {
  // export default function DashboardPage() {
  constructor(props) {
    super(props);
    console.log("DashboardPage isLoading : ", props.isLoading);
    console.log(`DashboardPage TAB VALUE = ${props.tabNum}`);
    const memberDB = new MembersDB();
    const examResult = new EvaluationResultDB();
    const examSummaryProcessor = new ExamSummaryProcessor();

    this.state = {
      value: this.props.tabNum,
      isLoading: this.props.isLoading,
      members: [],

      // kitty, 2020-08-16
      data_load_fail: false,

      fetching: false, // tells whether the request is waiting for response or not
      memberDB: memberDB,
      examResult: examResult,
      examSummaryProcessor: examSummaryProcessor,
    };
  }

  async componentDidMount() {
    // console.log(">> componentDidMount");

    ShowUIProgressBackdrop();
    // await fetchInfo(this, this.props.tabNum);
    HideUIProgressBackdrop();

    if (this.props.isLoading) {
      // console.log("data_members  : ", data_members.length);
      this.setState({
        isLoading: true,
        members: data_members,
      });
    }
  }


  async shouldComponentUpdate(nextProps, nextState) {
    // if (nextProps.tabNum !== this.props.tabNum) {
    //   console.log("\n\nUPDATE\n\n\n");
    //   const memberDB = new MembersDB();
    //   const examResult = new EvaluationResultDB();
    //   const examSummaryProcessor = new ExamSummaryProcessor();

    //   this.state = {
    //     ...this.state,

    //     fetching: true,
    //     memberDB: memberDB,
    //     examResult: examResult,
    //     examSummaryProcessor: examSummaryProcessor,
    //   };

    //   ShowUIProgressBackdrop();
    //   await fetchInfo(this, nextProps.tabNum);
    //   HideUIProgressBackdrop();
    //   this.setState({ fetching: false, });

    //   // this.state.renderer.replayStart();
    //   // return false;
    // }

    return true;
  }

  // onSetTabValue = (newValue) => {
  //   this.setState({ value: newValue });
  // };
  render() {
    const { classes, tabNum } = this.props;

    if (this.state.data_load_fail) {
      // const url = baseUrl + "/login_social/google_kerispjt_web?redirect_uri=" + redirectUri;
      logout();
      this.props.history.push("/");
      // return (
      //   <Redirect to="/login" />
      // );
    }

    // console.log(`DashboardPage TAB VALUE = ${tabNum}`);
    const topTabs = getTabNames();

    return (
      <div className={classes.root}>
        <TopTabs onChange={this.onSetTabValue} value={this.state.value} classes={this.props.classes} />

        {/* <div dangerouslySetInnerHTML={{ __html: str }} /> */}
        { topTabs.map((name, index) => {
          const key = `dashboard-${index}`;

          return (
            <TabPanel value={this.state.value} index={index} key={key}>
              <DashboardComponent classes={classes} tabNum={tabNum} isLoading={this.state.isLoading} />
            </TabPanel>
          );
        })}

        {/* <TabPanel value={this.state.value} index={0}>
          <DashboardComponent classes={classes} tabNum={tabNum} isLoading={this.state.isLoading} />
        </TabPanel>

        <TabPanel value={this.state.value} index={1}>
          <DashboardComponent classes={classes} tabNum={tabNum} isLoading={this.state.isLoading} />
        </TabPanel>

        <TabPanel value={this.state.value} index={2}>
          <DashboardComponent classes={classes} tabNum={tabNum} isLoading={this.state.isLoading} />
        </TabPanel> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const ret = {
    tabNum: state.TabReducer.tabNum,
  };
  return ret;
};

export default withStyles(styles)(connect(mapStateToProps)(DashboardPage));

