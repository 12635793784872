import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chart, {
  ArgumentAxis,
  Legend,
  Series,
  ValueAxis,
  Label,
  CommonSeriesSettings,
  Tick,
} from "devextreme-react/chart";

import { data_all_members, data_exam } from "../../data/students_data";
import { Box, Paper } from "@material-ui/core";
import { QUESTION_CATEGORIES } from "../../data/Constant";



const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  paper: {
    marginLeft: 18,
    marginRight: 18,
    marginBottom: theme.spacing(2),
  },
  container: {
    // height: 800,
    // maxHeight: 800,
  },
  table: {},
  tableHead: {
    fontSize: '1.0rem'
  },

  table_row: {
    "padding-left": "0px",
    "padding-right": "0px",
    "padding-top": "10px",
    "padding-bottom": "10px",
  },

}));

// 전체 학생들의 문제 풀이 시간과 시퀀스를 얻어낼 수 있다.
// 나중에 참고, 참조할 것, 2020-08-09
/**
 * @typedef AnswerSummary
 * @type {array}
 * @property {number} question_number - number of question
 * @property {number} time - total comsumed time of all applicants
 * @property {number} score - total score of all applicants
 * @property {number} tried - total tried count of all applicants
 */

/**
 *
 * @param {Array.<Object>} rows
 *
 * @return {AnswerSummary}
 */
function summarizeTestResult(rows) {

  const time_sumup = {};

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const id = row.id;
    const name = row.name;
    const time = row.time;
    const score = row.score;

    const examData = data_exam[id];
    const userInfo = examData.userInfo;
    const answer_arr = examData.questionInfoArray;

    for (let j = 0; j < answer_arr.length; j++) {
      const ans_row = answer_arr[j];

      /** @type {string} */
      const q_num = ans_row.number;           // 문제 번호
      const q_num_str = ans_row.numberStr;
      
      /** @type {string} */
      const q_eval_elem = ans_row.evaluationElement;  // 성취 기준
      const q_eval_elem_key = q_eval_elem.replace(/\s/g, "");

      /** @type {string} */
      const q_eval_detail = ans_row.detailContent;      // 내용 요소
      const q_eval_detail_key = q_eval_detail.replace(/\s/g, "");

      /** @type {number} */
      const a_time = ans_row.solveTimeSec;    // 문제 풀이에 걸린 시간

      /** @type {number} */
      const a_seq_num = ans_row.sequenceNumber; // 전체 문제 풀이 순서 중에서 몇번째로 풀었는지 (시도를 안했으면 0)

      /** @type {boolean} */
      const a_correct = ans_row.isCorrect;    // 문제를 맞혔는지 안맞혔는지

      /** @type {boolean} */
      const a_tried = ans_row.isPlay;     // 문제를 풀려고 했는지 안했는지,

      // console.log(`${q_eval_elem} --> ${name} ${id}, Q-num:${q_num},  A-timesec:${a_time} == SEQ(${a_seq_num}), ans/tried=${a_correct}/${a_tried} `);

      // 성취 기준(q_eval_elem) 배열에 신규 생성
      const q_key = q_eval_elem_key;
      if (!time_sumup.hasOwnProperty(q_key)) {
        // console.log(`    create now era`);
        time_sumup[q_key] = {
          q_eval_elem,
          q_num: [],
          q_eval_detail: [],
          time: 0,
          score: 0,
          tried: 0,
          count: 0,
        };
      }

      
      // 성취 기준(q_eval_elem) 배열에 문항을 추가
      if (!time_sumup[q_key].q_num.find(q => q === q_num)) {
        // console.log(`    q_key ${q_num}`);
        time_sumup[q_key].q_num.push(q_num);
      }

      // 성취 기준(q_eval_elem) 배열에 내용을 추가
      if (!time_sumup[q_key].q_eval_detail.find(elem => elem.key === q_eval_detail_key)) {
        // console.log(`    push ${q_eval_detail}`);
        time_sumup[q_key].q_eval_detail.push({
          key: q_eval_detail_key,
          q_eval_detail,
        });
      }

      time_sumup[q_key].count++;
      time_sumup[q_key].time += a_time;
      time_sumup[q_key].score += a_correct ? 1 : 0;
      time_sumup[q_key].tried += a_tried ? 1 : 0;
    }
  }
  // console.log(time_sumup);

  // 이제 정렬을 하자
  const sortable = [];
  for (const q_key in time_sumup) {
    sortable.push([
      time_sumup[q_key].q_num,            // 0: 문항번호
      "도형 두 걸음",                           // 1: 단계
      time_sumup[q_key].q_eval_elem,                              // 2: 성취기준
      time_sumup[q_key].q_eval_detail,    // 3: 내용
      time_sumup[q_key].score,            // 4: 점수
      time_sumup[q_key].time,             // 5: 시간
      time_sumup[q_key].tried,            // 6: 시도 회수
      time_sumup[q_key].count,            // 7: 해당 문항수

    ]);
  }

  sortable.sort(function (a, b) {
    return a[0] - b[0];   // key로 정렬
  });

  let total_time = 0;
  for (let k = 0; k < sortable.length; k++) {
    const ie = sortable[k];

    ie[0].sort((a, b) => a - b);     // q_num
    ie[3].sort((detail_a, detail_b) => detail_a.key - detail_b.key);     // q_eval_detail


    total_time += sortable[k][1];
  }

  // console.log(sortable);

  return sortable;
}

function percentStr(num, under_digit = 1) {
  const div = Math.pow(10, under_digit);
  const ret = Math.round(num * div * 100) / div;
  return ret;
}






function labelText(tabNum, n) {
  let ret = null;
  const ret_arr = QUESTION_CATEGORIES[tabNum];

  if (ret_arr) ret = ret_arr[n - 1];
  if (ret)
    return ret;

  return `4-${n}`;
}

export default function EvaluationSectionChart(props) {

  const { tabNum } = props;

  const classes = useStyles();
  const customizeText = (e, source) => {
    return labelText(tabNum, e.value);
  }

  const raw_data = data_all_members;
  // const [raw_data, setRows] = React.useState(data_all_members);
  const exam_result = raw_data.filter(row => (!row.absent));

  const applicants_cnt = exam_result.length;

  /** @type {AnswerSummary} */
  const s_arr = summarizeTestResult(exam_result);

  const data_source = [];
  for (let i = 0; i < s_arr.length; i++) {
    const item = s_arr[i];
    data_source.push(
      {
        level: i + 1,
        average: Math.round(item[4] / item[7] * 1000) / 10,
        time: Math.round(item[5] / item[7] * 10) / 10,
        tried: Math.round(item[6] / item[7] * 1000) / 10,
      }
    );
  }

  const contents_source = [];
  for (let j = 0; j < s_arr.length; j++) {
    const item = s_arr[j];
    contents_source.push(
      {
        value: item[0],
        name: labelText(j + 1),
      }
    );
  }

  return (
    <div>
      <Box display="flex" flexDirection="row"  fontSize={18} p={1} fontWeight="fontWeightBold">
        성취기준 그래프
        </Box>

      <Paper className={classes.paper} style={{ minHeight: "450px" }} >
        <div className="pies-container">
          <Chart palette="Material" dataSource={data_source} rotated={false} id="chart">
            {/* <Chart palette="Material" dataSource={this.state.data_source} rotated={false} id="chart"> */}
            <CommonSeriesSettings barPadding={0.5} argumentField="level" />

            <ArgumentAxis tickInterval={1}>
              <Label customizeText={customizeText} />
            </ArgumentAxis>

            <ValueAxis name="percent">
              <Tick visible={true} />
              <Label visible={true} />
            </ValueAxis>

            <ValueAxis name="time" position="right">
              <Tick visible={true} />
              <Label visible={true} />
            </ValueAxis>

            <Series axis="time" valueField="time" argumentField="level" name="시간(초)" type="bar">
              {/* <Point symbol="" /> */}
            </Series>

            <Series axis="percent" valueField="average" argumentField="level" name="성취도(%)" >
              <Label visible={true} />
            </Series>

            <Series axis="percent" valueField="tried" argumentField="level" name="시도율(%)" >
              <Label visible={true} />
            </Series>

            <Legend visible={true} />
          </Chart>
        </div>
      </Paper>
    </div>
  );
}
