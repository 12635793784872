import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { Collapse, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, } from "@material-ui/core";
import { VisangDrawerRoutePath } from "../routers/VisangDrawerRoutePath";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { logout } from "../../store/reducers/authorization";
import { connect } from "react-redux";
import { RootState } from "../../store/configureStore";
import { numCourses } from "../constants/VisangConstants";

const styles = (theme) => ({
  drawer: {
    width: theme.props.drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: theme.props.drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7),
    },
  },
  toolbar: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listIcon: {
    display: "flex",
    justifyContents: "center",
  },
});


interface Props {
  classes?,
  location?,
  match?,
  history?,
  onDrawerOpenStatus?,

  course: number,
  refreshDrawerCount: number,
}

interface State {
  alwaysOpen: boolean,
  minified: boolean,
}

const defaultProps: Props = {
  course: -1,
  refreshDrawerCount: -1,
}

const selectedItems: number[] = Array.apply(null, { length: numCourses }).map(() => undefined);
class VisangDrawerMenu extends Component<Props, State> {

  state = {
    // 기본, 열림
    // 초기
    alwaysOpen: true,
    minified: false,

    // 기본, 닫힘
    // 초기
    // alwaysOpen: false,
    // minified: true,

  };

  constructor(props = defaultProps) {
    super(props);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onDrawerMenuClick = this.onDrawerMenuClick.bind(this);
  }


  buildChildMenuItems = (item) => {
    const { classes } = this.props;
    return (
      <Collapse in={this.state[`${item.title}IsOpen`]} timeout="auto" unmountOnExit >
        <List component="div" disablePadding>
          {item.subMenuItems.map((c) => {
            let isSelected = false;
            if (this.props.location.pathname === `${this.props.match.path}/${item.path}/${c.path}`) {
              isSelected = true;
            }
            return (
              <ListItem key={c.title} button selected={isSelected}
                className={`${this.state.minified === false ? classes.nested : ""}`}
                onClick={(e) => this.onDrawerMenuClick(e, item, c)}
              >
                {c.icon !== null && c.icon !== undefined && c.icon !== "" ? (
                  <ListItemIcon className={classes.listIcon}>
                    {c.icon}
                  </ListItemIcon>
                ) : (
                    <div className="MuiListItemIcon-root"></div>
                  )}
                <ListItemText primary={c.title} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    );
  };

  buildMenuItems = () => {
    const visangDrawerItems = VisangDrawerRoutePath.getInstance();
    return visangDrawerItems.menuItems.map((item, index) => {
      if (item.isDummy === true) {
        return (
          <ListItem key={item.title}></ListItem>
        );
      } else if (item.subMenuItems !== undefined && item.subMenuItems !== null) {
        let isSelected = false;

        const { pathname, search } = this.props.location;
        const fullUrl = `${pathname}${search ? search : ""}`;

        if (fullUrl.indexOf(`${this.props.match.path}/${item.path}`) === 0) {
          isSelected = true;
        }
        return (
          <div key={item.title}>
            <ListItem button key={item.path} selected={isSelected} onClick={(e) => this.onChildMenuOpenClick(e, item)} >
              {item.icon !== null && item.icon !== undefined ? (
                <ListItemIcon>{item.icon}</ListItemIcon>
              ) : (
                  <div className="MuiListItemIcon-root"></div>
                )}
              <ListItemText primary={item.title} />
              {this.state[`${item.title}IsOpen`] ? (
                <ExpandLess />
              ) : (
                  <ExpandMore />
                )}
            </ListItem>
            {this.buildChildMenuItems(item)}
          </div>
        );
      } else {
        let isSelected = false;
        const { pathname, search } = this.props.location;
        const fullUrl = `${pathname}${search ? search : ""}`;
        const itemUrl = `${this.props.match.path}/${item.path}`;
        // console.log(`fullUrl=${fullUrl} itemUrl=${itemUrl}, result=${fullUrl == itemUrl}`);
        if (fullUrl == itemUrl) {
          isSelected = true;
        }

        return (
          <ListItem button key={item.path} selected={isSelected} onClick={(e) => this.onDrawerMenuClick(e, item, "")} >
            {
              item.icon !== null && item.icon !== undefined ? (
                <ListItemIcon>{item.icon}</ListItemIcon>
              ) : (
                  <div className="MuiListItemIcon-root"></div>
                )
            }
            <ListItemText primary={item.title} />
          </ListItem>
        );
      }
    });
  };

  onChildMenuOpenClick = (event, item) => {
    const stateKey = `${item.title}IsOpen`;
    const val = !this.state[stateKey];
    const state = {};
    state[stateKey] = val;
    this.setState(state);
  };

  onDrawerMenuClick = (event, item, c) => {
    if (item.path !== null && item.path !== undefined) {
      let childPath = "";
      if (c !== null && c !== undefined) {
        if (c.path !== null && c.path !== undefined) {
          childPath = `/${c.path}`;
        }
      }

      const visangDrawerItems = VisangDrawerRoutePath.getInstance();
      let index = visangDrawerItems.children.findIndex(i => (i.path === item.path));
      if (index === undefined) index = 0;
      selectedItems[this.props.course] = index;

      const url = `${this.props.match.path}/${item.path}${childPath}`;
      this.props.history.push(url);
    } else if (item.action !== null && item.action !== undefined) {
      switch (item.action) {
        case "logout": {
          logout();
          this.props.history.push("/");
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  onMouseEnter = () => {
    if (!this.state.alwaysOpen) {
      this.setState({
        minified: false,
      });
    }
  };

  onMouseLeave = () => {
    if (!this.state.alwaysOpen) {
      this.setState({
        minified: true,
      });
    }
  };

  componentDidMount() {
    const visangDrawerItems = VisangDrawerRoutePath.getInstance();
    const subMenuItem = visangDrawerItems.menuItems.filter(
      (item) => item.subMenuItems !== null && item.subMenuItems !== undefined
    );
    subMenuItem.map((item) => {
      const stateKey = `${item.title}IsOpen`;
      const state = {};
      state[stateKey] = true;
      this.setState(state);
      return stateKey;
    });
    this.forceUpdate();
  }

  pseudoClickItem = (course: number) => {
    const visangDrawerItems = VisangDrawerRoutePath.getInstance();
    const length = visangDrawerItems.children.length;

    // replay와 로딩 중이 2개, 그 이상이면 filed가 있다.
    let index = 0;
    if (!selectedItems[course]) {
      index = visangDrawerItems.children.findIndex(item => item.isMenuItem);
      if (index === undefined) index = 0;
    }
    else {
      index = selectedItems[course];
    }
    const item = visangDrawerItems.children[index];

    const childPath = "";
    const url = `${this.props.match.path}/${item.path}${childPath}`;
    console.log(`MainRoutePath: pseudoClickItem, url="${url}" index=${index}`);
    this.props.history.push(url);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const nextDrawerOpen = nextState.alwaysOpen || !nextState.minified;
    const prevDrawerOpen = this.state.alwaysOpen || !this.state.minified;
    if (nextDrawerOpen !== prevDrawerOpen) {
      this.props.onDrawerOpenStatus(nextDrawerOpen);
    }

    if (this.props.course !== nextProps.course) {
      const drawerItems = VisangDrawerRoutePath.getInstance();
      drawerItems.refresh(nextProps.course);
    }

    if (this.props.refreshDrawerCount !== nextProps.refreshDrawerCount) {
      console.log("MainRoutePath: drawer refresh");

      this.pseudoClickItem(nextProps.course);
    }

    return true;
  }

  render() {
    const { classes } = this.props;
    return (
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: this.state.alwaysOpen || !this.state.minified,
          [classes.drawerClose]: !this.state.alwaysOpen && this.state.minified,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: this.state.alwaysOpen || !this.state.minified,
            [classes.drawerClose]:
              !this.state.alwaysOpen && this.state.minified,
          }),
        }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className={classes.toolbar} />
        <div
          style={{
            padding: "0 4px",
          }}
        >
          <IconButton style={{ // float: this.state.alwaysOpen ? "right" : "left",
            float: this.state.alwaysOpen ? "left" : "left",
          }}
            onClick={() => {
              this.setState({
                alwaysOpen: !this.state.alwaysOpen,
              });
            }} >
            {/* {this.state.alwaysOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
            {this.state.alwaysOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <List component="nav" style={{ padding: 0 }}>
          {this.buildMenuItems()}
        </List>
      </Drawer>
    );
  }
}



const mapStateToProps = (state: RootState) => {
  const ret = {
    course: state.visang.course,
    refreshDrawerCount: state.visang.refreshDrawerCount,
  };
  return ret;
};


export default withRouter(withTheme(withStyles(styles)(connect(mapStateToProps)(VisangDrawerMenu))));
