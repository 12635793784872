
export const courses = ["Starter", "Jumper", "Advanced"];
export const lessons = [
  "Lesson1", "Lesson2", "Review_Lesson 1-2",
  "Lesson3", "Lesson4", "Review_Lesson 3-4",
  "Lesson5", "Lesson6", "Review_Lesson 5-6",
  "Lesson7", "Lesson8"
];


export const lessonNames = [
  "Lesson 1", "Lesson 2", "Review lesson 1-2",
  "Lesson 3", "Lesson 4", "Review lesson 3-4",
  "Lesson 5", "Lesson 6", "Review lesson 5-6",
  "Lesson 7", "Lesson 8"
];



export const numLesseons = lessons.length;
export const numCourses = courses.length;
